<template>
    <div class="container company-page flex-row justify-between">
        <div class="page-content company-info text-left">
            <div class="company-title flex-row justify-between align-center index-blank-padding bg-color-white">
                <div class="company-name font-16 font-bold">{{companyInfo.companyName}}</div>
            </div>
            <div class="engagement font-14 company-product bg-color-white" >
                <div class="flex-row justify-between index-blank-padding align-center info-title">
                    <div class="font-bold ">企业经营</div>
                </div>
                <navigator hover-class="none" url="/pages/searchProduct/product_list" class="item font-14 font-color-3737 flex-row justify-between index-blank-padding">
                    <div class="item-label">主营产品 <text class="font-color-F204" style="margin-left: 5px;">{{productCount}}</text></div>
                    <div class="item-nums flex-row align-center"><uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
                </navigator>
            </div>
            <div class="property font-14 company-product bg-color-white margin-top-20">
                <div class="info-title font-bold index-blank-padding">知识产权</div>
                <navigator hover-class="none" url="/pages/patent_list/patent_list" class="item font-14 font-color-3737 flex-row justify-between index-blank-padding">
                    <div class="item-label">专利技术 <text class="font-color-F204" style="margin-left:5px;">{{patentCount}}</text></div>
                    <div class="item-nums flex-row align-center"><uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
                </navigator>
            </div>

            <div class="font-14 index-blank-padding bg-color-white margin-top-20 company-desc">
                <div class="info-label font-bold">公司简介</div>
                <div class="info-content">
                    {{companyInfo.scope}}
                </div>
                <div class="font-14 flex-row align-center justify-between  company-into">
                    <div class="legalPerson info-item">
                        <div class="info-label font-color-A0A1">法定代表人</div>
                        <div class="info-val font-color-3737">{{companyInfo.representative}}</div>
                    </div>
                    <div class="info-item registeredCapital">
                        <div class="info-label font-color-A0A1">注册资本</div>
                        <div class="info-val font-color-3737">{{companyInfo.capital}}</div>
                    </div>
                    <div class="foundData info-item">
                        <div class="info-label font-color-A0A1">成立日期</div>
                        <div class="info-val font-color-3737">{{companyInfo.established ? companyInfo.established : '-'}}</div>
                    </div>
                </div>
            </div>

            <div class="contact font-14 index-blank-padding bg-color-white">
                <div class="info-title  font-bold  contact-label"> 联系方式</div>
                <div class="contact bg-color-white">
                    <div class="addr flex-row align-start">
                        <div class="label font-color-A0A1">地址</div>
                        <div class="contact-content">{{companyInfo.address}}</div>
                    </div>
                    <div class=" flex-row align-start">
                        <div class="label font-color-A0A1">官网</div>
                        <div class="contact-content">{{companyInfo.website ? companyInfo.website : '-'}}</div>
                    </div>
                    <div class=" flex-row align-start">
                        <div class="label font-color-A0A1">邮箱</div>
                        <div class="contact-content">{{companyInfo.email}}</div>
                    </div>
                    <div class=" flex-row align-start">
                        <div class="label font-color-A0A1">电话</div>
                        <div class="contact-content">{{companyInfo.phone}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="recomend-content">
            <company-hot />
        </div>
    </div>
</template>

<script>
import CompanyHot from "@/components/hot/CompanyHot";
export default {
    name: 'CompanyDetail',
    components: {
        CompanyHot
    },
    data() {
        return {
            companyInfo: {},
            isClaim: false,
            isEdit: 'confirm',
            isLike: false,
            companyId: '',
            productCount: 0,
            patentCount: 0
        }
    },
    created(){
        this.companyId = this.$route.params.id
        this.getDetail()
        this.getProductCount()
        this.getPatentCount()
    },
    watch: {
        $route: function(to, from) {
            if (to.path !== from.path) {
                this.patentId = to.params.id // 获取参数 这里的 mainTpe 是我的路由参数
                this.getDetail()
                this.getProductCount()
                this.getPatentCount()
            }
        }
    },
    computed:{
    },
    methods: {

        getDetail(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/company/queryById?id='+ _that.companyId,

            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.companyInfo = ldata.result
                }
            })
        },

        getProductCount(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/company/queryCompanyProductByMainId?id='+ _that.companyId,

            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.productCount = ldata.result.length
                }
            })
        },

        getPatentCount(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/company/queryCompanyPatentByMainId?id='+ _that.companyId,

            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.patentCount = ldata.result.length
                }
            })
        },
    }
}
</script>

<style  scoped >
page{
    background-color: #F8F8F8;
}
.page-content{
    width: 68%;
}
.recomend-content{
    width: 30%;
    position: fixed;
    right: 4%;
}
.company-page{
    padding: 16px 10%;
}
.company-info {
    padding-bottom: 20px;
}
.company-info .info-label{
    margin-bottom: 7px;
}
.company-info .info-item{
    text-align: center;
}
.company-info .company-title{
    padding-top: 20px;
    padding-bottom: 15px;
}

.company-info .contact{
    padding-bottom: 10px;
}
.company-info .contact>div{
    margin-bottom: 10px;
}
.company-info .contact .label{
    width: 15%;
}
.company-info .contact .contact-content{
    width: 75%;
}
.company-info .company-claim{
    padding: 4px 10px;
    border-radius: 5px;
    border: 1px solid #4D8CEE;
}
.margin-top-20{
    margin-top: 10px;
}
.info-title{
    padding-bottom: 15px;
    border-bottom: 1px solid #EBEBEB;
}
.contact-label{
    padding-top: 15px;
    padding-bottom: 15px;
}
.contact{
    padding-top: 10px;
    margin-top: 10px;
}
.company-desc{
    padding-top: 15px;
    padding-bottom: 15px;
}
.company-into{
    background-color: #F8F8F8;
    padding: 12px 10px;
    border-radius: 5px;
    margin-top: 15px;
}
.company-product{
    padding-top: 15px;
}
.company-product .item{
    padding-top: 15px;
    padding-bottom: 15px;
}
.page-bottom{
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
}
</style>
