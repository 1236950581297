<template>
    <div class="conten index-detail">
        <div class="page-content bg-color-white">
            <div class="chart">
                <div id="applicantChart" style="width: 100%; height: 300px"></div>
            </div>
            <div class="content-desc font-14 index-blank-padding text-justify">
                <div>全球对虾病害诊断及防治专利申请数量排在前十位申请人主要分布在中国，中国水产科院研究院黄海水产研究所的申请数量最多，为34件，其次是中国海洋大学，专利申请量为22件。上图2-7的排名中，只有珠海容川饲料有限公司和海大集团股份有限公司是企业，其余的申请人为科研单位和大专院校。</div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    name: 'ApplicantDetail',
    data() {
        return {
            applicantData: {
                "organ": {
                    labename: ["上海海洋大学", "中国科学院南海海洋研究所","国家海洋局第三海洋研究所","广东海大集团股份有限公司","珠海容川饲料有限公司","中山大学", '中国科学院海洋研究所', '广东海洋大学', '中国海洋大学', '中国水产科学研究院黄海水产研究所'],
                    data: [13, 13, 15, 15, 15, 61, 16, 17, 19, 22, 34]
                },
                "type":{
                    labename: ["企业", "大专院校","科研单位","个人","机关团体","其他"],
                    data: [251, 167, 152, 87, 16]
                },
                "inventor": {
                    labename: ["王志萍", "相建海","黄倢","任春华","胡超群", "卓成忠","张庆利","李富花","刘兴旺","孙成波"],
                    data: [15, 15, 15, 12, 13, 13, 12, 12, 11, 11]
                }
            },
            applicantTemp: {}
        }
    },
    created() {
        if(this.$route.params.applicantType == 'organ'){
            this.applicantTemp = this.applicantData['organ']
        }else if(this.$route.params.applicantType == 'type'){
            this.applicantTemp = this.applicantData['type']
        }else{
            this.applicantTemp = this.applicantData['inventor']
        }
    },
    mounted() {
        this.initApplicantChart()
    },
    methods: {
        // 区域分布
        initApplicantChart(){
            var chartDom = document.getElementById('applicantChart')
            var myChart = echarts.init(chartDom)
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ["王志萍", "相建海","黄倢","任春华","胡超群", "卓成忠","张庆利","李富花","刘兴旺","孙成波"]
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data:  [15, 15, 15, 12, 13, 13, 12, 12, 11, 11]
                    }
                ]
            };
            myChart.setOption(option)
        },

    }
}
</script>

<style scoped>
.index-detail{
    padding: 20px 20%;
}
.content-desc{
    margin-top: 15px;
    border-top: 10px solid #F8F8F8;
    margin-bottom: 30px;
    padding: 20px;
}

</style>
