<template>
  <div id="app">
    <div class="common-header-bar text-left setColor-bg" :class="colorBg">
      <div class="flex-row align-center header-index-bar justify-between">
        <router-link tag="a" target="_blank" class="flex-row align-center" to="/">
          <div class="info-logo" style="height: 34px">
            <img
              class="logo"
              src="/static/logo.png"
              width="34" height="34"
              style="margin-top: 2px"/>
          </div>
          <div class="company-title font-12 font-bold">
            <div>海洋科技成果转化</div>
            <div>公共服务平台</div>
          </div>
        </router-link>

        <div class="right-part">
          <router-link tag="a" target="_blank" to="/rank/list" class="to-rankList cur-pointer font-bold">
            价值排行榜
          </router-link>
          <router-link tag="a" target="_blank" to="/" class="to-index cur-pointer font-bold">首页</router-link>
        </div>
      </div>
    </div>
    <div :class="marginTopClass">
      <router-view/>
    </div>

  </div>
</template>

<script>
import '@/assets/hy-ui/font.css'
import '@/assets/hy-ui/layout.css'
import '@/assets/hy-ui/main.css'

export default {
  name: 'App',
  data() {
    return {
      colorBg: '',
      pageCurrent: '', // red, blue
      marginTopClass: 'common-content-bar'
    }
  },
  created() {
    console.log(this.$route.path)
    if (this.$route.path == '/') {
      this.colorBg = 'blue-bg'
      this.pageCurrent = 'blue'
      this.marginTopClass = " "
    } else if (this.$route.path == '/rank/list') {
      this.colorBg = 'red-bg'
      this.pageCurrent = 'red'
      this.marginTopClass = " "
    } else {
      this.colorBg = ''
      this.pageCurrent = ''
    }
  },
  mounted() {
    if (this.pageCurrent) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > 360) {
        this.colorBg = ''
      } else {
        if (this.pageCurrent == 'red') {
          this.colorBg = 'red-bg'
        } else if (this.pageCurrent == 'blue') {
          this.colorBg = 'blue-bg'
        }
      }
      // console.log(scrollTop)
    }
  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

@font-face {
  font-family: "iconfont"; /* Project id 2259585 */
  src: url('/static/iconfont/iconfont.woff2?t=1629084801362') format('woff2'),
  url('/static/iconfont/iconfont.woff?t=1629084801362') format('woff'),
  url('/static/iconfont/iconfont.ttf?t=1629084801362') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #333;
}

.router-link-active {
  text-decoration: none;
  color: #333;
}

.common-content-bar {
  margin-top: 60px;
}

.common-header-bar {
  top: 0;
  height: 60px;
  position: fixed;
  width: 100%;
  /*background-color: rgba(235,235,235, .3);*/
  background-color: #FFFFFF;
  color: #333333;
  z-index: 99;
  box-shadow: 0 5px 5px #EBEBEB;
  /*opacity: .2;*/
}

.common-header-bar .header-index-bar {
  padding: 12px 10%;
}

.common-header-bar .header-index-bar .right-part {
  width: 50%;
  text-align: right;
}

.common-header-bar .to-index {
  float: right;
  margin-right: 30px;
}

.common-header-bar .to-rankList {
  float: right;
}

.common-header-bar .company-title {
  background-color: rgb(225, 64, 67);
  color: #ffffff;
  padding: 2px 8px;
  /*border-radius: 3px;*/
  margin-left: 10px;
}

.common-header-bar.blue-bg {
  background-color: #4D8CEE;
  box-shadow: none;
  position: relative;
}

.common-header-bar.blue-bg .to-rankList,
.common-header-bar.red-bg .to-rankList,
.common-header-bar.blue-bg .to-index,
.common-header-bar.red-bg .to-index {
  color: #FFFFFF !important;
}

.common-header-bar.red-bg {
  background-color: #EE4649;
  box-shadow: none;
  position: relative;
}

</style>
