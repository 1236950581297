<template>
    <div class="content index-detail">
        <div class="page-content bg-color-white">
            <div class="chart ">
                <div class=" flex-row">
                    <div id="global" class="chart-item" style="width: 50%;">
                        <div class="charts-box">
                            <div id="globalCharts" style="height: 300px;"></div>
                        </div>
                    </div>
                    <div id="china" class="chart-item" style="width: 50%;">
                        <div class="charts-box">
                            <div id="chinaCharts" style="height: 300px;"></div>
                        </div>
                    </div>
                </div>
                <div class="flex-row">
                    <div id="guangdong" class="chart-item" style="width: 50%;">
                        <div class="charts-box">
                            <div id="guangdongCharst" style="height: 300px"></div>
                        </div>
                    </div>
                    <div class="charts-box" style="width: 50%;">
                        <div id="proviceCharts" style="height: 300px"></div>
                    </div>

                </div>
            </div>
            <div class="content-desc font-14 index-blank-padding text-justify">
                <div>从全球对虾病害诊断及防治专利申请的IPC主分类号（小类）的分布情况来看，A23K（动物喂养饲料）的专利数量最多，为283件，占分析样本专利申请总量的35.9%；其次是A01K（畜牧业）专利数量为145件，占比18.3%。中国对虾病害诊断及防治专利申请的IPC主分类号（小类）的分布情况基本与全球的分布情况一致；其中，A23K（动物喂养饲料）的专利数量，为265件，占全国总申请量的33.5%，A01K（畜牧业）专利数量为138件，占比为17.4%。这种分布趋势主要由两点原因导致：一方面随着对虾养殖的发展，企业加大了对虾饲料的研发投入，促进了对虾饲料产品的技术发展；另一方面，由于对虾病害防治的药物投喂方式一大部分是结合饲料进行投喂，从而也使得与饲料相关类别的专利申请数量较大。</div>
                <div>我国各省对虾病害诊断及防治专利申请IPC主分类号（小类）的分布情况与全球及全国的分布情况基本趋于一致，可以看出，各省在A23K（动物喂养饲料）的专利数量明显要多于其他类别的专利数量。广东省对虾病害诊断及防治专利申请IPC主分类号（小类）的分布情况整体上于全球和中国的趋势一致。</div>
            </div>
        </div>
    </div>
</template>

<script>
import  * as echarts from 'echarts'
export default {
    name: 'TechnologyDetail',
    data() {
        return {
            cWidth:'',
            cHeight:'',
            pixelRatio:1,
            provinceData: {
                "categories": ["广东","山东","江苏","浙江","广西","福建","天津","上海","安徽","湖北"],
                "series": [{
                    "name": "A23K",
                    "data": [82,46,48,24,39,23,13,12,13,1],
                    "type": "column"
                }, {
                    "name": "A01K",
                    "data": [38,29,17,12,18,9,3,6,5,1],
                    "type": "column"
                }, {
                    "name": "A61K",
                    "data": [17,28,11,9,11,6,14,7,2,5],
                    "type": "column"
                },{
                    "name": "C12N",
                    "data": [22,29,7,8,8,20,3,8,0,7],
                    "type": "column"
                },{
                    "name": "A61P",
                    "data": [17,27,11,9,11,6,15,7,2,3],
                    "type": "column"
                },{
                    "name": "C12Q",
                    "data": [21,24,2,18,4,8,3,10,0,4],
                    "type": "column"
                },{
                    "name": "C12R",
                    "data": [15,18,4,4,3,7,3,9,0,3],
                    "type": "column"
                },{
                    "name": "C07K",
                    "data": [8,21,3,0,4,6,1,0,0,6],
                    "type": "column"
                },{
                    "name": "G01N",
                    "data": [5,9,1,3,0,2,0,0,0,6],
                    "type": "column"
                },{
                    "name": "C02F",
                    "data": [4,2,3,1,2,5,1,2,0,0],
                    "type": "column"
                }]
            },
            techData:{
                "global":{
                    labename: ["A23K", "A01K", "C12Q", "A61K", "C12N", "C07K", "G01N", "C02F", "A01N", "其他"],
                    data: [283, 145, 100, 83, 82, 44, 19, 9, 6, 18]
                },
                "china":{
                    labename: ["其他", "A01N", "C02F", "G01N", "C07K", "C12N", "A61K", "C12Q", "A01K", "A23K"],
                    data: [7, 3, 9, 18, 35, 51, 62, 85, 138, 265]
                },
                "guangdong":{
                    labename: ["C07K", "C02F", "G01N", "A61K", "C12N", "C12Q", "A01K", "A23K"],
                    data: [3, 4, 4, 7, 11, 18, 35, 69]
                },
            },
        }
    },
    mounted() {
        this.initGlobalDataChart()
        this.initChinaDataChart()
        this.initGuangdongDataChart()
    },
    methods: {

        initGlobalDataChart(){
            var globalData = this.techData['global']
            var chartDom = document.getElementById('globalCharts')
            var myChart = echarts.init(chartDom)
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: globalData.labename
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data:  globalData.data
                    }
                ]
            };
            myChart.setOption(option)
        },

        initChinaDataChart(){
            var chinaData = this.techData['china']
            var chartDom = document.getElementById('chinaCharts')
            var myChart = echarts.init(chartDom)
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: chinaData.labename
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data:  chinaData.data
                    }
                ]
            };
            myChart.setOption(option)
        },

        // initTechDataChart(){
        //     var chartDom = document.getElementById('techChart')
        //     var myChart = echarts.init(chartDom)
        //     var option = {
        //         tooltip: {
        //             trigger: 'axis',
        //             axisPointer: {
        //                 type: 'shadow'
        //             }
        //         },
        //         legend: {},
        //         grid: {
        //             left: '3%',
        //             right: '4%',
        //             bottom: '3%',
        //             containLabel: true
        //         },
        //         xAxis: {
        //             type: 'value',
        //             boundaryGap: [0, 0.01]
        //         },
        //         yAxis: {
        //             type: 'category',
        //             data: this.teachTemp.labename
        //         },
        //         series: [
        //             {
        //                 name: '',
        //                 type: 'bar',
        //                 data:  this.teachTemp.data
        //             }
        //         ]
        //     };
        //     myChart.setOption(option)
        // },

        initGuangdongDataChart(){
            var chartDom = document.getElementById('guangdongCharst')
            var guangdongData = this.techData['guangdong']
            var myChart = echarts.init(chartDom)
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: guangdongData.labename
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data:  guangdongData.data
                    }
                ]
            };
            myChart.setOption(option)
        },
    }
}
</script>

<style scoped>
.index-detail{
    padding: 20px 10%;
}
.content-desc{
    margin-top: 15px;
    border-top: 10px solid #F8F8F8;
    margin-bottom: 30px;
    padding: 40px;
}
canvas{
    width: 100%;
    height: 200px;
}
</style>

