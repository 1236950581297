<template>
  <div class="content">
    <div class="page-content flex-column">
      <!-- 分类按钮 -->
      <div class="bg-color-white align-center" style="padding-top: 20px;">
        <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
          <button
            v-for="(item,i) in btn" :key="item"
            class="btn"
            :class="btnActive === i ? 'active' : ''"
            @click="switchData(i)">
            {{ item }}
          </button>
        </div>
      </div>

      <!-- 海水对虾 -->
      <div v-if="btnActive===0">
        <div class="content-part bg-color-white">

          <!-- 一级标题 -->
          <div class="part-title font-16 font-color-3737 font-bold">
            <span>产业分类</span>
            <!--            <span v-if="btnActive===1">公司专利技术分支</span>-->
            <!--            <span v-if="btnActive===2">发明人</span>-->
            <!--            <span v-if="btnActive===3">专利技术分支</span>-->
          </div>

          <!-- 二级标题 -->
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="height: 25px;">
            <div
              class="switch-item cur-pointer"
              :class="product === 'duixia' ? 'switch-select': ''"
              v-show="btnActive === 0"
              @click="switchPart('product', 'duixia')">
              病害诊断及防治
            </div>
            <div
              class="switch-item cur-pointer"
              :class="product === 'duixia2' ? 'switch-select': ''"
              v-show="btnActive === 0"
              @click="switchPart('product', 'duixia2')">
              药物及诊断
            </div>
          </div>

          <!-- Echarts -->
          <router-link tag="a" target="_blank" to="/index/product">
            <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
              <Charts :option="productData[product]"/>

              <!--            <div id="productChart" style="height: 222px; width: 100%" v-if="btnActive===0"></div>-->
              <!--            <div id="techBranChart" style="height: 222px; width: 824px" v-if="btnActive===1"></div>-->
              <!--            <div id="inventorChart" style="height: 222px; width: 824px" v-if="btnActive===2"></div>-->
            </div>
          </router-link>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            技术分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="tech === 'global' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('tech', 'global')">全球
            </div>
            <div :class="tech === 'china' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('tech', 'china')">
              中国
            </div>
            <!-- <div :class="tech == '各省' ? 'switch-select': ''" class="switch-item" @click="switchPart('tech', '各省')">各省</div> -->
            <div :class="tech === 'guangdong' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('tech', 'guangdong')">广东
            </div>
          </div>
          <router-link tag="a" target="_blank" to="/index/technology">
            <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
              <Charts :option="techData[tech]"/>
            </div>
          </router-link>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            申请趋势
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="apply === 'global' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('apply', 'global')">全球
            </div>
            <div :class="apply === 'china' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('apply', 'china')">中国
            </div>
            <div :class="apply === 'america' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('apply', 'america')">美国
            </div>
          </div>
          <router-link tag="a" target="_blank" :to="'/index/apply_trend/'+apply">
            <div class="charts-box" style="display:flex;justify-content:center;height: 322px;  width: 100%;">
              <Charts :option="applyData[apply]"/>
            </div>
          </router-link>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            区域分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="area === 'global' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('area', 'global')">全球
            </div>
            <div :class="area === 'province' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('area', 'province')">省份
            </div>
          </div>
          <router-link tag="a" target="_blank" :to="'/index/area/'+area">
            <div class="charts-box" style="display:flex;justify-content:center;height: 300px;  width: 100%;">
              <Charts :option="areaData[area]"/>
            </div>
          </router-link>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            申请人分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="applicant === 'type' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicant', 'type')">类型
            </div>
            <div :class="applicant === 'organ' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicant', 'organ')">名称
            </div>
            <div :class="applicant === 'inventor' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicant', 'inventor')">发明人
            </div>
          </div>
          <router-link tag="a" target="_blank" :to="'/index/applicant/'+applicant">
            <div class="charts-box" style="display:flex;justify-content:center;height: 250px;  width: 100%;">
              <Charts :option="applicantData"/>
            </div>
          </router-link>
        </div>
      </div>

      <!-- 海洋工程装备 -->
      <div v-if="btnActive===1">
        <div class="content-part bg-color-white">
          <!-- 一级标题 -->
          <div class="part-title font-16 font-color-3737 font-bold">
            <span>公司专利技术分支</span>
          </div>

          <!-- Echarts -->
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in pTechBranData" :key="index"
                style="display:flex;justify-content:center;height: 300px;  width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>

          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            资源专利申请趋势
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="resPatentApp === 'china' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentApp', 'china')">中国
            </div>
            <div :class="resPatentApp === 'guangdong' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentApp', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="padding-left: 10px;">
            <div :class="resPatentAppSecTitle === 'kzs' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentAppSecTitle', 'kzs')">可再生能源
            </div>
            <div :class="resPatentAppSecTitle === 'kczy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentAppSecTitle', 'kczy')">矿场资源
            </div>
            <div :class="resPatentAppSecTitle === 'yqzy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentAppSecTitle', 'yqzy')">油气资源
            </div>
          </div>

          <div id="techDistribute">
            <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
              <Charts :option="resPatentData[resPatentApp][resPatentAppSecTitle]"/>
            </div>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司专利申请趋势
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comApplyDate" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            资源专利技术分类 <span class="font-color-999 font-13">IPC主分类小类</span>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="resPatentTech === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('resPatentTech', 'china')">中国
            </div>
            <div :class="resPatentTech === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('resPatentTech', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="padding-left: 10px;">
            <div :class="resPatentTechSecTitle === 'kzs' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentTechSecTitle', 'kzs')">可再生能源
            </div>
            <div :class="resPatentTechSecTitle === 'kczy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentTechSecTitle', 'kczy')">矿场资源
            </div>
            <div :class="resPatentTechSecTitle === 'yqzy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentTechSecTitle', 'yqzy')">油气资源
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
            <Charts :option="resPatentTechData[resPatentTech][resPatentTechSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司专利技术分类 <span class="font-color-999 font-13">IPC主分类小类</span>
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comResPatentTechDate" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            资源专利主要发明人
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="resPatentIntor === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('resPatentIntor', 'china')">中国
            </div>
            <div :class="resPatentIntor === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('resPatentIntor', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="padding-left: 10px;">
            <div :class="resPatentIntorSecTitle === 'kzs' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentIntorSecTitle', 'kzs')">可再生能源
            </div>
            <div :class="resPatentIntorSecTitle === 'kczy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentIntorSecTitle', 'kczy')">矿场资源
            </div>
            <div :class="resPatentIntorSecTitle === 'yqzy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('resPatentIntorSecTitle', 'yqzy')">油气资源
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
            <Charts :option="resPatentIntorData[resPatentIntor][resPatentIntorSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司专利发明人
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comPatInventorData" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司专利申请类型
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comApplyTypeData" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            资源专利申请人分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="distOfResPatApp === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('distOfResPatApp', 'china')">中国
            </div>
            <div :class="distOfResPatApp === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('distOfResPatApp', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="padding-left: 10px;">
            <div :class="distOfResPatAppSecTitle === 'kzs' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('distOfResPatAppSecTitle', 'kzs')">可再生能源
            </div>
            <div :class="distOfResPatAppSecTitle === 'kczy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('distOfResPatAppSecTitle', 'kczy')">矿场资源
            </div>
            <div :class="distOfResPatAppSecTitle === 'yqzy' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('distOfResPatAppSecTitle', 'yqzy')">油气资源
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
            <Charts :option="distOfResPatAppData[distOfResPatApp][distOfResPatAppSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司专利有效性
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="comPatValidity === 'zlyxxfl' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('comPatValidity', 'zlyxxfl')">专利有效性分类
            </div>
            <div :class="comPatValidity === 'sxyy' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('comPatValidity', 'sxyy')">失效原因
            </div>
          </div>
          <div class="charts-box" v-if="comPatValidity === 'sxyy'">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in  comPatValidityData['sxyy']" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
          <div class="charts-box" v-else>
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in  comPatValidityData['zlyxxfl']" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>
      </div>

      <!-- 海洋工程船舶 -->
      <div v-if="btnActive===2">
        <div class="content-part bg-color-white">

          <div class="part-title font-16 font-color-3737 font-bold">
            发明人
          </div>

          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="height: 25px;">
            <div
              class="switch-item cur-pointer"
              :class="inventor === 'sb' ? 'switch-select': ''"
              @click="switchPart('inventor', 'sb')">
              设备
            </div>
            <div
              class="switch-item cur-pointer"
              :class="inventor === 'dq' ? 'switch-select': ''"
              @click="switchPart('inventor', 'dq')">
              地区
            </div>
          </div>
          <div v-show="inventor === 'sb'" class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="inventorSecTitle === 'fsscsb' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'fsscsb')">浮式生产设备
            </div>
            <div :class="inventorSecTitle === 'gcc' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'gcc')">工程船
            </div>
            <div :class="inventorSecTitle === 'hgzc' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'hgzc')">海工支持
            </div>
            <div :class="inventorSecTitle === 'hsfd' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'hsfd')">海上风电
            </div>
            <div :class="inventorSecTitle === 'dcc' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'dcc')">调查船
            </div>
            <div :class="inventorSecTitle === 'ydzj' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'ydzj')">移动钻井
            </div>
          </div>
          <div v-show="inventor === 'dq'" class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="inventorSecTitle === 'bj' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'bj')">北京
            </div>
            <div :class="inventorSecTitle === 'sh' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'sh')">上海
            </div>
            <div :class="inventorSecTitle === 'gd' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'gd')">广东
            </div>
            <div :class="inventorSecTitle === 'js' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'js')">江苏
            </div>
            <div :class="inventorSecTitle === 'sd' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'sd')">山东
            </div>
            <div :class="inventorSecTitle === 'zj' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorSecTitle', 'zj')">浙江
            </div>
          </div>

          <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
            <Charts :option="inventorData[inventor][inventorSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司发明人
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comInventorData" :key="index"
                style="display:flex;justify-content:center;height: 300px;  width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            申请趋势
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start" style="height: 25px;">
            <div
              class="switch-item cur-pointer"
              :class="sqqs === 'china' ? 'switch-select': ''"
              @click="switchPart('sqqs', 'china')">
              中国
            </div>
            <div
              class="switch-item cur-pointer"
              :class="sqqs === 'main' ? 'switch-select': ''"
              @click="switchPart('sqqs', 'main')">
              主要地区
            </div>
          </div>
          <div v-show="sqqs==='main'" class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="sqqsSecTitle === 'qb' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'qb')">全部
            </div>
            <div :class="sqqsSecTitle === 'bj' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'bj')">北京
            </div>
            <div :class="sqqsSecTitle === 'sh' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'sh')">上海
            </div>
            <div :class="sqqsSecTitle === 'gd' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'gd')">广东
            </div>
            <div :class="sqqsSecTitle === 'js' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'js')">江苏
            </div>
            <div :class="sqqsSecTitle === 'sd' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'sd')">山东
            </div>
            <div :class="sqqsSecTitle === 'zj' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('sqqsSecTitle', 'zj')">浙江
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 350px; width: 100%;">
            <Charts v-if="sqqs==='china'" :option="sqqsData[sqqs]"/>
            <Charts v-else :option="sqqsData[sqqs][sqqsSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司申请趋势
          </div>

          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comAppTrendData" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            技术分支
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="techDist === 'sb' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('techDist', 'sb')">设备
            </div>
            <div :class="techDist === 'dq' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('techDist', 'dq')">地区
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px;  width: 100%;">
            <Charts :option="techDistData[techDist]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司技术分支
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comTechBranchData" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司申请类型
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in comAppTypeData" :key="index"
                style="display:flex;justify-content:center;height: 350px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            公司技术构成
          </div>
          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in techCompositionComData" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>
      </div>

      <!-- 海洋防腐材料 -->
      <div v-if="btnActive===3">
        <div class="content-part bg-color-white">

          <div class="part-title font-16 font-color-3737 font-bold">
            专利技术分支
          </div>

          <div class="charts-box">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide
                v-for="(item, index) in patTechBranchData" :key="index"
                style="display:flex;justify-content:center;height: 300px; width: 100%;">
                <Charts :option="item"/>
              </swiper-slide>

              <!-- 分页器 -->
              <div class="swiper-pagination" slot="pagination"/>
            </swiper>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            技术分类分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="techClaDist === 'global' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techClaDist', 'global')">全球
            </div>
            <div :class="techClaDist === 'china' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techClaDist', 'china')">
              中国
            </div>
            <!-- <div :class="tech == '各省' ? 'switch-select': ''" class="switch-item" @click="switchPart('tech', '各省')">各省</div> -->
            <div :class="techClaDist === 'guangdong' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techClaDist', 'guangdong')">广东
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 350px; width: 100%;">
            <Charts :option="techClaDistData[techClaDist]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">

          <div class="part-title font-16 font-color-3737 font-bold">
            技术分支分布
          </div>

          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="techBranchDist === 'global' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDist', 'global')">全球
            </div>
            <div :class="techBranchDist === 'china' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDist', 'china')">
              中国
            </div>
            <!-- <div :class="tech == '各省' ? 'switch-select': ''" class="switch-item" @click="switchPart('tech', '各省')">各省</div> -->
            <div :class="techBranchDist === 'guangdong' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDist', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="techBranchDistSecTitle === 'qb' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDistSecTitle', 'qb')">全部
            </div>
            <div :class="techBranchDistSecTitle === 'gnxsp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDistSecTitle', 'gnxsp')">功能性食品
            </div>
            <div :class="techBranchDistSecTitle === 'hzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDistSecTitle', 'hzp')">化妆品
            </div>
            <div :class="techBranchDistSecTitle === 'yycl' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDistSecTitle', 'yycl')">医药材料
            </div>
            <div :class="techBranchDistSecTitle === 'nyzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('techBranchDistSecTitle', 'nyzp')">农用制品
            </div>
          </div>

          <div class="charts-box" style="display:flex;justify-content:center;height: 300px; width: 100%;">
            <Charts :option="techBranchDistData[techBranchDist][techBranchDistSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            产业分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="industDist === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('industDist', 'china')">中国
            </div>
            <div :class="industDist === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('industDist', 'guangdong')">广东
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px;  width: 100%;">
            <Charts :option="industDistData[industDist]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            申请趋势
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="applicTrend === 'global' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('applicTrend', 'global')">全球
            </div>
            <div :class="applicTrend === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('applicTrend', 'china')">中国
            </div>
            <div :class="applicTrend === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('applicTrend', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="applicTrendSecTitle === 'qb' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicTrendSecTitle', 'qb')">全部
            </div>
            <div :class="applicTrendSecTitle === 'gnxsp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicTrendSecTitle', 'gnxsp')">功能性食品
            </div>
            <div :class="applicTrendSecTitle === 'hzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicTrendSecTitle', 'hzp')">化妆品
            </div>
            <div :class="applicTrendSecTitle === 'yycl' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicTrendSecTitle', 'yycl')">医药材料
            </div>
            <div :class="applicTrendSecTitle === 'nyzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('applicTrendSecTitle', 'nyzp')">农用制品
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px;  width: 100%;">
            <Charts :option="applicTrendData[applicTrend][applicTrendSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">

          <div class="part-title font-16 font-color-3737 font-bold">
            全球区域分布
          </div>

          <div class="charts-box" style="display:flex;justify-content:center;height: 350px;  width: 100%;">
            <Charts :option="globalRegDistData"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            专利申请人
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="patApplic === 'global' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('patApplic', 'global')">全球
            </div>
            <div :class="patApplic === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('patApplic', 'china')">中国
            </div>
            <div :class="patApplic === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('patApplic', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="patApplicSecTitle === 'qb' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('patApplicSecTitle', 'qb')">全部
            </div>
            <div :class="patApplicSecTitle === 'gnxsp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('patApplicSecTitle', 'gnxsp')">功能性食品
            </div>
            <div :class="patApplicSecTitle === 'hzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('patApplicSecTitle', 'hzp')">化妆品
            </div>
            <div :class="patApplicSecTitle === 'yycl' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('patApplicSecTitle', 'yycl')">医药材料
            </div>
            <div :class="patApplicSecTitle === 'nyzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('patApplicSecTitle', 'nyzp')">农用制品
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px;  width: 100%;">
            <Charts :option="patApplicData[patApplic][patApplicSecTitle]"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            专利申请人类型
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 400px;  width: 100%;">
            <Charts :option="cnPatApplicData"/>
          </div>
        </div>

        <div class="content-part bg-color-white">
          <div class="part-title font-16 font-color-3737 font-bold">
            发明人分布
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start">
            <div :class="inventorDist === 'global' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('inventorDist', 'global')">全球
            </div>
            <div :class="inventorDist === 'china' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('inventorDist', 'china')">中国
            </div>
            <div :class="inventorDist === 'guangdong' ? 'switch-select': ''"
                 class="switch-item"
                 @click="switchPart('inventorDist', 'guangdong')">广东
            </div>
          </div>
          <div class="part-switch flex-row font-14 font-color-A0A1 align-start"
               style="padding-left: 10px;">
            <div :class="inventorDistSecTitle === 'qb' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorDistSecTitle', 'qb')">全部
            </div>
            <div :class="inventorDistSecTitle === 'gnxsp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorDistSecTitle', 'gnxsp')">功能性食品
            </div>
            <div :class="inventorDistSecTitle === 'hzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorDistSecTitle', 'hzp')">化妆品
            </div>
            <div :class="inventorDistSecTitle === 'yycl' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorDistSecTitle', 'yycl')">医药材料
            </div>
            <div :class="inventorDistSecTitle === 'nyzp' ? 'switch-select': ''" class="switch-item"
                 @click="switchPart('inventorDistSecTitle', 'nyzp')">农用制品
            </div>
          </div>
          <div class="charts-box" style="display:flex;justify-content:center;height: 300px;  width: 100%;">
            <Charts :option="inventorDistData[inventorDist][inventorDistSecTitle]"/>
          </div>
        </div>
      </div>
    </div>

    <BackTop/>
  </div>
</template>

<script>
import Charts from './charts/charts'
import BackTop from "./backToTop/BackToTop"

export default {
  components: {
    Charts,
    BackTop
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 6000
        }
      },
      btn: ['海水对虾', '海洋工程装备', '海洋工程船舶', '海洋防腐材料'],
      btnActive: 0,

      cWidth: '',
      cHeight: '',
      pixelRatio: 1,
      serverData: '',
      keywords: '',

      /** 海水对虾 **/
      // 产业分类
      product: 'duixia',
      productData: {
        'duixia': {
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '142', name: "养殖方法"},
                {value: '246', name: "饲料"},
                {value: '31', name: "其他"}
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        'duixia2': {
          title: {},
          tooltip: {
            // trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {"name": "药物", "value": 224},
                {"name": "诊断试剂及方法", "value": 146}
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
      },
      productTemp: 0,

      // 技术分布
      tech: 'global',
      techData: {
        'global': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ["A23K", "A01K", "C12Q", "A61K", "C12N", "C07K", "G01N", "C02F", "A01N", "其他"],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [283, 145, 100, 83, 82, 44, 19, 9, 6, 18]
            }
          ]
        },
        'china': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ["其他", "A01N", "C02F", "G01N", "C07K", "C12N", "A61K", "C12Q", "A01K", "A23K"],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [7, 3, 9, 18, 35, 51, 62, 85, 138, 265]
            }
          ]
        },
        'guangdong': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ["C07K", "C02F", "G01N", "A61K", "C12N", "C12Q", "A01K", "A23K"],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [3, 4, 4, 7, 11, 18, 35, 69]
            }
          ]
        },
      },
      teachTemp: 0,

      // 申请趋势
      apply: 'global',
      applyData: {
        "global": {
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              data: [],
              type: 'line'
            }
          ]
        },
        "china": {
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              data: [],
              type: 'line'
            }
          ]
        },
        "america": {
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              data: [],
              type: 'line'
            }
          ]
        }
      },
      applyTemp: {},

      // 区域分布
      area: 'global',
      areaData: {
        'global': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ["中国", "美国", "印度", "韩国", "墨西哥", "其他"],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [671, 18, 13, 12, 9, 61]
            }
          ]
        },
        "province": {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: ["安徽", "湖北", "天津", "上海", "福建", "广西", "浙江", "江苏", "山东", "广东"]
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [16, 16, 33, 33, 49, 60, 61, 75, 128, 151]
            }
          ]
        }
      },
      areaTemp: {},

      // 申请人分布
      applicant: 'type',
      applicantTemp: {
        "organ": [
          {"value": 13, "name": "上海海洋大学"}, {"value": 13, "name": "中国科学院南海海洋研究所"},
          {"value": 15, "name": "国家海洋局第三海洋研究所"},
          {"value": 15, "name": "广东海大集团股份有限公司"},
          {"value": 15, "name": "珠海容川饲料有限公司"},
          {"value": 16, "name": "中山大学"},
          {"value": 17, "name": "中国科学院海洋研究所"}, {"value": 19, "name": "广东海洋大学"},
          {"value": 22, "name": "中国海洋大学"},
          {"value": 34, "name": "中国水产科学研究院黄海水产研究所"}
        ],
        "type": [
          {"value": 251, "name": "企业"},
          {"value": 167, "name": "大专院校"},
          {"value": 152, "name": "科研单位"},
          {"value": 87, "name": "个人"},
          {"value": 16, "name": "机关团体"}
        ],
        "inventor": [
          {"value": 15, "name": "王志萍"}, {"value": 15, "name": "相建海"}, {
            "value": 15,
            "name": "黄倢"
          }, {"value": 13, "name": "任春华"}, {"value": 13, "name": "胡超群"}, {"value": 12, "name": "卓成忠"}, {
            "value": 12,
            "name": "张庆利"
          }, {"value": 12, "name": "李富花"}, {"value": 11, "name": "刘兴旺"}, {"value": 11, "name": "孙成波"}
        ],
      },
      applicantData: {
        tooltip: {
          // trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: '10',
          top: '20'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            data: [
              {"value": 251, "name": "企业"},
              {"value": 167, "name": "大专院校"},
              {"value": 152, "name": "科研单位"},
              {"value": 87, "name": "个人"},
              {"value": 16, "name": "机关团体"}
            ],
            label: {
              show: true,
              formatter: '{d}%'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      /** 海水对虾 **/

      /** 海洋工程装备 **/
      // 公司专利技术
      // pTechBranData: {
      //   "pTechBran": [
      //     {value: '2.93', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
      //     {value: '0.76', name: "海洋可再生能源-海洋风能"},
      //     {value: '0.15', name: "海洋可再生能源-海洋温差能"},
      //     {value: '0.30', name: "海洋可再生能源-海洋综合能"},
      //     {value: '0.05', name: "海洋矿产资源-勘探"},
      //     {value: '2.93', name: "海洋矿产资源-钻采"},
      //     {value: '6.47', name: "海洋油气资源-储存"},
      //     {value: '2.93', name: "海洋油气资源-勘探"},
      //     {value: '82.72', name: "海洋油气资源-钻采"},
      //     {value: '5.71', name: "海洋油气资源-其他"},
      //   ],
      // },
      pTechBran: '',
      pTechBranData: [
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '0.20', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
                {value: '0.76', name: "海洋可再生能源-海洋风能"},
                {value: '0.15', name: "海洋可再生能源-海洋温差能"},
                {value: '0.30', name: "海洋可再生能源-海洋综合能"},
                {value: '0.05', name: "海洋矿产资源-勘探"},
                {value: '0.71', name: "海洋矿产资源-钻采"},
                {value: '6.47', name: "海洋油气资源-储存"},
                {value: '2.93', name: "海洋油气资源-勘探"},
                {value: '82.72', name: "海洋油气资源-钻采"},
                {value: '5.71', name: "海洋油气资源-其他"}
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中海油田服务股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '0.69', name: "海洋矿产资源-勘探"},
                {value: '0.23', name: "海洋矿产资源-钻采"},
                {value: '0.23', name: "海洋油气资源-储存"},
                {value: '6.25', name: "海洋油气资源-勘探"},
                {value: '90.97', name: "海洋油气资源-钻采"},
                {value: '1.62', name: "海洋油气资源-其他"}
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油化工股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '0.06', name: "海洋可再生能源-海洋温差能"},
                {value: '0.18', name: "海洋矿产资源-勘探"},
                {value: '0.30', name: "海洋矿产资源-钻采"},
                {value: '1.33', name: "海洋油气资源-储存"},
                {value: '1.33', name: "海洋油气资源-勘探"},
                {value: '95.95', name: "海洋油气资源-钻采"},
                {value: '0.85', name: "海洋油气资源-其他"}
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油集团渤海钻探工程有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '0.77', name: "海洋油气资源-勘探"},
                {value: '99.16', name: "海洋油气资源-钻采"},
                {value: '0.06', name: "海洋矿产资源-钻采"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '0.20', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
                {value: '0.76', name: "海洋可再生能源-海洋风能"},
                {value: '0.15', name: "海洋可再生能源-海洋温差能"},
                {value: '0.30', name: "海洋可再生能源-海洋综合能"},
                {value: '0.05', name: "海洋矿产资源-勘探"},
                {value: '0.71', name: "海洋矿产资源-钻采"},
                {value: '6.47', name: "海洋油气资源-储存"},
                {value: '2.93', name: "海洋油气资源-勘探"},
                {value: '82.72', name: "海洋油气资源-钻采"},
                {value: '5.71', name: "海洋油气资源-其他"}
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
      ],
      pTechBranTemp: [
        [
          {value: '2.93', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
          {value: '0.76', name: "海洋可再生能源-海洋风能"},
          {value: '0.15', name: "海洋可再生能源-海洋温差能"},
          {value: '0.30', name: "海洋可再生能源-海洋综合能"},
          {value: '0.05', name: "海洋矿产资源-勘探"},
          {value: '2.93', name: "海洋矿产资源-钻采"},
          {value: '6.47', name: "海洋油气资源-储存"},
          {value: '2.93', name: "海洋油气资源-勘探"},
          {value: '82.72', name: "海洋油气资源-钻采"},
          {value: '5.71', name: "海洋油气资源-其他"}
        ],
        [
          {value: '2.93', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
          {value: '0.76', name: "海洋可再生能源-海洋风能"},
          {value: '0.15', name: "海洋可再生能源-海洋温差能"},
          {value: '0.30', name: "海洋可再生能源-海洋综合能"},
          {value: '0.05', name: "海洋矿产资源-勘探"},
          {value: '2.93', name: "海洋矿产资源-钻采"},
          {value: '6.47', name: "海洋油气资源-储存"},
          {value: '2.93', name: "海洋油气资源-勘探"},
          {value: '82.72', name: "海洋油气资源-钻采"},
          {value: '5.71', name: "海洋油气资源-其他"}
        ],
        [
          {value: '2.93', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
          {value: '0.76', name: "海洋可再生能源-海洋风能"},
          {value: '0.15', name: "海洋可再生能源-海洋温差能"},
          {value: '0.30', name: "海洋可再生能源-海洋综合能"},
          {value: '0.05', name: "海洋矿产资源-勘探"},
          {value: '2.93', name: "海洋矿产资源-钻采"},
          {value: '6.47', name: "海洋油气资源-储存"},
          {value: '2.93', name: "海洋油气资源-勘探"},
          {value: '82.72', name: "海洋油气资源-钻采"},
          {value: '5.71', name: "海洋油气资源-其他"}
        ],
        [
          {value: '2.93', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
          {value: '0.76', name: "海洋可再生能源-海洋风能"},
          {value: '0.15', name: "海洋可再生能源-海洋温差能"},
          {value: '0.30', name: "海洋可再生能源-海洋综合能"},
          {value: '0.05', name: "海洋矿产资源-勘探"},
          {value: '2.93', name: "海洋矿产资源-钻采"},
          {value: '6.47', name: "海洋油气资源-储存"},
          {value: '2.93', name: "海洋油气资源-勘探"},
          {value: '82.72', name: "海洋油气资源-钻采"},
          {value: '5.71', name: "海洋油气资源-其他"}
        ],
        [
          {value: '2.93', name: "海洋可再生能源-海域潮汐能与海洋潮流能"},
          {value: '0.76', name: "海洋可再生能源-海洋风能"},
          {value: '0.15', name: "海洋可再生能源-海洋温差能"},
          {value: '0.30', name: "海洋可再生能源-海洋综合能"},
          {value: '0.05', name: "海洋矿产资源-勘探"},
          {value: '2.93', name: "海洋矿产资源-钻采"},
          {value: '6.47', name: "海洋油气资源-储存"},
          {value: '2.93', name: "海洋油气资源-勘探"},
          {value: '82.72', name: "海洋油气资源-钻采"},
          {value: '5.71', name: "海洋油气资源-其他"}
        ],
      ],

      // 资源专利申请趋势
      resPatentApp: 'china',
      resPatentData: {
        "china": {
          'kzs': {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['全部', '海洋风能', '海洋波浪能', '海洋综合能', '海洋潮汐能和海洋潮流能']
            },
            xAxis: {
              type: 'category',
              data: ['1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '全部',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 60, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 880, 1170, 1462, 1576, 2173, 2407, 2838, 3159, 3699, 804]
              },
              {
                name: '海洋风能',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 60, 100, 120, 90, 90, 150, 130, 230, 230, 20, 20, 508, 531, 704, 807, 1091, 1278, 1630, 1989, 2371, 538]
              },
              {
                name: '海洋波浪能',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 60, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 142, 206, 399, 267, 120, 387, 455, 414, 495, 112]
              },
              {
                name: '海洋综合能',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 60, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 122, 217, 199, 262, 379, 350, 400, 403, 417, 87]
              },
              {
                name: '海洋潮汐能和海洋潮流能',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 60, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 20, 59, 121, 133, 111, 141, 135, 154, 151, 29]
              },
            ]
          },
          'kczy': {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['全部', '勘探', '钻采', '储运', '其他']
            },
            xAxis: {
              type: 'category',
              data: ['1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '全部',
                type: 'line',
                stack: 'Total',
                data: [1, 1, 3, 1, 3, 0, 0, 2, 3, 2, 2, 7, 4, 7, 13, 15, 9, 13, 14, 34, 50, 35, 67, 100, 104, 133, 149, 213, 302, 349, 423, 525, 98]
              },
              {
                name: '勘探',
                type: 'line',
                stack: 'Total',
                data: [1, 1, 3, 1, 3, 0, 0, 2, 3, 2, 2, 4, 2, 1, 5, 7, 3, 9, 10, 16, 19, 13, 27, 31, 44, 58, 82, 89, 152, 159, 203, 230, 43]
              },
              {
                name: '钻采',
                type: 'line',
                stack: 'Total',
                data: [2, 2, 2, 1, 3, 0, 0, 2, 3, 2, 2, 6, 2, 4, 7, 8, 2, 3, 3, 18, 29, 20, 36, 66, 56, 75, 62, 111, 136, 175, 204, 272, 52]
              },
              {
                name: '储运',
                type: 'line',
                stack: 'Total',
                data: [1, 1, 3, 1, 3, 0, 0, 2, 3, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 5, 3, 8, 2]
              },
              {
                name: '其他',
                type: 'line',
                stack: 'Total',
                data: [1, 1, 3, 1, 3, 0, 0, 2, 3, 2, 2, 2, 0, 2, 1, 0, 4, 1, 1, 0, 2, 1, 4, 3, 4, 0, 5, 12, 13, 10, 13, 15, 1]
              },
            ]
          },
          'yqzy': {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['海洋油气资源', '勘探', '钻采', '储运', '其他']
            },
            xAxis: {
              type: 'category',
              data: ['1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海洋油气资源',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 32, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 690, 1153, 780, 1090, 1685, 1770, 1907, 1766, 1594, 246]
              },
              {
                name: '勘探',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 2, 100, 120, 90, 90, 150, 130, 230, 230, 20, 20, 60, 81, 100, 120, 108, 107, 93, 135, 124, 11]
              },
              {
                name: '钻采',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 26, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 690, 893, 780, 1090, 1336, 1432, 1556, 1408, 1226, 180]
              },
              {
                name: '储运',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 1, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 690, 102, 780, 1090, 138, 145, 179, 148, 157, 31]
              },
              {
                name: '其他',
                type: 'line',
                stack: 'Total',
                data: [10, 10, 30, 10, 30, 0, 0, 20, 30, 20, 20, 60, 3, 100, 120, 90, 90, 150, 130, 230, 230, 420, 360, 20, 77, 50, 80, 103, 86, 79, 75, 87, 24]
              },
            ]
          },
        },
        "guangdong": {
          'kzs': {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['海洋可再生能源']
            },
            xAxis: {
              type: 'category',
              data: ['1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海洋可再生能源',
                type: 'line',
                stack: 'Total',
                data: [0, 1, 3, 1, 3, 0, 0, 2, 3, 2, 2, 6, 7, 1, 0, 4, 4, 9, 9, 12, 13, 35, 55, 132, 116, 143, 124, 150, 198, 270, 380, 481, 115]
              },
            ]
          },
          'kczy': {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['海洋矿产资源', '勘探', '钻采', '储运', '其他']
            },
            xAxis: {
              type: 'category',
              data: ['1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海洋矿产资源',
                type: 'line',
                stack: 'Total',
                data: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 1, 0, 2, 1, 2, 6, 5, 14, 22, 22, 35, 52, 7]
              },
              {
                name: '勘探',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 0, 0, 2, 1, 2, 4, 3, 8, 16, 9, 17, 18, 3]
              },
              {
                name: '钻采',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 2, 2, 6, 5, 11, 15, 32, 4]
              },
              {
                name: '储运',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 0, 0]
              },
              {
                name: '其他',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 1, 2, 0]
              },
            ]
          },
          'yqzy': {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['海洋油气资源', '勘探', '钻采', '储运', '其他']
            },
            xAxis: {
              type: 'category',
              data: ['1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海洋油气资源',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 3, 0, 0, 3, 0, 2, 10, 11, 11, 24, 9, 18, 17, 33, 52, 64, 78, 77, 115, 13]
              },
              {
                name: '勘探',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 3, 5, 15, 1, 8, 10, 11, 0]
              },
              {
                name: '钻采',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 3, 0, 2, 9, 8, 9, 20, 8, 7, 12, 20, 21, 41, 52, 49, 82, 10]
              },
              {
                name: '储运',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 4, 0, 2, 11, 9, 12, 14, 16, 3]
              },
              {
                name: '其他',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 1, 0, 7, 2, 6, 5, 13, 6, 4, 6, 0]
              },
            ]
          },
        }
      },
      resPatentTemp: {},
      resPatentAppSecTitle: 'kzs',

      // 公司专利申请趋势
      comApply: '',
      comApplyDate: [
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [0, 10, 20, 10, 10, 260, 208, 44, 86, 88, 77, 60, 22, 16, 10, 5, 2]
            },
          ]
        },
        {
          title: {
            text: '中海油田服务股份有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            max: 'dataMax',
            data: ['1999', '2000', '2001', '2002', '2003', '2004', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [1, 0, 2, 2, 2, 10, 20, 15, 25, 35, 40, 44, 55, 45, 88, 76, 76, 90, 34, 32, 38, 10]
            },
          ]
        },
        {
          title: {
            text: '中国石油化工股份有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            max: 'dataMax',
            data: ['2003', '2004', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [2, 0, 1, 4, 5, 10, 20, 30, 54, 108, 112, 177, 150, 170, 170, 270, 230, 20]
            },
          ]
        },
        {
          title: {
            text: '中国石油集团渤海钻探工程有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            max: 'dataMax',
            data: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [10, 18, 20, 30, 53, 100, 156, 210, 260, 207, 140, 130, 156, 20]
            },
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            max: 'dataMax',
            data: ['2003', '2004', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [2, 0, 1, 4, 5, 10, 20, 30, 54, 108, 112, 177, 150, 170, 170, 270, 230, 20]
            },
          ]
        },
      ],
      comApplyTemp: {},

      // 资源专利技术分类
      resPatentTech: 'china',
      resPatentTechData: {
        'china': {
          'kzs': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '28.78', name: "F03B"},
                  {value: '21.56', name: "F03D"},
                  {value: '15.35', name: "E02D"},
                  {value: '8.62', name: "B63B"},
                  {value: '8.36', name: "H02J"},
                  {value: '4.20', name: "E02B"},
                  {value: '3.87', name: "H02S"},
                  {value: '3.30', name: "B66C"},
                  {value: '3.29', name: "H02K"},
                  {value: '2.66', name: "H01B"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'kczy': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '33.48', name: "E21B"},
                  {value: '18.52', name: "G01N"},
                  {value: '16.90', name: "E21C"},
                  {value: '14.06', name: "G01V"},
                  {value: '6.70', name: "B63C"},
                  {value: '3.66', name: "B63B"},
                  {value: '1.76', name: "G05D"},
                  {value: '1.42', name: "B25J"},
                  {value: '1.23', name: "G05B"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'yqzy': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '21.48', name: "E21B"},
                  {value: '18.52', name: "B63B"},
                  {value: '16.90', name: "C09K"},
                  {value: '14.06', name: "E02B"},
                  {value: '16.70', name: "G01V"},
                  {value: '4.66', name: "G01N"},
                  {value: '1.76', name: "F16L"},
                  {value: '1.42', name: "F17D"},
                  {value: '1.23', name: "G01M"},
                  {value: '1.23', name: "E02D"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        },
        'guangdong': {
          'kzs': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '28.78', name: "F03B"},
                  {value: '21.56', name: "F03D"},
                  {value: '15.35', name: "E02D"},
                  {value: '8.62', name: "B63B"},
                  {value: '8.36', name: "H02J"},
                  {value: '4.20', name: "E02B"},
                  {value: '3.87', name: "H02S"},
                  {value: '3.30', name: "B66C"},
                  {value: '3.29', name: "H02K"},
                  {value: '2.66', name: "H01B"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'kczy': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '33.48', name: "E21B"},
                  {value: '18.52', name: "G01N"},
                  {value: '16.90', name: "E21C"},
                  {value: '14.06', name: "G01V"},
                  {value: '6.70', name: "B63C"},
                  {value: '3.66', name: "B63B"},
                  {value: '1.76', name: "G05D"},
                  {value: '1.42', name: "B25J"},
                  {value: '1.23', name: "G05B"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'yqzy': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '21.48', name: "E21B"},
                  {value: '18.52', name: "B63B"},
                  {value: '16.90', name: "C09K"},
                  {value: '14.06', name: "E02B"},
                  {value: '16.70', name: "G01V"},
                  {value: '4.66', name: "G01N"},
                  {value: '1.76', name: "F16L"},
                  {value: '1.42', name: "F17D"},
                  {value: '1.23', name: "G01M"},
                  {value: '1.23', name: "E02D"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        },
      },
      resPatentTechTemp: '',
      resPatentTechSecTitle: 'kzs',

      // 公司专利技术分类
      comResPatentTech: '',
      comResPatentTechDate: [
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '74.56', name: "E21B"},
                {value: '4.95', name: "B63B"},
                {value: '4.29', name: "E02B"},
                {value: '4.05', name: "C09K"},
                {value: '2.84', name: "G01N"},
                {value: '2.72', name: "G01V"},
                {value: '1.93', name: "G01M"},
                {value: '2.84', name: "F17D"},
                {value: '1.63', name: "F16L"},
                {value: '1.27', name: "E02D"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中海油田服务股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '71.83', name: "B21B"},
                {value: '7.48', name: "G01V"},
                {value: '7.43', name: "C09K"},
                {value: '3.60', name: "G01N"},
                {value: '2.14', name: "H01R"},
                {value: '2', name: "G01M"},
                {value: '1.87', name: "E02B"},
                {value: '1.34', name: "E25B"},
                {value: '1.20', name: "B63B"},
                {value: '1.20', name: "G06F"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油化工股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '78.46', name: "E21B"},
                {value: '10.12', name: "C09K"},
                {value: '4.70', name: "G01N"},
                {value: '2.22', name: "G06F"},
                {value: '1.17', name: "G01V"},
                {value: '1.11', name: "G06Q"},
                {value: '0.98', name: "G01M"},
                {value: '0.46', name: "C08F"},
                {value: '0.39', name: "B63B"},
                {value: '0.39', name: "G01L"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油集团渤海钻探工程有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '8.62', name: "B63B"},
                {value: '8.36', name: "H02J"},
                {value: '4.20', name: "E02B"},
                {value: '3.87', name: "H02S"},
                {value: '3.30', name: "B66C"},
                {value: '3.29', name: "H02K"},
                {value: '2.66', name: "H01B"},
                {value: '28.78', name: "F03B"},
                {value: '21.56', name: "F03D"},
                {value: '15.35', name: "E02D"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '74.08', name: "E21B"},
                {value: '5.68', name: "B63B"},
                {value: '4.69', name: "E02B"},
                {value: '3.39', name: "C09K"},
                {value: '2.86', name: "G01N"},
                {value: '2.63', name: "G01V"},
                {value: '1.98', name: "F16L"},
                {value: '21.79', name: "G01M"},
                {value: '1.49', name: "E02D"},
                {value: '1.41', name: "F17D"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
      ],
      comResPatentTechTemp: {},

      // 资源专利主要发明人
      resPatentIntor: 'china',
      resPatentIntorData: {
        'china': {
          'kzs': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['练继建',
                '朱荣华',
                '丁红岩',
                '张浦阳',
                '高宏飙',
                '王磊',
                '王海军',
                '陈强',
                '王涛',
                '姜贞强']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [287, 150, 143, 134, 124, 116, 112, 112, 107, 106]
              },
            ]
          },
          'kczy': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['万步炎',
                '金永平',
                '高宇清',
                '程阳铣',
                '唐红平',
                '彭建平',
                '李俊',
                '黄筱军',
                '吴鸿云',
                '李小艳']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [72, 65, 63, 59, 53, 52, 44, 42, 41, 39]
              },
            ]
          },
          'yqzy': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['蒋廷学',
                '刘书杰',
                '周建良',
                '张勇',
                '杨进',
                '许亮斌',
                '姜伟',
                '曾义金',
                '张伟',
                '冯强']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [182, 152, 136, 132, 129, 124, 119, 113, 104, 101]
              },
            ]
          }
        },
        'guangdong': {
          'kzs': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['朱荣华',
                '马兆荣',
                '毕明君',
                '刘晋超',
                '傅强',
                '张美阳',
                '田振亚',
                '元国凯',
                '邹荔兵',
                '徐龙博']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [126, 63, 52, 51, 49, 47, 46, 44, 40, 39]
              },
            ]
          },
          'kczy': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['陈向东',
                '刘建成',
                '徐立新',
                '潘化若',
                '王偲',
                '谢文卫',
                '陈道华',
                '卢秋平',
                '孙铭远',
                '张伟']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [9, 8, 8, 7, 7, 7, 7, 6, 6, 6]
              },
            ]
          },
          'yqzy': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['贾银鸽',
                '张武辇',
                '秦绪文',
                '寇贝贝',
                '叶建良',
                '苗建',
                '李光远',
                '郭德林',
                '黄芳飞',
                '于彦江']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [30, 27, 24, 21, 19, 18, 17, 17, 17, 16]
              },
            ]
          }
        },
      },
      resPatentIntorTemp: '',
      resPatentIntorSecTitle: 'kzs',

      // 公司专利发明人
      comPatInventor: '',
      comPatInventorData: [
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: ['周建良',
              '刘书杰',
              '许亮斌',
              '李中',
              '菫社霞',
              '杨万有',
              '蒋世全',
              '李强',
              '王春升',
              '盛磊祥']
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [101, 100, 82, 60, 60, 59, 54, 51, 51, 50]
            },
          ]
        },
        {
          title: {
            text: '中国油田服务股份有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: ['董社霞',
              '冯永仁',
              '孙永涛',
              '耿铁',
              '刘刚芝',
              '侯洪为',
              '宋林松',
              '王野粱',
              '马增华',
              '宋公仆']
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [60, 55, 499, 40, 36, 34, 32, 31, 31, 30]
            },
          ]
        },
        {
          title: {
            text: '中国石油化工股份股份有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: ['蒋廷学',
              '曾义金',
              '张卫',
              '李奎为',
              '李洪春',
              '马兰菜',
              '卫然',
              '周林波',
              '王海涛',
              '刘建坤']
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [182, 113, 79, 79, 78, 75, 74, 73, 72, 71]
            },
          ]
        },
        {
          title: {
            text: '中国石油集团渤海钻探工程有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: ['冯强',
              '周俊然',
              '王林',
              '韩振强',
              '马金山',
              '苟旭东',
              '蒋本强',
              '蒋海涛',
              '张京华',
              '王益山']
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [101, 74, 70, 70, 69, 66, 65, 64, 63, 63]
            },
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
            max: 'dataMax'
          },
          yAxis: {
            type: 'category',
            data: ['刘书杰',
              '周建良',
              '许亮斌',
              '姜伟',
              '蒋世全',
              '杨万有',
              '王春升',
              '张勇',
              '李中',
              '谢彬']
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [130, 129, 115, 112, 89, 78, 74, 73, 71, 67]
            },
          ]
        },
      ],
      comPatInventorTemp: '',

      // 公司专利申请类型
      comApplyType: '',
      comApplyTypeData: [
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '57.82', name: "发明申请"},
                {value: '42.18', name: "实用类型"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中海油田服务股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '50.92', name: "发明申请"},
                {value: '49.08', name: "实用类型"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油化工股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '71.89', name: "发明申请"},
                {value: '28.11', name: "实用类型"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油集团渤海钻探工程有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '41.01', name: "发明申请"},
                {value: '58.99', name: "实用类型"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '28.11', name: "发明申请"},
                {value: '71.89', name: "实用类型"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
      ],
      comApplyTypeTemp: '',

      // 资源专利申请人分布
      distOfResPatApp: 'china',
      distOfResPatAppData: {
        'china': {
          'kzs': {
            grid: {
              left: '26%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',

              data: ['天津大学', '大连理工大学', '浙江大学', '中国电建集团华东勘测设计研究院', '上海交通大学', '国家电网公司', '哈尔滨工业大学', '国电联合动力技术有限公司', '浙江海洋大学', '中国海洋大学']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [453, 392, 307, 289, 262, 225, 202, 200, 196, 189]
              },
            ]
          },
          'kczy': {
            grid: {
              left: '26%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['长沙矿治研究院有限责任公司', '中国海洋大学', '湖南科技大学', '中南大学', '浙江大学', '长沙矿山研究院有限责任公司', '上海交通大学', '杭州电子科技大学', '广州海洋地质调查局', '国家海洋局第一海洋研究所']
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [95, 86, 78, 69, 67, 58, 57, 43, 40, 39]
              },
            ]
          },
          'yqzy': {
            grid: {
              left: '26%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '中国海洋石油总公司',
                '中国石油化工股份有限公司',
                '中国石油集团渤海钻探工程有限公司',
                '中国石油化工股份有限公司石油工程技术研究院',
                '中海油田服务有限公司',
                '中国海洋石油集团有限公司',
                '中海石油能源发展股份有限公司',
                '中国石油大学(华东)',
                '中国石油大学(北京)',
                '中国石油集团渤海石油装备制造有限公司',
                '中海石油(中国)有限公司湛江分公司',
                '西南石油大学',
                '中海油研究总院',
                '海洋石油工程股份有限公司',
                '中石化石油工程技术服务有限公司',
                '中海石油(中国)有限公司天津分公司',
                '中国石油天然气集团有限公司',
                '中海石油研究中心',
                '中石化胜利石油工程有限公司',
                '宝鸡石油机械有限责任公司'
              ]
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [2287, 1567, 1518, 1221, 849, 724, 689, 401, 370, 370, 352, 352, 338, 294, 291, 287, 282, 245, 216, 173]
              },
            ]
          }
        },
        'guangdong': {
          'kzs': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '中国能源建设集团广东省电力设计研究院有限公司',
                '明阳智慧能源集团股份公司',
                '广东明阳风电产业集团有限公司',
                '华南理工大学',
                '南方电网科学研究院有限责任公司',
                '广东海洋大学',
                '中国科学院广州能源研究所',
                '广东工业大学',
                '中山大学',
                '中国能源建设集团广东省电力设计研究院'
              ]
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [163, 152, 142, 98, 65, 61, 54, 42, 31, 26]
              },
            ]
          },
          'kczy': {
            grid: {
              left: '30%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: ['广州海洋地质调查局',
                '广东工业大学',
                '招商局海洋装备研究院有限公司',
                '中国科学院南海海洋研究所',
                '广东海洋大学',
                '中交第四航务工程勘察设计院有限公司',
                '中国科学院广州地球化学研究所',
                '南方海洋科学与工程广东省实验室(广州)',
                '广东安元矿业勘察设计有限公司',
                '广东技术师范学院'
              ]
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [31, 10, 8, 6, 6, 5, 4, 4, 4, 4]
              },
            ]
          },
          'yqzy': {
            grid: {
              left: '30%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '广州海洋地质调查局',
                '中海石油深海开发有限公司',
                '中海石油(中国)有限公司深圳分公司',
                '中海石油(中国)有限公司湛江分公司',
                '深圳市远东石油钻采工程有限公司',
                '深圳市惠尔凯博海洋工程有限公司',
                '深圳海油工程水下技术有限公司',
                '中国科学院南海海洋研究所',
                '广东工业大学',
                '中国国际海运集装箱(集团)股份有限公司'
              ]
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [52, 29, 26, 22, 22, 11, 10, 9, 9, 8]
              },
            ]
          }
        }
      },
      distOfResPatAppTemp: '',
      distOfResPatAppSecTitle: 'kzs',

      // 公司专利有效性
      comPatValidity: 'zlyxxfl',
      comPatValidityData: {
        'zlyxxfl': [
          {
            title: {
              text: '中国海洋石油集团有限公司'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['失效', '审中', '有效']
            },
            series: [
              {
                name: '发明申请',
                type: 'bar',
                data: [101, 385, 660]
              },
              {
                name: '实用新型',
                type: 'bar',
                data: [101, 0, 660]
              }
            ]
          },
          {
            title: {
              text: '中海油田服务股份有限公司'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['失效', '审中', '有效']
            },
            series: [
              {
                name: '发明申请',
                type: 'bar',
                data: [107, 117, 217]
              },
              {
                name: '实用新型',
                type: 'bar',
                data: [130, 0, 295]
              }
            ]
          },
          {
            title: {
              text: '中国石油化工股份有限公司'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['失效', '审中', '有效']
            },
            series: [
              {
                name: '发明申请',
                type: 'bar',
                data: [177, 447, 568]
              },
              {
                name: '实用新型',
                type: 'bar',
                data: [152, 0, 314]
              }
            ]
          },
          {
            title: {
              text: '中国海洋石油集团渤海钻探工程有限公司'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['失效', '审中', '有效']
            },
            series: [
              {
                name: '发明申请',
                type: 'bar',
                data: [203, 208, 228]
              },
              {
                name: '实用新型',
                type: 'bar',
                data: [268, 0, 651]
              }
            ]
          },
          {
            title: {
              text: '中国海洋石油总公司'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['失效', '审中', '有效']
            },
            series: [
              {
                name: '发明申请',
                type: 'bar',
                data: [509, 393, 776]
              },
              {
                name: '实用新型',
                type: 'bar',
                data: [639, 0, 789]
              }
            ]
          }
        ],
        'sxyy': [
          {
            title: {
              text: '中国海洋石油集团有限公司'
            },
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                data: [
                  {value: '142', name: ""},
                  {value: '246', name: ""},
                  {value: '31', name: ""}
                ]
              }
            ]
          },
          {
            title: {
              text: '中海油田服务股份有限公司'
            },
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                data: [
                  {value: '142', name: ""},
                  {value: '246', name: ""},
                  {value: '31', name: ""}
                ]
              }
            ]
          },
          {
            title: {
              text: '中国石油化工股份有限公司'
            },
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                data: [
                  {value: '142', name: ""},
                  {value: '246', name: ""},
                  {value: '31', name: ""}
                ]
              }
            ]
          },
          {
            title: {
              text: '中国海洋石油集团渤海钻探工程有限公司'
            },
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                data: [
                  {value: '142', name: ""},
                  {value: '246', name: ""},
                  {value: '31', name: ""}
                ]
              }
            ]
          },
          {
            title: {
              text: '中国海洋石油总公司'
            },
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                data: [
                  {value: '142', name: ""},
                  {value: '246', name: ""},
                  {value: '31', name: ""}
                ]
              }
            ]
          }
        ]
      },
      comPatValidityTemp: '',
      /** 海洋工程装备 **/

      /** 海洋工程船舶 **/
      // 发明人
      inventor: 'sb',
      inventorData: {
        'dq': {
          'bj': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['陈兵',
                '李小灵',
                '袁洪涛',
                '吴富生',
                '陈建平',
                '范会渠',
                '曾骥',
                '陈刚',
                '付世晓',
                '朱彦'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [46, 34, 33, 30, 30, 26, 23, 23, 21, 20]
              }
            ]
          },
          'sh': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['丁郁华',
                '周宏勤',
                '谷家扬',
                '张永康',
                '宋勇荣',
                '李荣',
                '段凤江',
                '王振刚',
                '姚震球',
                '庄建军'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [38, 28, 24, 20, 19, 19, 17, 17, 16, 16]
              }
            ]
          },
          'gd': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['谢彬',
                '谢文会',
                '王春升',
                '王世圣',
                '范模',
                '冯加果',
                '赵晶瑞',
                '喻西崇',
                '李达',
                '杨贵强'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [92, 58, 54, 53, 53, 43, 35, 34, 33, 33]
              }
            ]
          },
          'js': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['陈兵',
                '李小灵',
                '袁洪涛',
                '吴富生',
                '陈建平',
                '范会渠',
                '曾骥',
                '陈刚',
                '付世晓',
                '朱彦'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [46, 34, 33, 30, 30, 26, 23, 23, 21, 20]
              }
            ]
          },
          'sd': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['张磊',
                '陈焕东',
                '唐军',
                '李光远',
                '陆军',
                '马振军',
                '张静波',
                '秦绪文',
                '傅强',
                '古国维'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [20, 20, 16, 16, 16, 16, 15, 15, 14, 14]
              }
            ]
          },
          'zj': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['邬宾杰',
                '张元元',
                '朱永灵',
                '张登',
                '张涛',
                '谢永和',
                '张志刚',
                '楼眺华',
                '贺波',
                '陈勇'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [38, 32, 25, 24, 23, 22, 16, 16, 16, 16]
              }
            ]
          },
        },
        'sb': {
          'fsscsb': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ["王春升", "谢彬", "笵模", "杨贵强", "王世胜", "喻西崇", "谢文会", "杨静", "冯加果"],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [34, 34, 28, 27, 27, 24, 24, 20, 19]
              }
            ]
          },
          'gcc': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['李斌',
                '周宏勤',
                '张志刚',
                '王晓波',
                '王学军',
                '丁兆冈',
                '李建楠',
                '翟世鸿',
                '陈永訢'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [22, 21, 19, 19, 18, 17, 17, 17, 17]
              }
            ]
          },
          'hgzc': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['傅强',
                '谢彬',
                '陈兵',
                '李磊',
                '丁郁华',
                '李小灵',
                '谢文会',
                '陈建平',
                '刘刚'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [49, 46, 46, 42, 35, 34, 32, 30, 27]
              }
            ]
          },
          'hsfd': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['冯甲鑫',
                '刘德进',
                '曲俐俐',
                '练继建',
                '张竹',
                '徐善忠',
                '杨余',
                '张虎',
                '周华'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [22, 21, 18, 17, 16, 15, 15, 14, 13]
              }
            ]
          },
          'dcc': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['邬宾杰',
                '张元元',
                '张志平',
                '朱永灵',
                '靳春光',
                '张登',
                '张涛',
                '张浩然',
                '朱耀强'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [34, 32, 29, 25, 25, 24, 23, 21, 21]
              }
            ]
          },
          'ydzj': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
            },
            yAxis: {
              type: 'category',
              data: ['许亮斌',
                '齐宝兰',
                '李磊',
                '杨进',
                '佟国志',
                '孙瑞雪',
                '徐锦诚',
                '杨鹏',
                '傅强'],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [22, 19, 17, 16, 15, 15, 15, 15, 14]
              }
            ]
          },
        }
      },
      inventorTemp: '',
      inventorSecTitle: 'fsscsb',

      // 公司发明人
      comInventor: '',
      comInventorData: [
        {
          title: {
            text: '烟台中集来福士海洋工程有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['傅强',
              '李磊',
              '滕瑶',
              '王如壮',
              '张工',
              '肖元',
              '贺昌海',
              '韩华伟',
              '宋述占'],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [60, 42, 35, 32, 31, 31, 25, 25, 21]
            }
          ]
        },
        {
          title: {
            text: '海洋石油工程股份有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['李斌',
              '李怀亮',
              '王晓波',
              '罗勇',
              '钟文军',
              '朱绍华',
              '何宁',
              '李建楠',
              '陈永訢',
              '刘培林'],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [22, 21, 19, 19, 19, 18, 17, 17, 17, 14]
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['谢彬',
              '谢文会',
              '范模',
              '王春升',
              '王世圣',
              '谢玉洪',
              '马勇新',
              '喻西崇',
              '冯加果',
              '杜庆贵'],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [34, 21, 20, 19, 15, 13, 13, 12, 11, 11]
            }
          ]
        },
        {
          title: {
            text: '上海振华重工(集团)股份有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['王文涛',
              '丛海军',
              '尹刚',
              '林呜',
              '何可耕',
              '李辉',
              '王学军',
              '管彤贤',
              '蔡东伟',
              '龚钰晟'],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [9, 7, 7, 6, 4, 4, 4, 4, 4, 4]
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['谢彬',
              '范模',
              '李达',
              '叚瑞芳',
              '赵晶瑞',
              '张振波',
              '曾翔',
              '许亮斌',
              '钟文军',
              '曹曹静'],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [22, 9, 7, 7, 7, 6, 5, 5, 5, 4]
            }
          ]
        },
      ],
      comInventorTemp: '',

      // 申请趋势
      sqqs: 'china',
      sqqsData: {
        'china': {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['全部', '浮式生产设备', '工程船', '调查船', '移动钻井平台', '海上风电设备', '海工支持设备']
          },
          xAxis: {
            type: 'category',
            data: ['1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value',
            max: 'dataMax'
          },
          categories: [],
          series: [
            {
              name: '海工支持设备',
              type: 'line',
              stack: 'Total',
              data: [4, 3, 1, 1, 0, 0, 2, 1, 2, 0, 0, 1, 3, 1, 1, 1, 1, 5, 2, 2, 3, 10, 4, 28, 50, 34, 60, 111, 98, 138, 157, 174, 121, 144, 133, 106, 26]
            },
            {
              name: '海上风电设备',
              type: 'line',
              stack: 'Total',
              data: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 5, 1, 4, 2, 1, 4, 6, 2, 7, 1, 1, 15, 26, 16, 43, 48, 76, 90, 81, 122, 129, 182, 194, 176, 30]
            },
            {
              name: '移动钻井平台',
              type: 'line',
              stack: 'Total',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 3, 15, 20, 46, 42, 27, 36, 42, 55, 65, 103, 96, 169, 19]
            },
            {
              name: '调查船',
              type: 'line',
              stack: 'Total',
              data: [1, 1, 3, 1, 1, 0, 0, 2, 5, 1, 4, 1, 3, 1, 3, 6, 5, 9, 6, 16, 11, 17, 9, 44, 98, 79, 159, 147, 197, 295, 324, 387, 389, 434, 466, 451, 76]
            },
            {
              name: '工程船',
              type: 'line',
              stack: 'Total',
              data: [1, 0, 1, 0, 0, 1, 0, 0, 1, 2, 1, 1, 1, 1, 5, 7, 1, 2, 18, 8, 13, 7, 9, 22, 39, 57, 61, 65, 83, 82, 103, 83, 122, 117, 140, 127, 28]
            },
            {
              name: '浮式生产设备',
              type: 'line',
              stack: 'Total',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 4, 1, 0, 4, 3, 3, 5, 14, 12, 15, 24, 30, 50, 76, 57, 86, 92, 98, 95, 125, 105, 116, 22]
            },
            {
              name: '全部',
              type: 'line',
              stack: 'Total',
              data: [6, 5, 5, 2, 1, 1, 2, 3, 8, 4, 6, 5, 13, 6, 17, 18, 8, 24, 35, 32, 38, 49, 57, 127, 252, 236, 419, 489, 538, 727, 798, 919, 921, 1105, 1113, 1145, 201]
            },
          ]
        },
        'main': {
          'qb': {
            legend: {
              data: ['上海', '北京', '广东', '江苏', '山东', '浙江']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value',
              max: 'dataMax'
            },
            categories: [],
            series: [
              {
                name: '浙江',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 1, 2, 6, 14, 27, 48, 42, 66, 104, 53, 71, 74, 64, 13]
              },
              {
                name: '广东',
                type: 'line',
                stack: 'Total',
                data: [1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 10, 1, 1, 2, 5, 0, 0, 0, 0, 1, 6, 29, 26, 32, 46, 66, 102, 108, 107, 114, 163, 37]
              },
              {
                name: '山东',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 2, 2, 0, 1, 3, 1, 1, 10, 18, 13, 22, 44, 33, 104, 62, 66, 88, 131, 129, 105, 18]
              },
              {
                name: '北京',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 1, 3, 6, 3, 7, 19, 21, 22, 44, 54, 87, 86, 69, 99, 93, 85, 91, 55, 106, 104, 9]
              },
              {
                name: '江苏',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 5, 1, 1, 0, 6, 15, 41, 23, 49, 59, 78, 77, 105, 141, 124, 154, 157, 149, 23]
              },
              {
                name: '上海',
                type: 'line',
                stack: 'Total',
                data: [0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 10, 2, 3, 6, 6, 10, 30, 35, 32, 63, 40, 115, 122, 126, 142, 140, 177, 210, 36]
              },
            ]
          },
          'bj': {
            legend: {
              data: ['浮式生产设备', '工程船', '调查船', '移动钻井设备', '海上风电设备', '海工支持设备']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海工支持设备',
                type: 'line',
                stack: 'Total',
                data: [2, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 5, 0, 9, 13, 13, 21, 16, 11, 32, 29, 30, 31, 16, 31, 40, 0]
              },
              {
                name: '海上风电设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 3, 2, 6, 1, 6, 0, 2, 2, 2, 3, 9, 21, 0]
              },
              {
                name: '移动钻井设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 2, 12, 6, 15, 8, 11, 21, 6, 4, 9, 8, 11, 7, 14, 16, 3]
              },
              {
                name: '调查船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 2, 3, 3, 2, 10, 11, 14, 15, 10, 14, 12, 28, 5, 1]
              },
              {
                name: '工程船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 6, 0, 2, 2, 5, 21, 19, 14, 16, 20, 19, 12, 7, 5, 14, 13, 2]
              },
              {
                name: '浮式生产设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 11, 6, 2, 5, 7, 28, 24, 19, 29, 19, 23, 26, 12, 10, 9, 3]
              },
            ]
          },
          'sh': {
            legend: {
              data: ['浮式生产设备', '工程船', '调查船', '移动钻井设备', '海上风电设备', '海工支持设备']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海工支持设备',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 2, 1, 3, 7, 14, 21, 13, 18, 55, 64, 63, 69, 77, 91, 112, 16]
              },
              {
                name: '海上风电设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 5, 4, 7, 2, 3, 6, 6, 11, 15, 20, 15, 5]
              },
              {
                name: '移动钻井设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 2, 1, 9, 8, 28, 25, 26, 9, 11, 12, 10, 8]
              },
              {
                name: '调查船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 6, 2, 12, 5, 11, 19, 12, 20, 32, 2]
              },
              {
                name: '工程船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 2, 2, 2, 4, 14, 5, 5, 11, 8, 12, 15, 12, 31, 21, 20, 15, 2]
              },
              {
                name: '浮式生产设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 11, 6, 2, 5, 7, 28, 24, 19, 29, 19, 23, 26, 12, 10, 9, 3]
              },
            ]
          },
          'gd': {
            legend: {
              data: ['浮式生产设备', '工程船', '调查船', '移动钻井设备', '海上风电设备', '海工支持设备']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海工支持设备',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 2, 1, 3, 7, 14, 21, 13, 18, 55, 64, 63, 69, 77, 91, 112, 16]
              },
              {
                name: '海上风电设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 5, 4, 7, 2, 3, 6, 6, 11, 15, 20, 15, 5]
              },
              {
                name: '移动钻井设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 2, 1, 9, 8, 28, 25, 26, 9, 11, 12, 10, 8]
              },
              {
                name: '调查船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 6, 2, 12, 5, 11, 19, 12, 20, 32, 2]
              },
              {
                name: '工程船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 2, 2, 2, 4, 14, 5, 5, 11, 8, 12, 15, 12, 31, 21, 20, 15, 2]
              },
              {
                name: '浮式生产设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 11, 6, 2, 5, 7, 28, 24, 19, 29, 19, 23, 26, 12, 10, 9, 3]
              },
            ]
          },
          'js': {
            legend: {
              data: ['浮式生产设备', '工程船', '调查船', '移动钻井设备', '海上风电设备', '海工支持设备']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海工支持设备',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 2, 1, 3, 7, 14, 21, 13, 18, 55, 64, 63, 69, 77, 91, 112, 16]
              },
              {
                name: '海上风电设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 5, 4, 7, 2, 3, 6, 6, 11, 15, 20, 15, 5]
              },
              {
                name: '移动钻井设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 2, 1, 9, 8, 28, 25, 26, 9, 11, 12, 10, 8]
              },
              {
                name: '调查船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 6, 2, 12, 5, 11, 19, 12, 20, 32, 2]
              },
              {
                name: '工程船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 2, 2, 2, 4, 14, 5, 5, 11, 8, 12, 15, 12, 31, 21, 20, 15, 2]
              },
              {
                name: '浮式生产设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 11, 6, 2, 5, 7, 28, 24, 19, 29, 19, 23, 26, 12, 10, 9, 3]
              },
            ]
          },
          'sd': {
            legend: {
              data: ['浮式生产设备', '工程船', '调查船', '移动钻井设备', '海上风电设备', '海工支持设备']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海工支持设备',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 2, 1, 3, 7, 14, 21, 13, 18, 55, 64, 63, 69, 77, 91, 112, 16]
              },
              {
                name: '海上风电设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 5, 4, 7, 2, 3, 6, 6, 11, 15, 20, 15, 5]
              },
              {
                name: '移动钻井设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 2, 1, 9, 8, 28, 25, 26, 9, 11, 12, 10, 8]
              },
              {
                name: '调查船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 6, 2, 12, 5, 11, 19, 12, 20, 32, 2]
              },
              {
                name: '工程船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 2, 2, 2, 4, 14, 5, 5, 11, 8, 12, 15, 12, 31, 21, 20, 15, 2]
              },
              {
                name: '浮式生产设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 11, 6, 2, 5, 7, 28, 24, 19, 29, 19, 23, 26, 12, 10, 9, 3]
              },
            ]
          },
          'zj': {
            legend: {
              data: ['浮式生产设备', '工程船', '调查船', '移动钻井设备', '海上风电设备', '海工支持设备']
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '海工支持设备',
                type: 'line',
                stack: 'Total',
                data: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 1, 2, 1, 3, 7, 14, 21, 13, 18, 55, 64, 63, 69, 77, 91, 112, 16]
              },
              {
                name: '海上风电设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 5, 4, 7, 2, 3, 6, 6, 11, 15, 20, 15, 5]
              },
              {
                name: '移动钻井设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 2, 1, 9, 8, 28, 25, 26, 9, 11, 12, 10, 8]
              },
              {
                name: '调查船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 3, 6, 2, 12, 5, 11, 19, 12, 20, 32, 2]
              },
              {
                name: '工程船',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 0, 2, 2, 2, 4, 14, 5, 5, 11, 8, 12, 15, 12, 31, 21, 20, 15, 2]
              },
              {
                name: '浮式生产设备',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 11, 6, 2, 5, 7, 28, 24, 19, 29, 19, 23, 26, 12, 10, 9, 3]
              },
            ]
          },
        },
      },
      sqqsSecTitle: 'qb',


      // 公司申请趋势
      comAppTrend: '',
      comAppTrendData: [
        {
          title: {
            text: '烟台中集来福士海洋工程有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [1, 3, 4, 5, 14, 16, 24, 25, 18, 15, 24, 10, 9, 5]
            },
          ]
        },
        {
          title: {
            text: '海洋石油工程股份有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [2, 0, 0, 1, 6, 13, 22, 16, 9, 11, 23, 22, 22, 24, 23, 26, 35, 8]
            },
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '',
              type: 'line',
              stack: 'Total',
              data: [0, 3, 2, 13, 12, 11, 16, 20, 21, 35, 13, 23, 16, 12, 6, 0, 0, 0, 0]
            },
          ]
        },
        {
          title: {
            text: '上海振华重工(集团)股份有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '全部',
              type: 'line',
              stack: 'Total',
              data: [2, 4, 6, 2, 8, 11, 19, 11, 16, 12, 26, 19, 18, 19, 4]
            },
          ]
        },
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            data: ['2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
          },
          yAxis: {
            type: 'value'
          },
          categories: [],
          series: [
            {
              name: '全部',
              type: 'line',
              stack: 'Total',
              data: [4, 0, 0, 4, 6, 7, 16, 18, 25, 20, 23, 50, 48, 39, 20, 16, 15, 17, 0]
            },
          ]
        },
      ],
      comAppTrendTemp: '',

      // 技术分布
      techDist: 'sb',
      techDistData: {
        'sb': {
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '13.57', name: "调查船"},
                {value: '12.95', name: "工程船"},
                {value: '15.28', name: "移动钻井设备"},
                {value: '11.16', name: "浮式生产设备"},
                {value: '7.94', name: "海上风电设备"},
                {value: '39.10', name: "海工支持设备"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        'dq': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            data: ['全部', '浮式生产设备', '工程船', '调查船', '移动钻井平台', '海上风电设备', '海工支持设备']
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['上海',
              '江苏',
              '北京',
              '山东',
              '广东',
              '浙江',
            ],
          },
          series: [
            {
              name: '浮式生产设备',
              type: 'bar',
              stack: 'Total',
              data: [99, 78, 236, 72, 89, 7]
            },
            {
              name: '工程船',
              type: 'bar',
              stack: 'Total',
              data: [195, 164, 179, 70, 73, 90]
            },
            {
              name: '调查船',
              type: 'bar',
              stack: 'Total',
              data: [127, 95, 132, 181, 105, 151]
            },
            {
              name: '移动钻井平台',
              type: 'bar',
              stack: 'Total',
              data: [154, 139, 154, 196, 86, 60]
            },
            {
              name: '海上风电设备',
              type: 'bar',
              stack: 'Total',
              data: [103, 205, 59, 27, 84, 28]
            },
            {
              name: '海工支持设备',
              type: 'bar',
              stack: 'Total',
              data: [630, 530, 306, 316, 416, 252]
            },
          ]
        },
      },
      techDistTemp: '',

      // 公司技术分支
      comTechBranch: '',
      comTechBranchData: [
        {
          title: {
            text: '烟台中集来福士海洋工程有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '44.30', name: "钻井平台"},
                {value: '21.52', name: "海工支持船"},
                {value: '20.25', name: "钻井船"},
                {value: '3.80', name: "浮式生产储卸油装置"},
                {value: '3.80', name: "科考船"},
                {value: '2.53', name: "起重船"},
                {value: '1.27', name: "铺管船"},
                {value: '1.27', name: "铺缆船"},
                {value: '1.27', name: "勘探船"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '海洋石油工程股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '48.39', name: "浮式生产储卸油装置"},
                {value: '23.87', name: "海工支持平台"},
                {value: '10.97', name: "海工支持船"},
                {value: '5.81', name: "起重船"},
                {value: '3.23', name: "铺缆船"},
                {value: '3.23', name: "钻井船"},
                {value: '2.58', name: "风电安装船"},
                {value: '0.65', name: "抛石船"},
                {value: '0.65', name: "风电运维船"},
                {value: '0.65', name: "钻井平台"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '22.92', name: "海工支持平台"},
                {value: '20.83', name: "钻井船"},
                {value: '19.44', name: "铺管船"},
                {value: '14.58', name: "海工支持船"},
                {value: '12.50', name: "钻井平台"},
                {value: '4.17', name: "勘探船"},
                {value: '2.08', name: "起重船"},
                {value: '1.39', name: "铺缆船"},
                {value: '1.39', name: "凤电安装船"},
                {value: '0.69', name: "液化天然气浮式生产储卸装置"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '上海振华重工(集团)股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '25.37', name: "海工支持平台"},
                {value: '16.42', name: "抛石船"},
                {value: '14.18', name: "钻井平台"},
                {value: '13.43', name: "风电安装船"},
                {value: '11.19', name: "起重船"},
                {value: '7.46', name: "海工支持船"},
                {value: '5.97', name: "钻井船"},
                {value: '2.24', name: "铺缆船"},
                {value: '0.75', name: "浮式生产储卸油装置"},
                {value: '0.75', name: "液化天然气浮式生产储卸装置"},
                {value: '0.75', name: "风电运维船"},
                {value: '0.75', name: "科考船"},
                {value: '0.75', name: "勘探船"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油集团有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '29.50', name: "浮式生产储卸油装置"},
                {value: '18.63', name: "铺管船"},
                {value: '16.77', name: "海工支持平台"},
                {value: '9.01', name: "钻井船"},
                {value: '8.39', name: "勘探船"},
                {value: '7.76', name: "海工支持船"},
                {value: '6.83', name: "钻井平台"},
                {value: '1.24', name: "起重船"},
                {value: '1.24', name: "铺缆船"},
                {value: '0.31', name: "风电运维船"},
                {value: '0.31', name: "风电安装船"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
      ],
      comTechBranchTemp: '',

      // 公司申请类型
      comAppType: '',
      comAppTypeData: [
        {
          grid: {
            left: '20%'
          },
          title: {
            text: '烟台中集来福士海洋工程有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              '浮式生产储卸油装置',
              '起重船',
              '铺管船',
              '铺缆船',
              '海工支持平台',
              '海工支持船',
              '风电安装船',
              '科考船',
              '勘探船',
              '钻并平台',
              '钻井船',
            ]
          },
          series: [
            {
              name: '发明申请',
              type: 'bar',
              data: [2, 2, 0, 1, 55, 70, 2, 2, 1, 21, 14, 8]
            },
            {
              name: '实用型',
              type: 'bar',
              data: [1, 0, 1, 0, 38, 7, 1, 1, 0, 21, 14, 8]
            }
          ]
        },
        {
          grid: {
            left: '20%'
          },
          title: {
            text: '海洋石油工程股份有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              '浮式生产储卸油装置',
              '铺管船',
              '起重船',
              '铺缆船',
              '拋石船',
              '海工支持平台',
              '海工支持船',
              '风电安装船',
              '凤电运维船',
              '钻井船',
              '钻井平台',
            ]
          },
          series: [
            {
              name: '发明申请',
              type: 'bar',
              data: [43, 63, 4, 4, 0, 28, 9, 3, 0, 1, 1]
            },
            {
              name: '实用型',
              type: 'bar',
              data: [32, 43, 5, 1, 1, 9, 8, 1, 1, 4, 0]
            }
          ]
        },
        {
          grid: {
            left: '20%'
          },
          title: {
            text: '中国海洋石油总公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              '浮式生产储卸油装置',
              '液化天然气浮式生产储卸装置',
              '铺管船',
              '起重船',
              '铺缆船',
              '海工支持平台',
              '海工支持船',
              '风电安装船',
              '勘探船',
              '钻井船',
              '钻井平台'
            ]
          },
          series: [
            {
              name: '发明申请',
              type: 'bar',
              data: [2, 2, 0, 1, 55, 70, 2, 2, 1, 21, 14, 8]
            },
            {
              name: '实用型',
              type: 'bar',
              data: [1, 0, 1, 0, 38, 7, 1, 1, 0, 21, 14, 8]
            }
          ]
        },
        {
          grid: {
            left: '20%'
          },
          title: {
            text: '上海振华重工(集团)股份有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              '浮式生产储卸油装置',
              '起重船',
              '铺管船',
              '铺缆船',
              '海工支持平台',
              '海工支持船',
              '风电安装船',
              '科考船',
              '勘探船',
              '钻并平台',
              '钻井船',
            ]
          },
          series: [
            {
              name: '发明申请',
              type: 'bar',
              data: [2, 2, 0, 1, 55, 70, 2, 2, 1, 21, 14, 8]
            },
            {
              name: '实用型',
              type: 'bar',
              data: [1, 0, 1, 0, 38, 7, 1, 1, 0, 21, 14, 8]
            }
          ]
        },
        {
          grid: {
            left: '20%'
          },
          title: {
            text: '中国石油集团有限公司'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              '浮式生产储卸油装置',
              '起重船',
              '铺管船',
              '铺缆船',
              '海工支持平台',
              '海工支持船',
              '风电安装船',
              '科考船',
              '勘探船',
              '钻并平台',
              '钻井船',
            ]
          },
          series: [
            {
              name: '发明申请',
              type: 'bar',
              data: [2, 2, 0, 1, 55, 70, 2, 2, 1, 21, 14, 8]
            },
            {
              name: '实用型',
              type: 'bar',
              data: [1, 0, 1, 0, 38, 7, 1, 1, 0, 21, 14, 8]
            }
          ]
        },
      ],
      comAppTypeTemp: '',

      // 公司技术构成
      techCompositionCom: '',
      techCompositionComData: [
        {
          title: {
            text: '烟台中集来福士海洋工程有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '65.68', name: "B63B"},
                {value: '20.12', name: "E02B"},
                {value: '2.96', name: "B66C"},
                {value: '2.96', name: "E21B"},
                {value: '1.78', name: "B63C"},
                {value: '1.78', name: "B63H"},
                {value: '1.78', name: "E02D"},
                {value: '1.18', name: "B63J"},
                {value: '1.18', name: "G01M"},
                {value: '0.59', name: "A01K"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '海洋石油工程股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '43.20', name: "B63B"},
                {value: '31.07', name: "F16L"},
                {value: '4.34', name: "B66C"},
                {value: '4.37', name: "B23K"},
                {value: '3.40', name: "E21B"},
                {value: '2.91', name: "E02B"},
                {value: '2.43', name: "B65D"},
                {value: '2.43', name: "B65H"},
                {value: '2.43', name: "G01M"},
                {value: '2.43', name: "H02G"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国海洋石油总公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '53.70', name: "B63B"},
                {value: '17.90', name: "E21B"},
                {value: '11.11', name: "F16L"},
                {value: '3.70', name: "G01M"},
                {value: '2.47', name: "F25J"},
                {value: '2.47', name: "G01L"},
                {value: '2.47', name: "G01S"},
                {value: '2.47', name: "G01V"},
                {value: '1.85', name: "B65D"},
                {value: '1.85', name: "B65G"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '上海振华重工(集团)股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '22.88', name: "B63B"},
                {value: '20.92', name: "E02B"},
                {value: '16.34', name: "E02D"},
                {value: '13.07', name: "B66C"},
                {value: '8.50', name: "B66D"},
                {value: '7.84', name: "F16L"},
                {value: '3.27', name: "B63H"},
                {value: '2.61', name: "B23K"},
                {value: '2.61', name: "B66F"},
                {value: '1.96', name: "F16H"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '中国石油集团有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '50.63', name: "B63B"},
                {value: '14.23', name: "F16L"},
                {value: '12.97', name: "E21B"},
                {value: '7.11', name: "GO1V"},
                {value: '3.35', name: "G01M"},
                {value: '2.93', name: "E02B"},
                {value: '2.51', name: "F25J"},
                {value: '2.51', name: "H02G"},
                {value: '2.09', name: "B65D"},
                {value: '1.67', name: "B63J"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
      ],
      techCompositionComTemp: '',
      /** 海洋工程船舶 **/

      /** 海洋防腐材料 **/
      // 专利技术分支
      patTechBranch: '',
      patTechBranchData: [
        {
          title: {
            text: '长沙协浩吉生物工程有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '90.29', name: "海洋化妆品-肤用类"},
                {value: '8.74', name: "海洋化妆品-特殊用途类"},
                {value: '0.97', name: "海洋农用制品-肥料类"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '海南京润珍珠生物技术股份有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '5.26', name: "海洋功能性食品-美容养颜类"},
                {value: '7.02', name: " 海洋功能性食品-其他"},
                {value: '3.51', name: "海洋功能性食品-增加骨密度类"},
                {value: '3.51', name: "海洋功能性食品-安神助眠类"},
                {value: '1.75', name: "海洋功能性食品-血脂谪节类"},
                {value: '61.40', name: "海洋化妆品-肤用类"},
                {value: '8.74', name: "海洋化妆品-特殊用途类"},
                {value: '0.97', name: "海洋化妆品-美容类"},
                {value: '1.25', name: "海洋化妆品-发用类"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '长沙瑞多康生物科技有限公司'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '100', name: "海洋农用制品-饲料及饵料类"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
        {
          title: {
            text: '广东海洋大学'
          },
          legend: {
            orient: 'vertical',
            left: '20',
            top: '40'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              label: {
                show: true,
                formatter: '{d}%'
              },
              data: [
                {value: '15.15', name: "海洋功能性食品-其他"},
                {value: '8.74', name: "海洋功能性食品-抗疲劳类"},
                {value: '5.26', name: "海洋功能性食品-美容养颜类"},
                {value: '7.02', name: " 海洋功能性食品-其他"},
                {value: '3.51', name: "海洋功能性食品-增加骨密度类"},
                {value: '3.51', name: "海洋功能性食品-安神助眠类"},
                {value: '1.75', name: "海洋功能性食品-血脂谪节类"},
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        },
      ],
      patTechBranchTemp: '',

      // 技术分类分布
      techClaDist: 'global',
      techClaDistData: {
        'global': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: [
              'A61K',
              'A23L',
              'A23K',
              'C05G',
              'A23F',
              'C12G',
              'C11D',
              'C07K',
              'C05F',
              'C09K',
              'A61L',
              'C12P',
              'A01N',
              'A23G',
              'C12N',
              'CO5D',
              'COGJ',
              'A23C',
              'A23J',
              '其他'
            ],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [4937, 1169, 1077, 437, 138, 105, 91, 57, 78, 75, 73, 69, 41, 36, 30, 22, 22, 19, 18, 156]
            }
          ]
        },
        'china': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: [
              'A61K',
              'A23L',
              'A23K',
              'C05G',
              'A23F',
              'C12G',
              'C11D',
              'C12P',
              'CO9K',
              '其他'
            ],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [4450, 1050, 962, 418, 138, 101, 85, 63, 58, 0]
            }
          ]
        },
        'guangdong': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: [
              'A61K',
              'A23L',
              'A23K',
              'C05G',
              'C07K',
              'C12P',
              'A23F',
              'A23G',
              'A61L',
              'C09K',
              'C11D',
              'C12N',
              'CO5F',
              'C12G',
              'A23J',
              'C08B',
              'A23C',
              'C02F',
              'C08J'
            ],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [735, 143, 56, 23, 10, 9, 7, 7, 7, 7, 6, 4, 3, 3, 2, 2, 1, 1, 1]
            }
          ]
        },
      },
      techClaDistTemp: '',

      // 技术分支分布
      techBranchDist: 'global',
      techBranchDistData: {
        'global': {
          'qb': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '33.66', name: "海洋功能性食品"},
                  {value: '3.98', name: "海洋医用材料"},
                  {value: '41.31', name: "海洋化妆品"},
                  {value: '21.06', name: "海洋农用制品"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'gnxsp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '1.78', name: "其他"},
                  {value: '11.65', name: "抗疲劳类"},
                  {value: '11.34', name: "保护肝类"},
                  {value: '10.18', name: "美容养颜类"},
                  {value: '9.81', name: "增加骨密度类"},
                  {value: '8.13', name: "免疫调节类"},
                  {value: '5.23', name: "安神助眠类"},
                  {value: '38.98', name: "血糖调节类"},
                  {value: '1.25', name: "血脂调节类"},
                  {value: '1.40', name: "抗衰老类"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'hzp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '4.73', name: "美容类"},
                  {value: '58.27', name: "肤用类"},
                  {value: '32.38', name: "特殊用途类"},
                  {value: '4.62', name: "发用类"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'yycl': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '53.47', name: "其他"},
                  {value: '41.33', name: "创伤愈合类"},
                  {value: '5.20', name: "止血类"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'nyzp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '3.17', name: "其他"},
                  {value: '25.94', name: "肥料类"},
                  {value: '70.89', name: "饲料及饵料类"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
        },
        'china': {
          'qb': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '34.25', name: "海洋功能性食品"},
                  {value: '1.82', name: "海洋医用材料"},
                  {value: '43.56', name: "海洋化妆品"},
                  {value: '20.37', name: "海洋农用制品"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'gnxsp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '38.24', name: "其他"},
                  {value: '12.93', name: "抗疲劳类"},
                  {value: '11.70', name: "保护肝类"},
                  {value: '11.09', name: "美容养颜类"},
                  {value: '8.45', name: "增加骨密度类"},
                  {value: '7.80', name: "免疫调节类"},
                  {value: '5.39', name: "安神助眠类"},
                  {value: '1.34', name: "血糖调节类"},
                  {value: '1.57', name: "血脂调节类"},
                  {value: '1.49', name: "抗衰老类"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'hzp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '4.57', name: "美容类"},
                  {value: '59.20', name: "肤用类"},
                  {value: '32.32', name: "特殊用途类"},
                  {value: '3.91', name: "发用类"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'yycl': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '20.86', name: "其他"},
                  {value: '69.78', name: "创伤愈合类"},
                  {value: '9.35', name: "止血类"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'nyzp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '3.73', name: "其他"},
                  {value: '22.32', name: "肥料类"},
                  {value: '73.95', name: "饲料及饵料类"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
        },
        'guangdong': {
          'qb': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '30.77', name: "海洋功能性食品"},
                  {value: '1.66', name: "海洋医用材料"},
                  {value: '58.13', name: "海洋化妆品"},
                  {value: '9.44', name: "海洋农用制品"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'gnxsp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '38.61', name: "其他"},
                  {value: '19.94', name: "抗疲劳类"},
                  {value: '9.18', name: "保肝护胃类"},
                  {value: '9.49', name: "美容养颜类"},
                  {value: '4.43', name: "增加骨密度类"},
                  {value: '7.91', name: "免疫调节类"},
                  {value: '3.80', name: "安神助眠类"},
                  {value: '0.32', name: "血糖调节类"},
                  {value: '2.22', name: "血脂调节类"},
                  {value: '4.11', name: "抗衰老类"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'hzp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '4.73', name: "美容类"},
                  {value: '58.27', name: "肤用类"},
                  {value: '31.38', name: "特殊用途类"},
                  {value: '4.62', name: "发用类"}
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'yycl': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '58.12', name: "护肤类"},
                  {value: '28.31', name: "特殊用途类"},
                  {value: '7.71', name: "美容类"},
                  {value: '5.86', name: "发用类"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
          'nyzp': {
            legend: {
              orient: 'vertical',
              left: '20',
              top: '40'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                label: {
                  show: true,
                  formatter: '{d}%'
                },
                data: [
                  {value: '3.09', name: "其他"},
                  {value: '18.56', name: "肥料类"},
                  {value: '78.35', name: "饲料及饵料类"},
                ],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          },
        },
      },
      techBranchDistTemp: '',
      techBranchDistSecTitle: 'qb',

      // 产业分布
      industDist: 'china',
      industDistData: {
        'china': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              'C26',
              'C27',
              'C13',
              'C14',
              'C15',
              'D46',
              'C42',
              'A01',
              'C40',
              'A04',
              'C30',
              'A01',
              'C34',
              'C35',
            ],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [3646, 1621, 969, 772, 596, 10, 7, 4, 3, 2, 2, 1, 1, 1]
            }
          ]
        },
        'guangdong': {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: [
              'C26',
              'C27',
              'C14',
              'C15',
              'C13',
              'D46'
            ],
          },
          series: [
            {
              name: '',
              type: 'bar',
              data: [617, 190, 102, 61, 56, 1]
            }
          ]
        },
      },
      industDistTemp: '',

      // 申请趋势
      applicTrend: 'global',
      applicTrendData: {
        "global": {
          'qb': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              }
            ]
          },
          'gnxsp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 20, 14, 14, 15, 39, 39, 48, 42, 82, 54, 99, 105, 100, 83, 117, 131, 184, 201, 329, 376, 266, 172, 136, 115, 2]
              },
            ]
          },
          'hzp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 7, 7, 8, 10, 21, 22, 19, 30, 12, 28, 23, 44, 43, 48, 103, 142, 198, 274, 374, 559, 666, 417, 254, 189, 10]
              },
            ]
          },
          'yycl': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 7, 7, 6, 6, 12, 1, 3, 2, 12, 5, 7, 5, 4, 6, 13, 4, 17, 9, 51, 41, 39, 40, 31, 9, 1]
              },
            ]
          },
          'nyzp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 7, 7, 6, 6, 16, 1, 3, 11, 14, 8, 4, 19, 14, 19, 44, 53, 109, 203, 252, 433, 317, 90, 55, 35, 1]
              },
            ]
          },
        },
        "china": {
          'qb': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              }
            ]
          },
          'gnxsp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
          'hzp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
          'yycl': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
          'nyzp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
        },
        "guangdong": {
          'qb': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              }
            ]
          },
          'gnxsp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
          'hzp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
          'yycl': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
          'nyzp': {
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: ['1914', '1970', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021']
            },
            yAxis: {
              type: 'value'
            },
            categories: [],
            series: [
              {
                name: '',
                type: 'line',
                stack: 'Total',
                data: [0, 0, 1, 1, 0, 1, 0, 0, 0, 0, 3, 2, 2, 6, 7, 77, 78, 85, 120, 95, 135, 145, 161, 110, 277, 330, 508, 687, 1006, 1409, 1288, 719, 476, 348, 14]
              },
            ]
          },
        },
      },
      applicTrendTemp: '',
      applicTrendSecTitle: 'qb',

      // 全球区域区域分布
      globalRegDist: '',
      globalRegDistData: {
        legend: {
          orient: 'vertical',
          left: '20',
          top: '40'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {
              show: true,
              formatter: '{d}%'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: [
              {value: '87.80', name: "中国"},
              {value: '3.63', name: "日本"},
              {value: '3.33', name: "韩国"},
              {value: '1.0.', name: "美国"},
              {value: '0.94', name: "世界知识产权组织"},
              {value: '0.53', name: " 欧洲专利局(EPO)"},
              {value: '0.40', name: "中国台湾"},
              {value: '0.29', name: "澳大利亚"},
              {value: '0.21', name: "俄罗斯"},
              {value: '0.20', name: "德国"},
              {value: '0.18', name: "加拿大"},
              {value: '0.16', name: "菲律宾"},
              {value: '0.14', name: "法国"},
              {value: '0.11', name: "英国"},
              {value: '1.03', name: "其他国家或地区"}
            ]
          }
        ]
      },
      globalRegDistTemp: '',

      // 专利申请人
      patApplic: 'global',
      patApplicData: {
        'global': {
          'qb': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                "mikimoto seiyaku kk",
                "postech academy industry foundation",
                "马天俊",
                "浙江海洋学院",
                "李尧",
                "广西还珠海洋生物科技有限公司",
                "中国科学院南海海洋研究所",
                "高鹏",
                "浙江海洋大学",
                "李光亮",
                "中国海洋大学",
                "青岛浩大海洋保健食品有限公司",
                "沈志荣",
                '徐内逊',
                "江阴市本特塞缪森生命科学研究院",
                '广东海洋大学',
                "长沙瑞多康生物科技有限公司",
                '海南京润珍珠生物技术股份有限公司',
                '长沙协浩吉生物工程有限公司',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [17, 18, 19, 19, 19, 19, 19, 20, 20, 21, 21, 23, 23, 25, 27, 33, 45, 57, 103]
              }
            ]
          },
          'gnxsp': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '余内逊',
                '青岛浩大海洋保健食品有限公言',
                '李光亮',
                '浙江海洋学院',
                '广州蓝钥匙海洋生物工程有限公司',
                '海海南京润珍珠生物技术股份有限公司',
                '广东海洋大学',
                '浙江海洋大学',
                '中国海洋大学',
                '中国科学院南海海洋研究所',
                '北京因科瑞斯医药科技有限公司',
                '广西百润源农业有限公司',
                '湖南今珠生物科技有限公司',
                '青岛金佳慧食品有限公司司',
                '唐山十三肽保生物工程技术有限公司',
                '威海御膳坊生物科技有限公司',
                '安徽一杯茶电子商务有限公司',
                '山东好当家海洋发展股份有限公司',
                '山东省科学院生物研究所',
                '张景压'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [25, 23, 21, 17, 14, 14, 13, 13, 10, 10, 10, 10, 10, 10, 9, 9, 9, 9, 9, 9]
              }
            ]
          },
          'hzp': {
            grid: {
              left: '39%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '长沙协浩吉生物工程有限公司',
                '海南京润珍珠生物技术股份有限公司',
                '江阴市本特塞缪森生命科学研究院有限公司',
                '沈志荣',
                '马天俊',
                '李尧',
                '青岛艾派克生物科技有限公司',
                '周丽',
                '无锡市汉方八珍科技有限公司',
                '欧诗漫生物股份有限公司',
                '苏州市洋海电子有限公司',
                '武汉鸿兴疆科技有限公司',
                'mikimoto pharmaceut co ltd',
                'mikimoto seiyaku kk',
                '南通蛇类治疗研究所',
                '广州赛莱拉干细胞科技股份有限公司',
                '陆文奎',
                '青岛克立克信息技术有限公司',
                '佛山科学技术学院',
                '广州科恩生物技术有限公司',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 43, 23, 23, 19, 18, 17, 16, 16, 16, 16, 15, 13, 13, 13, 13, 13, 13, 12, 12]
              }
            ]
          },
          'yycl': {
            grid: {
              left: '40%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                'bengt i samuelsson institute of life science',
                '江阴市本特塞缪森生命科学研究院有限公司',
                'postech foundation',
                'hyung joon cha',
                'purdue research foundation',
                'jiangyin bengt i samuelsson institute of life',
                'choi hyuk su',
                'posco',
                'bengt i samuelsson institute of life science',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 7, 9, 18, 28]
              }
            ]
          },
          'nyzp': {
            grid: {
              left: '30%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '长沙瑞多康生物科技有限公司',
                '高鹏',
                '安徽省华禾种业有限公司',
                '青岛崂乡茶制品有限公司',
                '青岛田瑞生态科技有限公司',
                '福建天马科技集团股份有限公司',
                'urabe sangyo kk',
                '全椒县天润生态养殖专业合作社',
                'wang youngsung',
                '马鞍山市安康菌业有限公司',
                '中国海洋大学',
                '广西正五海洋产业股份有限公司',
                '青岛嘉禾丰肥业有限公司',
                '广东海洋大学',
                '集美大学',
                '青岛农业大学',
                '青岛海之源智能技术有限公言',
                'univ samar state',
                '钦州学院',
                '青岛众泰禽业专业合作社'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [45, 2, 17, 16, 16, 15, 13, 13, 12, 12, 10, 10, 10, 9, 9, 9, 9, 8, 8, 8]
              }
            ]
          },
        },
        'china': {
          'qb': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                "mikimoto seiyaku kk",
                "postech academy industry foundation",
                "马天俊",
                "浙江海洋学院",
                "李尧",
                "广西还珠海洋生物科技有限公司",
                "中国科学院南海海洋研究所",
                "高鹏",
                "浙江海洋大学",
                "李光亮",
                "中国海洋大学",
                "青岛浩大海洋保健食品有限公司",
                "沈志荣",
                '徐内逊',
                "江阴市本特塞缪森生命科学研究院",
                '广东海洋大学',
                "长沙瑞多康生物科技有限公司",
                '海南京润珍珠生物技术股份有限公司',
                '长沙协浩吉生物工程有限公司',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [17, 18, 19, 19, 19, 19, 19, 20, 20, 21, 21, 23, 23, 25, 27, 33, 45, 57, 103]
              }
            ]
          },
          'gnxsp': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '余内逊',
                '青岛浩大海洋保健食品有限公言',
                '李光亮',
                '浙江海洋学院',
                '广州蓝钥匙海洋生物工程有限公司',
                '海海南京润珍珠生物技术股份有限公司',
                '广东海洋大学',
                '浙江海洋大学',
                '中国海洋大学',
                '中国科学院南海海洋研究所',
                '北京因科瑞斯医药科技有限公司',
                '广西百润源农业有限公司',
                '湖南今珠生物科技有限公司',
                '青岛金佳慧食品有限公司司',
                '唐山十三肽保生物工程技术有限公司',
                '威海御膳坊生物科技有限公司',
                '安徽一杯茶电子商务有限公司',
                '山东好当家海洋发展股份有限公司',
                '山东省科学院生物研究所',
                '张景压'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [25, 23, 21, 17, 14, 14, 13, 13, 10, 10, 10, 10, 10, 10, 9, 9, 9, 9, 9, 9]
              }
            ]
          },
          'hzp': {
            grid: {
              left: '39%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '长沙协浩吉生物工程有限公司',
                '海南京润珍珠生物技术股份有限公司',
                '江阴市本特塞缪森生命科学研究院有限公司',
                '沈志荣',
                '马天俊',
                '李尧',
                '青岛艾派克生物科技有限公司',
                '周丽',
                '无锡市汉方八珍科技有限公司',
                '欧诗漫生物股份有限公司',
                '苏州市洋海电子有限公司',
                '武汉鸿兴疆科技有限公司',
                'mikimoto pharmaceut co ltd',
                'mikimoto seiyaku kk',
                '南通蛇类治疗研究所',
                '广州赛莱拉干细胞科技股份有限公司',
                '陆文奎',
                '青岛克立克信息技术有限公司',
                '佛山科学技术学院',
                '广州科恩生物技术有限公司',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 43, 23, 23, 19, 18, 17, 16, 16, 16, 16, 15, 13, 13, 13, 13, 13, 13, 12, 12]
              }
            ]
          },
          'yycl': {
            grid: {
              left: '40%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                'bengt i samuelsson institute of life science',
                '江阴市本特塞缪森生命科学研究院有限公司',
                'postech foundation',
                'hyung joon cha',
                'purdue research foundation',
                'jiangyin bengt i samuelsson institute of life',
                'choi hyuk su',
                'posco',
                'bengt i samuelsson institute of life science',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 7, 9, 18, 28]
              }
            ]
          },
          'nyzp': {
            grid: {
              left: '30%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '长沙瑞多康生物科技有限公司',
                '高鹏',
                '安徽省华禾种业有限公司',
                '青岛崂乡茶制品有限公司',
                '青岛田瑞生态科技有限公司',
                '福建天马科技集团股份有限公司',
                'urabe sangyo kk',
                '全椒县天润生态养殖专业合作社',
                'wang youngsung',
                '马鞍山市安康菌业有限公司',
                '中国海洋大学',
                '广西正五海洋产业股份有限公司',
                '青岛嘉禾丰肥业有限公司',
                '广东海洋大学',
                '集美大学',
                '青岛农业大学',
                '青岛海之源智能技术有限公言',
                'univ samar state',
                '钦州学院',
                '青岛众泰禽业专业合作社'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [45, 2, 17, 16, 16, 15, 13, 13, 12, 12, 10, 10, 10, 9, 9, 9, 9, 8, 8, 8]
              }
            ]
          },
        },
        'guangdong': {
          'qb': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                "mikimoto seiyaku kk",
                "postech academy industry foundation",
                "马天俊",
                "浙江海洋学院",
                "李尧",
                "广西还珠海洋生物科技有限公司",
                "中国科学院南海海洋研究所",
                "高鹏",
                "浙江海洋大学",
                "李光亮",
                "中国海洋大学",
                "青岛浩大海洋保健食品有限公司",
                "沈志荣",
                '徐内逊',
                "江阴市本特塞缪森生命科学研究院",
                '广东海洋大学',
                "长沙瑞多康生物科技有限公司",
                '海南京润珍珠生物技术股份有限公司',
                '长沙协浩吉生物工程有限公司',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [17, 18, 19, 19, 19, 19, 19, 20, 20, 21, 21, 23, 23, 25, 27, 33, 45, 57, 103]
              }
            ]
          },
          'gnxsp': {
            grid: {
              left: '35%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '余内逊',
                '青岛浩大海洋保健食品有限公言',
                '李光亮',
                '浙江海洋学院',
                '广州蓝钥匙海洋生物工程有限公司',
                '海海南京润珍珠生物技术股份有限公司',
                '广东海洋大学',
                '浙江海洋大学',
                '中国海洋大学',
                '中国科学院南海海洋研究所',
                '北京因科瑞斯医药科技有限公司',
                '广西百润源农业有限公司',
                '湖南今珠生物科技有限公司',
                '青岛金佳慧食品有限公司司',
                '唐山十三肽保生物工程技术有限公司',
                '威海御膳坊生物科技有限公司',
                '安徽一杯茶电子商务有限公司',
                '山东好当家海洋发展股份有限公司',
                '山东省科学院生物研究所',
                '张景压'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [25, 23, 21, 17, 14, 14, 13, 13, 10, 10, 10, 10, 10, 10, 9, 9, 9, 9, 9, 9]
              }
            ]
          },
          'hzp': {
            grid: {
              left: '39%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '长沙协浩吉生物工程有限公司',
                '海南京润珍珠生物技术股份有限公司',
                '江阴市本特塞缪森生命科学研究院有限公司',
                '沈志荣',
                '马天俊',
                '李尧',
                '青岛艾派克生物科技有限公司',
                '周丽',
                '无锡市汉方八珍科技有限公司',
                '欧诗漫生物股份有限公司',
                '苏州市洋海电子有限公司',
                '武汉鸿兴疆科技有限公司',
                'mikimoto pharmaceut co ltd',
                'mikimoto seiyaku kk',
                '南通蛇类治疗研究所',
                '广州赛莱拉干细胞科技股份有限公司',
                '陆文奎',
                '青岛克立克信息技术有限公司',
                '佛山科学技术学院',
                '广州科恩生物技术有限公司',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 43, 23, 23, 19, 18, 17, 16, 16, 16, 16, 15, 13, 13, 13, 13, 13, 13, 12, 12]
              }
            ]
          },
          'yycl': {
            grid: {
              left: '40%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                'bengt i samuelsson institute of life science',
                '江阴市本特塞缪森生命科学研究院有限公司',
                'postech foundation',
                'hyung joon cha',
                'purdue research foundation',
                'jiangyin bengt i samuelsson institute of life',
                'choi hyuk su',
                'posco',
                'bengt i samuelsson institute of life science',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [3, 3, 3, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 6, 6, 7, 9, 18, 28]
              }
            ]
          },
          'nyzp': {
            grid: {
              left: '30%'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '长沙瑞多康生物科技有限公司',
                '高鹏',
                '安徽省华禾种业有限公司',
                '青岛崂乡茶制品有限公司',
                '青岛田瑞生态科技有限公司',
                '福建天马科技集团股份有限公司',
                'urabe sangyo kk',
                '全椒县天润生态养殖专业合作社',
                'wang youngsung',
                '马鞍山市安康菌业有限公司',
                '中国海洋大学',
                '广西正五海洋产业股份有限公司',
                '青岛嘉禾丰肥业有限公司',
                '广东海洋大学',
                '集美大学',
                '青岛农业大学',
                '青岛海之源智能技术有限公言',
                'univ samar state',
                '钦州学院',
                '青岛众泰禽业专业合作社'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [45, 2, 17, 16, 16, 15, 13, 13, 12, 12, 10, 10, 10, 9, 9, 9, 9, 8, 8, 8]
              }
            ]
          },
        }
      },
      patApplicTemp: '',
      patApplicSecTitle: 'qb',

      // 中国专利申请人类型
      cnPatApplic: '',
      cnPatApplicData: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: ['海洋功能性食品', '海洋化妆品', '海洋农用制品', '海洋医用材料']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['企业', '个人', '大专院校', '科研单位', '机关固体']
        },
        yAxis: {
          type: 'value',
          max: 'dataMax'
        },
        series: [
          {
            name: '海洋功能性食品',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [1400, 909, 239, 106, 43]
          },
          {
            name: '海洋化妆品',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [1760, 1334, 152, 68, 27]
          },
          {
            name: '海洋农用制品',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [943, 326, 126, 68, 101]
          },
          {
            name: '海洋医用材料',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [53, 530, 20, 6, 7]
          }
        ]
      },
      cnPatApplicTemp: '',

      // 发明人分布
      inventorDist: 'global',
      inventorDistData: {
        'global': {
          'qb': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '严超',
                '周树立',
                '苏金武',
                '张丽华',
                '曲田桂',
                '何丹',
                '余内逊',
                '姚国英',
                '高敏',
                '周丽'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 60, 45, 44, 40, 25, 25, 25, 25, 23]
              }
            ]
          },
          'gnxsp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '余内逊',
                '徐伦超',
                '李光亮',
                '丁国芳',
                '杨最素',
                '黄芳芳',
                '周树立',
                '王强',
                '王阳光',
                '余方苗'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [25, 23, 21, 18, 18, 17, 15, 15, 15, 12]
              }
            ]
          },
          'hzp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '严超',
                '周树立',
                '张丽华',
                '何丹',
                '姚国英',
                '沈伟良',
                '高敏',
                '周丽',
                '马天俊',
                '李尧'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 45, 42, 25, 25, 23, 22, 20, 19, 18]
              }
            ]
          },
          'yycl': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '纪欣',
                '刘泊志',
                '史学深',
                '林海生',
                '王庆彬',
                '秦小明',
                '章超桦',
                '陈建萍',
                '高敏',
                '何昊'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [4, 3, 3, 3, 3, 3, 3, 3, 3, 2]
              }
            ]
          },
          'nyzp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '苏金武',
                '曲田桂',
                '高鹏',
                '周承东',
                '李德琴',
                '程圆',
                '李洋',
                '王艳丽',
                '张蕉南',
                '张蕉霖',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [45, 40, 20, 18, 17, 17, 16, 16, 15, 15]
              }
            ]
          },
        },
        'china': {
          'qb': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '严超',
                '周树立',
                '苏金武',
                '张丽华',
                '曲田桂',
                '何丹',
                '余内逊',
                '姚国英',
                '高敏',
                '周丽'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 60, 45, 44, 40, 25, 25, 25, 25, 23]
              }
            ]
          },
          'gnxsp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '余内逊',
                '徐伦超',
                '李光亮',
                '丁国芳',
                '杨最素',
                '黄芳芳',
                '周树立',
                '王强',
                '王阳光',
                '余方苗'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [25, 23, 21, 18, 18, 17, 15, 15, 15, 12]
              }
            ]
          },
          'hzp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '严超',
                '周树立',
                '张丽华',
                '何丹',
                '姚国英',
                '沈伟良',
                '高敏',
                '周丽',
                '马天俊',
                '李尧'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 45, 42, 25, 25, 23, 22, 20, 19, 18]
              }
            ]
          },
          'yycl': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '纪欣',
                '刘泊志',
                '史学深',
                '林海生',
                '王庆彬',
                '秦小明',
                '章超桦',
                '陈建萍',
                '高敏',
                '何昊'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [4, 3, 3, 3, 3, 3, 3, 3, 3, 2]
              }
            ]
          },
          'nyzp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '苏金武',
                '曲田桂',
                '高鹏',
                '周承东',
                '李德琴',
                '程圆',
                '李洋',
                '王艳丽',
                '张蕉南',
                '张蕉霖',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [45, 40, 20, 18, 17, 17, 16, 16, 15, 15]
              }
            ]
          },
        },
        'guangdong': {
          'qb': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '严超',
                '周树立',
                '苏金武',
                '张丽华',
                '曲田桂',
                '何丹',
                '余内逊',
                '姚国英',
                '高敏',
                '周丽'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 60, 45, 44, 40, 25, 25, 25, 25, 23]
              }
            ]
          },
          'gnxsp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '余内逊',
                '徐伦超',
                '李光亮',
                '丁国芳',
                '杨最素',
                '黄芳芳',
                '周树立',
                '王强',
                '王阳光',
                '余方苗'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [25, 23, 21, 18, 18, 17, 15, 15, 15, 12]
              }
            ]
          },
          'hzp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '严超',
                '周树立',
                '张丽华',
                '何丹',
                '姚国英',
                '沈伟良',
                '高敏',
                '周丽',
                '马天俊',
                '李尧'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [102, 45, 42, 25, 25, 23, 22, 20, 19, 18]
              }
            ]
          },
          'yycl': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '纪欣',
                '刘泊志',
                '史学深',
                '林海生',
                '王庆彬',
                '秦小明',
                '章超桦',
                '陈建萍',
                '高敏',
                '何昊'
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [4, 3, 3, 3, 3, 3, 3, 3, 3, 2]
              }
            ]
          },
          'nyzp': {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            xAxis: {
              type: 'value',
              max: 'dataMax'
            },
            yAxis: {
              type: 'category',
              data: [
                '苏金武',
                '曲田桂',
                '高鹏',
                '周承东',
                '李德琴',
                '程圆',
                '李洋',
                '王艳丽',
                '张蕉南',
                '张蕉霖',
              ],
            },
            series: [
              {
                name: '',
                type: 'bar',
                data: [45, 40, 20, 18, 17, 17, 16, 16, 15, 15]
              }
            ]
          },
        }
      },
      inventorDistTemp: '',
      inventorDistSecTitle: 'qb',
      /** 海洋防腐材料 **/

      topValue: []
    }
  },
  created() {
    this.getData()
    this.getApplayData()
  },
  methods: {
    getValue(amount) {
      return (amount / 10000).toFixed(2) + " 万元"
    },

    // 获取申请趋势数据
    getApplayData(cate = "global") {
      let url = '/seatech/patentApply/year-summary'
      if (cate === "china") {
        url = '/seatech/patentApply/year-summary?country=中国'
      }
      if (cate === "america") {
        url = '/seatech/patentApply/year-summary?country=美国'
      }
      this.axios({
        method: 'get',
        url: 'https://api.tusparkgd.com' + url,
      }).then(resp => {
        let categories = []
        let data = []
        let ldata = resp.data
        if (ldata.success) {
          let result = ldata.result
          for (let i = 0; i < result.length; i++) {
            categories.push(result[i].year)
            data.push(result[i].quantity)
          }
          // _that.applyData[cate]['categories'] = categories
          // _that.applyData[cate]['data'] = data
          // _that.applyTemp = _that.applyData[cate]
          // _that.initApplyChart()

          this.applyData[cate].categories = categories
          this.applyData[cate].series[0].data = data
          console.log(categories)
          console.log(data)
        }
      })
    },

    // 获取其他数据
    getData() {
      // let _that = this
      this.axios({
        method: 'get',
        url: 'https://api.tusparkgd.com/seatech/patentCategory/list?pageSize=1000000',
      }).then(function (resp) {
        let ldata = resp.data
        var tempcate = ''
        if (ldata.success) {
          let result = ldata.result.records
          let jsonData = [], jsonTempdata = []
          tempcate = result[0].category
          for (let i = 0; i < result.length; i++) {
            if (result[i].category != tempcate) {
              jsonData[tempcate] = jsonTempdata
              jsonTempdata = []
              tempcate = result[i].category
            }
            jsonTempdata.push({value: result[i].quantity, name: result[i].title})
          }
          console.log(jsonData)
          //条状图数据
          // _that.areaData.global = jsonData['专利公开国别']
          // _that.areaData.province = jsonData['申请人省市']

          // _that.techData.global = jsonData['IPC全球']
          // _that.techData.china = jsonData['IPC中国']
          // _that.techData.guangdong = jsonData['IPC广东']

          // 饼图数据
          // _that.applicantData.organ.series = jsonData['申请人']
          // _that.applicantData.type.series = jsonData['申请人类型']
          // _that.applicantData.inventor.series = jsonData['发明人']

          // _that.productData.duixia = jsonData['国民经济行业分类']
          //
          // //temp
          // _that.areaTemp = _that.dataTransform('区域分布', _that.areaData['global'])
          //
          // // _that.techTemp = _that.techData['global]
          // _that.teachTemp = _that.dataTransform('专利数', _that.techData['global'])
          // // _that.initF2('areaf2', _that.techTemp)
          //
          // _that.applicantTemp  = _that.applicantData['type']
          // // _that.showPie('canvasApplicant')
          //
          // _that.productTemp = _that.productData['duixia']

          // _that.initPoductChart();
          // console.log(JSON.stringify(_that.applicantData))
        }
      })
    },

    switchData(i) {
      this.btnActive = i
    },

    switchPart(partten, data) {
      // 产业分类
      if (partten === 'product') {
        this.product = data
      }
      // 技术分布
      if (partten === 'tech') {
        this.tech = data
      }
      // 申请趋势
      if (partten === 'apply') {
        this.apply = data

        this.getApplayData(data)
      }
      // 区域分布
      if (partten === 'area') {
        this.area = data
      }
      // 申请人分布
      if (partten === 'applicant') {
        this.applicant = data
        this.applicantData.series[0].data = this.applicantTemp[data]
      }
      // 资源专利申请趋势
      if (partten === 'resPatentApp') {
        this.resPatentApp = data
      }
      if (partten === 'resPatentAppSecTitle') {
        this.resPatentAppSecTitle = data
      }
      // 资源专利技术分类
      if (partten === 'resPatentTech') {
        this.resPatentTech = data
      }
      if (partten === 'resPatentTechSecTitle') {
        this.resPatentTechSecTitle = data
      }
      // 资源专利主要发明人
      if (partten === 'resPatentIntor') {
        this.resPatentIntor = data
      }
      if (partten === 'resPatentIntorSecTitle') {
        this.resPatentIntorSecTitle = data
      }
      // 资源专利申请人分布
      if (partten === 'distOfResPatApp') {
        this.distOfResPatApp = data
      }
      if (partten === 'distOfResPatAppSecTitle') {
        this.distOfResPatAppSecTitle = data
      }
      // 公司专利有效性
      if (partten === 'comPatValidity') {
        this.comPatValidity = data
      }
      // 发明人
      if (partten === 'inventor') {
        this.inventor = data

        if (data === 'sb') {
          this.inventorSecTitle = 'fsscsb'
        } else {
          this.inventorSecTitle = 'bj'
        }
      }
      if (partten === 'inventorSecTitle') {
        this.inventorSecTitle = data
      }
      // 申请趋势
      if (partten === 'sqqs') {
        this.sqqs = data
      }
      if (partten === 'sqqsSecTitle') {
        this.sqqsSecTitle = data
      }
      // 公司申请趋势
      if (partten === 'comAppTrend') {
        this.comAppTrend = data
      }
      // 技术分布
      if (partten === 'techDist') {
        this.techDist = data
      }
      // 技术分类分布
      if (partten === 'techClaDist') {
        this.techClaDist = data
      }
      // 技术分支分布
      if (partten === 'techBranchDist') {
        this.techBranchDist = data
      }
      if (partten === 'techBranchDistSecTitle') {
        this.techBranchDistSecTitle = data
      }
      // 产业分布
      if (partten === 'industDist') {
        this.industDist = data
      }
      // 申请趋势
      if (partten === 'applicTrend') {
        this.applicTrend = data
      }
      if (partten === 'applicTrendSecTitle') {
        this.applicTrendSecTitle = data
      }
      // 专利申请人
      if (partten === 'patApplic') {
        this.patApplic = data
      }
      if (partten === 'patApplicSecTitle') {
        this.patApplicSecTitle = data
      }
      // 发明人分布
      if (partten === 'inventorDist') {
        this.inventorDist = data
      }
      if (partten === 'inventorDistSecTitle') {
        this.inventorDistSecTitle = data
      }
    }
  }
}
</script>

<style scoped>
page {
  background-color: #F8F8F8;
}

.patent-valuation-list {
  margin-top: 10px;
  padding: 20px;
}

.patent-content {
  margin-top: 20px;
}

.patent-content .list-item {
  padding: 15px 15px 10px 15px;
}

.patent-content .list-item:not(:last-child) {
  border-bottom: 1px solid #E6E6E6;
}

.patent-content .item-title {
  margin-bottom: 10px;
}

.patent-content .item-No {
  margin-bottom: 5px;
}

.patent-content .item-No label {
  margin-right: 32px;
}

.patent-content .item-val {
  color: #FF9800 !important;
}

.patent-content .item-val label {
  margin-right: 20px;
}


.switch-select {
  color: #4D8CEE;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 2px solid #4D8CEE;
}

.content-part {
  padding: 15px;
  margin-bottom: 10px;
}

.content-part .part-title {
  margin-bottom: 10px;
}

.content-part .part-switch {
  margin-bottom: 15px;
}

.content-part .switch-item {
  margin: 0 18px;
  cursor: pointer;
}

.btn {
  border: solid 2px #4D8CEE;
  padding: 7px;
  border-radius: 20px;
  font-weight: bold;
  color: #7d7d7d;
  background: #fff;
  margin: 0 8px;
  outline: 0;
}

.btn:nth-child(1) {
  margin-left: auto;
}

.active {
  background: #4D8CEE;
  color: #fff;
}

canvas {
  width: 100%;
  height: 200px;
}
</style>
