<template>
  <div class="content text-left flex-row justify-between">
    <div class="page-content bg-color-white">
      <div class="index-blank-padding flex-row justify-between align-center">
        <div class="patent-name font-18 font-color-1010" style="width: 86%;">
          {{ patentDetail.title }}
        </div>
      </div>

      <div class="model-value" v-show="patentDetail.tensionIndex">
        <div class="margin-bo-20">
          <div class="info-title font-color-1010 font-16 font-bold index-blank-padding">
            专利估值
            <span
              class="font-14 font-color-FF7"
              style="margin-right: 5px;">
              {{ getValue(patentDetail.minValue) }}  ~ </span>
            <span class="font-14 font-color-FF7 patent-price">
              {{ getValue(patentDetail.value) }}
            </span>
          </div>
          <div class="detail-content">
            <div class="charts-box" style="height: 220px;display: flex;justify-content: center;">
              <div id="canvasRadar" ref="canvasRadar" style="height: 220px; width: 500px;"></div>
            </div>
          </div>
          <div class="data-desc font-14 index-blank-padding">
            <div class="flex-row align-center model-item">
              <div>
                <div class="model-label">专利张力指数(C)</div>
                <div class="model-label-val">{{ valueFill(patentDetail.tensionIndex) }}</div>
              </div>
              <div>
                <div class="model-label">市场指数(Q)</div>
                <div class="model-label-val">{{ valueFill(patentDetail.outputValueIndex) }}</div>
              </div>
            </div>
            <div class="flex-row justify-between align-center model-item">
              <div>
                <div class="model-label">行业敏感指数(IS)</div>
                <div class="model-label-val">{{ valueFill(patentDetail.sensitiityIndex) }}</div>
              </div>
              <div>
                <div class="model-label">权利人指数(PI)</div>
                <div class="model-label-val">{{ valueFill(patentDetail.influnenceIndex) }}</div>
              </div>
            </div>
            <div class="flex-row justify-between align-center model-item">
              <div>
                <div class="model-label">保护指数(P)</div>
                <div class="model-label-val">{{ valueFill(patentDetail.legalValidityIndex) }}</div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div class="basic-info bg-color-white">
        <div class="info-title font-bold index-blank-padding font-color-1010 font-16">基础信息</div>
        <div class="basic-content font-14 index-blank-padding">
          <div class="info-content flex-row  justify-between align-center">
            <div class="patent-type info-item">
              <div class="label ">专利类型</div>
              <div class="patent-val label-val font-14">
                {{ valueFill(patentDetail.patentType) }}
              </div>
            </div>
            <div class="patent-date info-item ">
              <div class="label">申请日</div>
              <div class="patent-val label-val font-14">
                {{ valueFill(patentDetail.applicationDate) }}
              </div>
            </div>
          </div>
          <div class="info-content flex-row  justify-between align-center">
            <div class="patent-number info-item ">
              <div class="label">申请号</div>
              <div class="patent-val label-val font-14">
                {{ valueFill(patentDetail.applicationNo) }}
              </div>
            </div>
            <div class="patent-type info-item">
              <div class="label ">专利状态</div>
              <div class="patent-val label-val font-14">
                {{ valueFill(patentDetail.patentValidity) }}
              </div>
            </div>
          </div>
          <div class="info-content flex-row  justify-between align-center">
            <div class="patent-date info-item ">
              <div class="label ">公开日</div>
              <div class="patent-val label-val font-14">
                {{ valueFill(patentDetail.publicDate) }}
              </div>
            </div>
            <div class="patent-number info-item ">
              <div class="label">公开号</div>
              <div class="patent-val label-val font-14">
                {{ valueFill(patentDetail.publicNo) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="patent-info">
        <div class="info-title index-blank-padding font-bold font-color-1010 font-16">专利信息</div>
        <div class="ifno-desc index-blank-padding">
          <div class="patent-number font-14 flex-row patent-item ">
            <div class="label">法律状态</div>
            <div class="font-color-3737 patent-val label-val">
              {{ valueFill(patentDetail.legalStatus) }}
            </div>
          </div>
          <div class="patent-number font-14 flex-row patent-item">
            <div class="label">IPC主分类</div>
            <div class="font-color-3737 patent-val label-val">
              {{ valueFill(patentDetail.ipcMainCategory) }}
            </div>
          </div>
          <div class="patent-number font-14 flex-row patent-item">
            <div class="label">IPC</div>
            <div class="font-color-3737 patent-val label-val">
              {{ valueFill(patentDetail.ipc) }}
            </div>
          </div>
          <div v-show="patentDetail.nationalEconomicCategory" class="patent-number font-14 flex-row patent-item">
            <div class="label">国民经济分类</div>
            <div class="font-color-3737 patent-val label-val">
              {{ valueFill(patentDetail.nationalEconomicCategory) }}
            </div>
          </div>
          <div class="patent-number font-14 flex-row patent-item">
            <div class="label">申请人</div>
            <div class="font-color-3737 patent-val label-val">
              <!-- {{}} -->
              <span style="margin-right: 10px;text-decoration: underline;" v-for="(item, index) in applicant"
                    :key="index"> {{ item }}</span>
            </div>
          </div>
          <div class="patent-number font-14 flex-row patent-item">
            <div class="label">发明人</div>
            <div class="font-color-3737 patent-val label-val">
              {{ valueFill(patentDetail.inventor) }}
            </div>
          </div>
        </div>
      </div>

      <div class="patent_detail " v-if="patentDetail.summary">
        <div class="info-title font-color-1010 font-16 index-blank-padding font-bold">专利摘要</div>
        <div class="detail-content index-blank-padding  font-14 font-color-3737"
             style="padding-top: 9px;line-height: 20px;">
          {{ patentDetail.summary }}
        </div>
      </div>
    </div>
    <div class="recomend-content">
      <patent-hot/>
    </div>
  </div>
</template>

<script>

import * as echarts from 'echarts'

import PatentHot from "@/components/hot/PatentHot";

export default {
  name: 'PatentDetail',
  data() {
    return {
      cWidth: '',
      cHeight: '',
      pixelRatio: 1,
      patentId: '',
      patentDetail: {},
      valueModelData: [],
      applicant: [],
      chartData: {},
      isLike: false
    }
  },
  components: {
    PatentHot
  },
  created() {
    this.patentId = this.$route.params.id
    this.getPatentDetail()
    // this.getUserLike()
  },
  mounted() {
    this.initRadar()
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) {
        this.patentId = to.params.id // 获取参数 这里的 mainTpe 是我的路由参数
        this.getPatentDetail()
        this.valueModelData = []
      }
    }
  },
  methods: {
    initRadar() {
      // var chartDom = document.getElementById('canvasRadar')
      var myChart = echarts.init(this.$refs.canvasRadar)
      var option = {
        title: {
          // text: 'Basic Radar Chart'
        },
        legend: {
          // data: ['Allocated Budget', 'Actual Spending']
        },
        radar: {
          // shape: 'circle',
          indicator: [
            {name: '(C)', max: 1},
            {name: '(Q)', max: 1},
            {name: '(IS)', max: 1},
            {name: '(PI)', max: 1},
            {name: '(P)', max: 1},
          ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [

              {
                value: this.valueModelData,
              }
            ]
          }
        ]
      };
      myChart.setOption(option)
    },

    getPatentDetail() {
      var _that = this
      this.axios({
        method: 'get',
        url: 'https://api.tusparkgd.com/seatech/techPatent/queryById?id=' + _that.patentId,

      }).then(function (resp) {
        let ldata = resp.data
        if (ldata.success) {
          _that.patentDetail = ldata.result
          _that.applicant = ldata.result.applicant.split("; ")
          if (ldata.result.tensionIndex) {
            _that.valueModelData.push(ldata.result.tensionIndex)
          } else {
            _that.valueModelData.push(0)
          }
          if (ldata.result.outputValueIndex) {
            _that.valueModelData.push(ldata.result.outputValueIndex)
          } else {
            _that.valueModelData.push(0)
          }
          if (ldata.result.sensitiityIndex) {
            _that.valueModelData.push(ldata.result.sensitiityIndex)
          } else {
            _that.valueModelData.push(0)
          }
          if (ldata.result.influnenceIndex) {
            _that.valueModelData.push(ldata.result.influnenceIndex)
          } else {
            _that.valueModelData.push(0)
          }
          if (ldata.result.legalValidityIndex) {
            _that.valueModelData.push(ldata.result.legalValidityIndex)
          } else {
            _that.valueModelData.push(0)
          }

          _that.initRadar()
        }
      })
    },

    getValue(amount) {
      return (amount / 10000).toFixed(2) + " 万元"
    },

    valueFill(str) {
      if (str) {
        return str
      } else {
        return "-"
      }
    }
  }
}
</script>

<style scoped>
/* 请根据需求修改图表容器尺寸，如果父容器没有高度图表则会显示异常 */
.content {
  padding: 20px 10%;
}

.page-content {
  width: 68%;
}

.recomend-content {
  width: 30%;
}

.patent-name {
  padding: 15px 0;
}

.info-title { /*  */
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #F8F8F8;
}

.basic-info {
  padding-top: 20px;
  padding-bottom: 15px;
}

.basic-info .info-content {
  padding: 10px 0;
  border-bottom: 1px solid #F8F8F8;
}

.basic-info .info-content > div:last-child {
  border-left: 1px solid #F8F8F8;
  padding-left: 12px;
}

.basic-info .info-content .info-item {
  width: 50%;
}

.basic-info .info-content .label {
  color: #A0A1A0;
}

.basic-info .info-content .patent-val {
  padding-top: 10px;
  color: #373737;
}

.patent-info {
  color: #666;
  font-size: 14px;
  border-top: 8px solid #F8F8F8;
  padding-top: 15px;
  padding-bottom: 15px;
}

.patent-info .patent-item {
  padding: 10px 0;
}

.patent-info .patent-item .label {
  color: #A0A1A0;
  width: 30%;
}

.patent-info .patent-item .label-val {
  width: 70%;
}

.model-value .patent-price {
  margin-left: 10px;
}

.model-value .model-item {
  padding: 15px 0 10px;
}

.model-value .model-item > div {
  width: 50%;
}

.model-value .model-item:not(:last-child) {
  border-bottom: 1px solid #F8F8F8;
}

.model-value .model-item:not(:last-child) > div:last-child {
  border-left: 1px solid #F8F8F8;
  padding-left: 24px;
}

.model-value .model-label {
  color: #A0A1A0;
  font-size: 14px;
  padding-bottom: 12px;
}

.model-value .model-label-val {
  font-size: 14px;
  color: #373737;
}

.patent_detail, .model-value, .patent-info, .basic-info {
  border-top: 1px solid #F8F8F8;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
