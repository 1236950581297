<template>
    <div class="page-content detail text-left">
        <div class="basic-info index-blank-padding bg-color-white">
            <div class="detail-title page-title font-16 font-bold margin-bo-20">
                基础信息
            </div>
            <div class="basic-name font-14 basic-item flex-row">
                <div class="label">
                    名称
                </div>
                <div class="margin-left-30">{{caseDetail.name}}</div>
            </div>
            <div class="basic-desc font-14 text-justify ">
                <div class="label">简介</div>
                <div class="pre-wrap">{{caseDetail.summary}}</div>
            </div>
        </div>
        <div class="disease-content bg-color-white">
            <div class="content-item font-14">
                <div class="detail-title  font-bold font-color-1010">
                    病原
                </div>
                <div class="item-content pre-wrap font-color-3737">
                    {{caseDetail.pathogen}}
                </div>
            </div>
            <div class="content-item font-14">
                <div class="detail-title  font-bold margin-bo-20 font-color-1010">
                    危害对象
                </div>
                <div class="item-content pre-wrap font-color-3737">
                    {{caseDetail.victim}}
                </div>
            </div>
            <div class="content-item font-14">
                <div class="detail-title  font-bold font-color-1010">
                    主要症状
                </div>
                <div class="item-content text-justify pre-wrap font-color-3737">
                    {{caseDetail.symptons}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CaseDetail',
    data() {
        return {
            caseId: '',
            caseDetail: {}
        }
    },
    created() {
        this.caseId = this.$route.params.id
        this.getCaeDetail()
    },
    watch: {
        $route: function(to, from) {
            if (to.path !== from.path) {
                this.patentId = to.params.id
                this.getCaeDetail()
            }
        }
    },
    methods: {
        getCaeDetail(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/diseaseCase/queryById?id='+this.caseId,
                data: {
                    id: _that.caseId
                },
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.caseDetail = ldata.result
                }
            })
        },
    }
}
</script>

<style scoped>
page{
    background-color: #F8F8F8;
    height: 100%;
}
.page-content{
    margin: 20px 20%;
}
.basic-info{
    padding: 30px;
}
 .basic-name{
     border-bottom: 1px solid #F8F8F8;
     padding: 10px 0;
}

.basic-desc{
    padding: 10px 0;
}
.basic-desc .label{
    margin-bottom: 10px;
}
.detail-title, .item-content{
    margin-bottom: 15px;
}
.item-content{
    text-indent: 16px;
}
.disease-content{
    margin-top: 20px;
    padding: 30px;
}
.margin-left-30{
    margin-left: 30px;
}
</style>
