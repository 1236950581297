<template>
    <div class="hot-page text-left">
        <div class="page-content flex-column text-left">
            <div class="bg-color-white item flex-column" v-if="hotProduct.length > 0">
                <div class="flex-row  justify-between recommend-product index-blank-padding">
                    <div class="item-title font-16 font-bold">热门产品</div>
                </div>
                <div class="item-content index-blank-padding">
                    <div class="patent-list">
                        <template v-for="(item, index) in hotProduct">
                            <router-link  tag="a" target="_blank"  :key="index" :to="'/product/detail/'+item.id"  class="company-item flex-column item ">
                                <div class="font-14 company-title font-color-1010">{{item.name}}</div>
                                <div class="product-code font-14">
                                    <label class="font-color-3737">产品代码</label>
                                    <span class="font-color-666">{{item.code}}</span>
                                </div>
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductHot",
    data(){
        return {
            hotProduct: []
        }
    },
    created() {
        this.getProduct()
    },
    methods:{
        getProduct(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/product/list?pageNo=1&pageSize=5',
            }).then(function (resp) {
                let ldata = resp.data
                if (ldata.success) {
                    _that.hotProduct = ldata.result.records
                }
            })
        },
    }
}
</script>

<style scoped>
.page-content{

}
.page-content .item{
    margin-bottom: 8px;
    padding-top: 20px;
    padding-bottom: 15px;
}
.recommend-product{
    padding-bottom: 10px;
    border-bottom: 1px solid #F8F8F8;
}
.company-title{
    margin-bottom: 12px;
}
.company-item {
    padding: 12px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.company-item:not(:last-child){
     padding-bottom: 16px;
     border-bottom:  1px solid #E6E6E6;
 }
.product-code label{
    margin-right: 10%;
}
</style>