<template>
  <div class="index-page">
    <div class="page-header flex-column index-blank-padding">
      <!--            <div class="info-logo">-->
      <!--                <img class="logo" src="/static/logo.png" width="60" height="60" />-->
      <!--            </div>-->
      <div class="info flex-row align-center justify-center">
        <div class="info-title  font-color-white font-36 font-bold">
          海洋科技成果查询平台
        </div>
      </div>
      <div class="search-type">
        <div class="type-items  text-left font-color-white">
          <span class="type-item" :class="searchType == 'patent'? 'select' : ''" @click="switchType('patent')"><span>专利技术</span></span>
          <!-- <span class="type-item" :class="searchType == 'product'? 'select' : ''" @click="switchType('product')"><span>产品</span></span> -->
          <span class="type-item" :class="searchType == 'project'? 'select' : ''" @click="switchType('project')"><span>项目</span></span>
          <span class="type-item" :class="searchType == 'company'? 'select' : ''" @click="switchType('company')"><span>企业</span></span>
        </div>
      </div>
      <div class="search  flex-row">
        <!--                <input class="search-input">-->
        <input class="font-14 bg-color-white search-input" v-model="keywords"
               placeholder-class="font-13 font-color-B5B7" @keyup.enter="Search" placeholder="输入病例名称、技术类型等"/>
        <div class="search-btn cur-pointer" @click="Search">搜一下</div>
        <!--                <router-link tag="a" target="_blank" class="search-btn cur-pointer" :to="'/search/'+earchType+'/'+keywords">搜一下</router-link>-->
      </div>
      <div class="hot-search font-color-white font-14 flex-row align-center">
        <div class="font-bold font-14">热搜
          <text style="margin-left: 20px;">|</text>
        </div>
        <div class="search-tag flex-row font-color-white">
          <router-link tag="a" target="_blank" to="/search/patent/白斑综合症" class="tag-item cur-pointer font-color-white ">
            白斑综合症
          </router-link>
          <router-link tag="a" target="_blank" to="/search/patent/疫苗" class="tag-item cur-pointer font-color-white">疫苗
          </router-link>
          <router-link tag="a" target="_blank" to="/search/patent/对虾" class="tag-item cur-pointer font-color-white">对虾
          </router-link>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="page-content-content flex-row justify-between">
        <div class="data-sys-module">
          <data-sys/>
        </div>

        <div class="index-rank" :class="topGap" ref="rankScroll" v-if="topValue.length>0">
          <div class="patent-valuation-list rank-item bg-color-white">
            <div class="flex-row justify-between align-end font-bold">
              <!-- <div class="rank-title">专利估值展示</div> -->
              <div class="rank-title">海洋相关领域专利估值分析</div>
              <router-link tag="a" target="_blank" to="/rank/list">
                <div class="get-more font-12 font-color-318">查看更多</div>
              </router-link>
            </div>

            <div class="list-content">
              <div class="content-item flex-column">

                <swiper class="swiper" :options="swiperOption">
                  <swiper-slide v-for="(item, index) in topValue" :key="index">

                    <template v-for="(patentInfo, index) in item">
                      <div class="border-bottom-EB" :key="index">
                        <router-link tag="a" target="_blank" :to="'/patent/detail/'+patentInfo.id">
                          <patent-item :patentInfo="patentInfo"/>
                        </router-link>
                      </div>
                    </template>

                  </swiper-slide>

                  <!-- 分页器 -->
                  <div class="swiper-pagination" slot="pagination"/>
                </swiper>

              </div>
            </div>

          </div>

          <!-- <patent-hot />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatentItem from "@/components/item-components/PatentItem";
// import PatentDate from "@/components/item-components/PatentDate";
import DataSys from "@/components/DataSys";

export default {
  name: "Index",
  components: {
    PatentItem,
    // PatentDate,
    DataSys
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 6000
        },
        loop: true
      },

      keywords: '',
      topValue: [],
      topGap: 'topNormal',
      searchType: 'patent',
    }
  },
  created() {
    this.getRankValue(1, 50)
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 监听页面
    handleScroll() {
      //获取滚动时的高度
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // let oneHeight = this.$refs.rankScroll.offsetTop;
      // console.log(oneHeight)
      //

      // if(scrollTop )
      //
      // // console.log(scrollTop)
      // if (scrollTop > 0) {
      //     //滚动大于0的时候要做的操作
      // }
      // if (scrollTop > 200) {
      //     //大于200的时候要做的操作
      // }
      // if (scrollTop > oneHeight) {
      //     //这是滑动到scrollTwo的距离要做的操作
      // }

    },

    // 切割数组
    _chunk(arr, num) {
      let j = 0, o = j
      let newArray = []
      while (j < arr.length) {
        j += num
        newArray.push(arr.slice(o, j))
        o = j
      }
      return newArray
    },

    getRankValue(pageNo = 1, pageSize = 10) {
      var _that = this
      this.axios({
        method: 'get',
        url: `https://api.tusparkgd.com/seatech/techPatent/list?pageNo=${pageNo}&pageSize=${pageSize}&column=value&order=desc`
      }).then(resp => {
        let ldata = resp.data
        if (ldata.success) {
          _that.topValue = this._chunk(ldata.result.records, 10)
          // this.topValue.splice(pageNo - 1, 0, ldata.result.records)
          // _that.topValue = _that.topValue.concat(...ldata.result.records)
        }
        console.log(this.topValue)
      })
    },

    Search() {
      // this.$router.push('/search/'+this.searchType+'/'+this.keywords)
      var _that = this
      let routeData = this.$router.resolve({
        path: '/search/' + _that.searchType + '/' + this.keywords,
      });
      window.open(routeData.href, '_blank');
    },

    switchType(type) {
      this.searchType = type
    }
  }
}
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  bottom: 0;
  position: unset;
}

.page-header {
  padding: 80px 10% 40px;
  background-color: #4D8CEE;
  background-image: url('/static/png/bg-map.png');
  background-size: 65% 80%;
  background-position: 45% 30%;
  background-repeat: no-repeat;
}

.search {
  margin: 20px 10% 40px;
  border-radius: 10px;
}

.search .search-input {
  padding: 15px 24px;
  width: 90%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid #FFFFFF !important;
  outline: none;
}

.search .search-btn {
  width: 20%;
  line-height: 50px;
  background-color: rgb(252, 125, 44);
  color: #FFFFFF;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.hot-search {
  margin: 0 10%;
}

.hot-search .tag-item {
  margin-left: 20px;
}

.page-content {
  margin: 10px 10%;
}

.page-content-content {
  margin-top: 20px;
}

.data-sys-module {
  width: 68%;
}

.index-rank {
  width: 30%;
  overflow: hidden;
}

.topTwenty {
  top: 30px;
  width: 26%;
  position: fixed;
  right: 7%;
  top: 20px
}

.index-rank .rank-item {
  padding: 16px 20px 16px;
}

.index-rank .patent-valuation-list {
  /*margin-right: 20%;*/
}

.search-type {
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 10%;
}

.search-type .type-items .type-item {
  padding: 8px 26px;
  width: 80px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  position: relative;
}

.search-type .type-items .select {
  background-color: #FFFFFF;
  position: relative;
  color: #4D8CEE;
}

.search-type .type-items .select:after {
  position: absolute;
  left: 40%;
  top: 90%;
  content: '';
  width: 0;
  height: 0;
  margin-top: 2px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}

.search-type .type-items .type-item:first-child {
  padding: 8px 10px;
}

.search-type .type-items .type-item:hover {
  color: #4D8CEE;
  background-color: #FFFFFF;
}

.search-type .type-items .type-item:hover:after {
  position: absolute;
  left: 40%;
  top: 90%;
  content: '';
  width: 0;
  height: 0;
  margin-top: 2px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fff;
}

/*.setColor-bg{*/
/*    background-color: #4D8CEE;*/
/*    color: #FFF !important;*/
/*}*/
/*.setColor-bg .header-index-bar .flex-row .company-title{*/
/*    color: #ffffff;*/
/*}*/
</style>
