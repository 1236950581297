<template>
    <div class="conten index-detail">
        <div class="page-content index-blank-padding bg-color-white">
            <div class="chart">
                <!-- <canvas canvas-id="canvasProduct" id="canvasColumn" class="charts"></canvas> -->
<!--                <qiun-data-charts type="pie" :chartData="productData['duixia']" />-->
                <div id="duixia" style="width: 100%;height: 222px"></div>
            </div>
            <div class="content-desc font-14 text-justify">
                <div>根据国民经济行业分类，本报告将对虾病害诊断及防治划分为药物及诊断（属于国民经济行业分类中的C27类）、养殖方法（属于国民经经济行业分类中的C13类）、饲料及添加剂（属于国民经经济行业分类中的A04类）以及其他（不便于划分的行业）。</div>
                <div>专利申请分布情况侧重在药物及诊断领域，专利申请数量为370件，其中药物相关的专利申请为224件，占该领域的比重为60.54%。</div>
                <!-- <canvas canvas-id="canvasProduct2" id="canvasColumn2" class="charts"></canvas> -->
                <div id="duixia2" style="width: 100%;height: 222px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import  * as echarts from 'echarts'
export default {
    name: 'ProductIndexDetail',
    data() {
        return {
            cWidth:'',
            cHeight:'',
            pixelRatio:1,
            productData: {
                "duixia": [
                    {value: '142', name: "养殖方法"},
                    {value: '246', name: "饲料"},
                    {value: '31', name: "其他"}
                ],
                "duixia2": [{"name": "药物","value": 224}, {"name": "诊断试剂及方法","value": 146}]
            },
        }
    },
    mounted() {
        this.initPoductChart()
        this.initDuixiangChart()
    },
    methods: {
        initPoductChart(){
            // var _that = this
            var duixia1 = this.productData['duixia']
            var chartDom = document.getElementById('duixia')
            var myChart = echarts.init(chartDom)
            var options= {
                title: {
                },
                tooltip: {
                    // trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: '20',
                    top: '40'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        label: {
                            show: true,
                            formatter: '{d}%'
                        },
                        data: duixia1,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            myChart.setOption(options)
        },

        initDuixiangChart(){
            // var _that = this
            var duixia2 = this.productData['duixia2']
            var chartDom = document.getElementById('duixia2')
            var myChart = echarts.init(chartDom)
            var options= {
                title: {
                },
                tooltip: {
                    // trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: '20',
                    top: '40'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        label: {
                            show: true,
                            formatter: '{d}%'
                        },
                        data: duixia2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            myChart.setOption(options)
        },

    }
}
</script>

<style  scoped>
.index-detail{
    padding: 20px 20%;
}
</style>
