<template>
    <div class="content">
        <div class="page-content">
            <div class="content-header ">
<!--                <div class="setBg"></div>-->
<!--                <div class="setBg2"></div>-->
                <div class="rank-title font-36 font-color-white font-bold">海洋专利价值排行榜</div>
<!--                <div class="font-14 flex-row justify-between align-end header-bottom">-->
<!--                    <div class="font-color-white">科学维度全力打造</div>-->
<!--                    <div class="push-btn">重磅发布</div>-->
<!--                </div>-->
            </div>
            <div class="list-desc page-setting bg-color-white">
                <div class="item-title font-18 font-color-3737 font-bold">榜单介绍</div>
                <div class="desc-content font-14 text-justify">本研究运用专利价值评估“产业法”对涉及海水对虾养殖疾病诊断和防治的160件中国授权发明专利进行了估值。该方法是已经使用多年，累计估值专利数量超过11万余件，其数学模型是根据专利技术所运用的行业总产值，产业专利敏感系数、专利价值回报期望系数、专利所属产品有效专利数、专利持有人影响参数，以及该专利性的价值度等参数和因素进行计算和评估专利价值。</div>
            </div>
            <div class="rank-list page-setting bg-color-white">
                <div class="item-title font-18 font-color-3737 font-bold">专利价值排行榜</div>
                <div class="list-content  text-left flex-row flex-wrap justify-between">
                    <template v-for="(item, index) in topValue">
                        <router-link tag="a" target="_blank" :to="'/patent/detail/'+item.id"  class="list-item" :key="index">
                            <div class="">
                                <div class="item-title font-14 font-color-1010 margin-bottom-12">{{item.title}}</div>
                                <div class="apply-num font-12 ">
                                    <label class="font-color-1010">申请号 </label><span class="font-color-666">{{item.applicationNo}}</span>
                                </div>
                                <div class="apply-value font-12 ">
                                    <label >估值价 </label> <span class="font-color-FF9" v-if="item.minValue" style="margin-right: 5px;" >{{getValue(item.minValue)}}  ~ </span> <span class="font-color-FF9">{{getValue(item.value)}}</span>
                                </div>
                            </div>
                        </router-link>
                    </template>
                </div>
                <div class="get-more">
                    <div class="getMore cur-pointer" @click="getMoreList"> 加载更多 </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RankList',
    data() {
        return {
            topValue: [],
            pageNo: 1,
        };
    },
    created() {
        this.getRankValue()
    },
    mounted() {
        // window.addEventListener("scroll",this.handleScroll);
    },
    methods:{
        // 监听页面,加载更多
        handleScroll() {
            //获取滚动时的高度
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            // 获取滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight;

            // 视频窗口高度
            var windowHeight = document.documentElement.clientHeight

            if((scrollTop + windowHeight + 200) >scrollHeight ){
                this.pageNo = this.pageNo + 1
                this.getRankValue()
            }

        },

        // 加载更多
        getMoreList(){
            this.pageNo = this.pageNo + 1
            this.getRankValue()
        },

        getValue(amount){
            return (amount / 10000).toFixed(2) + " 万元"
        },
        getRankValue(){
            var _that = this
            let updateUrl = 'https://api.tusparkgd.com/seatech/techPatent/list?pageNo='+this.pageNo+'&pageSize=15&column=value&order=desc'
            this.axios({
                method: 'get',
                url: updateUrl,
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.topValue =   _that.topValue.concat(ldata.result.records)
                }
            })
        }
    }
}
</script>

<style scoped>
    .content-header{
        padding: 20px 20% !important;
        background-color: #EE4649;
        /*opacity: .7;*/
        /**/
        height: 320px;
        position: relative;
        background-image: url('/static/png/bg-map.png');
        background-size: 65% 80%;
        background-position: 45% 30%;
        background-repeat: no-repeat;
    }

    .content-header .rank-title{
        line-height: 240px;
    }

    .list-desc{
        padding: 20px 2% !important;

    }
    .list-desc .desc-content{
        margin-top: 20px;
        line-height: 30px;
    }
    .rank-list{
        padding: 20px 2% ;
        border-top: 12px solid #F8F8F8;
        border-bottom: 12px solid #F8F8F8;
        border-bottom: 1px solid #F8F8F8;
    }

    .rank-list .list-item{
        padding: 20px;
        border-radius: 6px;
        width: 25%;
        margin-top: 30px;
        display: block;
        border: 1px solid #EBEBEB;
    }
    .rank-list .list-item:hover{
        box-shadow: 0 5px 10px #EBEBEB;
    }
    .list-item .list-nums .nums{
        height: 30px;
        width: 30px;
        margin-right: 10px;
        margin-top: 8px;
    }
    .list-item  .item-title{
        margin-bottom: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
    }
    .list-item .apply-value label, .apply-num label{
        margin-right: 32px ;
    }
    .list-item .apply-num{
        margin-bottom: 10px;
    }
    .page-setting{
        margin: 20px 10% ;
    }
    .get-more{
        padding: 40px;
    }
    .getMore{
        background-color: #EFEFEF;
        font-size: 14px;
        padding:10px 100px;
        cursor: pointer;
        border-radius: 6px;
        display: inline;
    }
</style>
