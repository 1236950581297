<template>
    <div class="conten index-detail">
        <div class="page-content bg-color-white">
            <div class="chart">
                <div id="applyCharts" style="height: 400px; width: 100%"></div>
            </div>
            <div class="content-desc font-14 index-blank-padding text-justify">
                <div class="title font-16 font-bold">指标介绍</div>
                <div >
                    <p>本次分析样本对象为与对虾病害诊断及防治相关的784件申请，图2-1为对虾病害诊断及防治全球专利申请趋势。总体上，从1989年出现第一件专利申请至今，对虾病害诊断及防治专利的申请趋势分为三个阶段：<br /><br /><strong>（1）第一阶段：技术萌芽期</strong><br /><br />第一阶段为1989年-1999年，对虾病害诊断及防治领域仅有零星的专利申请，每年的专利申请数量在10件以下，申请国家主要以中国为主，美国有少量申请。<br /><br /><strong>（2）第二阶段：技术缓慢发展期</strong><br /><br />第二阶段为2000年-2009年，这一阶段，专利平均年申请量超过10件，2008年和2009年专利申请量超过20件。第二阶段的申请国家还是以中国为主，但是美国、印度、韩国、墨西哥、日本已经开始有少量的专利申请，但是专利申请量整体呈现缓慢上升趋势。<br /><br /><strong>（3）第三阶段：技术快速发展期</strong><br /><br />第三阶段为2010年-2017年，该阶段专利申请量从20件左右快速增长到100件左右，其中，在2015年出现申请最高点，全球申请量为111件。专利申请的快速增长体现了经过近二十年的发展，水产养殖业已经更加规模化、多样化，养殖技术也更加先进。其中，2015年中国专利申请数量为107件。可见，在这个阶段，中国依然是主要的对虾病害诊断及防治专利申请国。<br /><br /><br style="font-family: 楷体; mso-ascii-font-family: 'Times New Roman'; mso-hansi-font-family: 'Times New Roman';" /></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
export default {
    name: 'ApplyTrendDetail',
    data() {
        return {
            applyContent: '',
            applyData: {
                "global" : {
                    categories:[],
                    series:[{
                        "name": "成交量2",
                        "data": [],
                    }],
                },
                "america": {
                    categories:[],
                    series:[{
                        "name": "成交量2",
                        "data": [],
                    }],
                },
                "china": {
                    categories:[],
                    series:[{
                        "name": "成交量2",
                        "data": [],
                    }],
                }
            },
            applyTemp: {},
            type: ''
        }
    },
    created() {
        this.type = this.$route.params.type
        this.getApplayData(this.type)
        console.log(this.$route.params.type)
    },
    mounted() {
        this.initApplyChart()
    },
    methods: {

        //申请趋势
        initApplyChart(){
            var chartDom = document.getElementById('applyCharts')
            var myChart = echarts.init(chartDom)
            var option = {
                xAxis: {
                    type: 'category',
                    data: this.applyTemp.categories
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: this.applyTemp.data,
                        type: 'line'
                    }
                ]
            };
            myChart.setOption(option)
        },


        // 获取申请趋势数据
        getApplayData(cate="global"){
            let _that = this
            let url = '/seatech/patentApply/year-summary'
            if(cate =="china"){
                url = '/seatech/patentApply/year-summary?country=中国'
            }
            if(cate =="america"){
                url = '/seatech/patentApply/year-summary?country=美国'
            }
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com'+url,
            }).then(function (resp) {
                let categories = []
                let data = []
                let ldata = resp.data
                if (ldata.success){
                    let result = ldata.result
                    for (let i = 0; i < result.length; i++) {
                        categories.push(result[i].year)
                        data.push(result[i].quantity)
                    }
                    _that.applyData[cate]['categories'] = categories
                    _that.applyData[cate]['data'] = data
                    _that.applyTemp = _that.applyData[cate]
                    _that.initApplyChart()
                }
            })
        },

    }
}
</script>

<style scoped>
.index-detail{
    padding: 20px 10%;
}
.content-desc{
    margin-top: 15px;
    border-top: 10px solid #F8F8F8;
    margin-bottom: 20px;
}
.title{
    margin: 15px 0;
}
</style>
