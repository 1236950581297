<template>
  <div class="component-content">
    <template v-if="patentInfo">
      <div class="list-item text-left">
        <div class="item-title font-14">{{ patentInfo.title }}</div>
        <div class="item-No font-14">
          <label class="font-color-1010">申请号 </label><span class="font-color-666">{{ patentInfo.applicationNo }}</span>
        </div>
        <div class="item-val font-14">
          <label>估值价 </label> <span class="font-color-4D8" v-if="patentInfo.minValue"
                                    style="margin-right: 10px;">{{ getValue(patentInfo.minValue) }}  ~ </span> <span
          class="font-color-4D8"> {{ getValue(patentInfo.value) }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PatentItem",
  data() {
    return {

    };
  },
  props: {
    patentInfo: {
      type: Object,
    }
  },
  created() {
  },
  methods: {
    getValue(amount) {
      return (amount / 10000).toFixed(2) + " 万元"
    },
  }
}
</script>

<style scoped>
.list-item {
  padding: 10px 0 4px;
}

.item-title {
  margin-bottom: 16px;
}

.item-No {
  margin-bottom: 10px;
}

.item-No label {
  margin-right: 32px;
}

.item-val {
  margin-bottom: 8px;
  /*color: #FF9800 !important;*/
  color: #FF9800 !important;
}

.item-val label {
  margin-right: 32px;
  color: #373737;
}
</style>
