<template>
  <div class="goTop" v-show="goTopShow" @click="goTop">
    <i class="goTopIcon">
      UP
    </i>
  </div>
</template>

<script>
const debounce = function debounce(fn, wait = 200) {
  let timer = null
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
      timer = null
    }, wait)
  }
};

export default {
  name: "BackToTop",
  data() {
    return {
      scrollTop: '',
      goTopShow: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll: debounce(function () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 360) this.goTopShow = true
    }),
    goTop() {
      let timer = null, _this = this

      cancelAnimationFrame(timer)

      timer = requestAnimationFrame(function fn() {
        if (_this.scrollTop > 0) {
          _this.scrollTop -= 100
          document.body.scrollTop = document.documentElement.scrollTop = _this.scrollTop
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
          _this.goTopShow = false
        }
      })
    },
  },
  watch: {
    scrollTop() {
      this.goTopShow = this.scrollTop > 360
    }
  }
}
</script>

<style scoped>
.goTop {
  position: fixed;
  right: 40px;
  bottom: 60px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: #fff;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
}

.goTop:hover .goTopIcon {
  color: rgb(36, 146, 255);
}

.goTopIcon {
  font-size: 20px;
  color: rgba(51, 153, 255, 0.8);
}
</style>
