<template>
    <div class="search-page">
        <div class="search-header flex-column index-blank-padding">
            <div  class="search bg-color-white flex-row">
                <input  class="font-14 search-input"  v-model="keywords" placeholder-class="font-13 font-color-B5B7" @keyup.enter="resetSearch" placeholder="输入病例名称、技术类型、研究专家等"  />
                <div class="search-btn cur-pointer" @click="resetSearch" >搜一下</div>
            </div>
        </div>
        <div class="type-items flex-row font-bold">
            <div class="type-item" :class="searchType == 'patent'? 'select' : ''" @click="switchType('patent')">专利技术</div> <div class="divide-line"></div>
            <div class="type-item" :class="searchType == 'product'? 'select' : ''" @click="switchType('product')">产品</div><div class="divide-line"></div>
            <div class="type-item" :class="searchType == 'project'? 'select' : ''" @click="switchType('project')">项目</div><div class="divide-line"></div>
            <div class="type-item" :class="searchType == 'company'? 'select' : ''" @click="switchType('company')">企业</div>
        </div>
        <div class="search-content text-center flex-row justify-between">
            <div class="results-content">
                <div class="search-results">
                    <div class="results-list text-left">
                        <template v-for="(item, index) in searchResult">
                            <router-link tag="a" target="_blank" :to="'/company/detail/'+item.id"   v-if="searchType=='company'" class="company-item flex-column item" :key="index">
                                <div class="font-14  company-title">{{item.companyName}}</div>
                                <div class="company-info font-12 flex-row align-center justify-between">
                                    <div class="legalPerson info-item">
                                        <div class="info-label font-color-A0A1">法定代表人</div>
                                        <div class="info-val font-color-3737">{{item.representative}}</div>
                                    </div>
                                    <div class="info-item registeredCapital">
                                        <div class="info-label font-color-A0A1">注册资本</div>
                                        <div class="info-val font-color-3737">{{item.capital}}</div>
                                    </div>
                                    <div class="foundData info-item">
                                        <div class="info-label font-color-A0A1">成立日期</div>
                                        <view class="info-val font-color-3737">{{item.established ? item.established: '-'}}</view>
                                    </div>
                                </div>
                            </router-link >
                            <router-link tag="a" target="_blank" :to="'/project/detail/'+item.id"   v-if="searchType=='project'" class="project-item flex-column item" :key="index">
                                <div class="font-14  project-title">{{item.name}}</div>
                                <div class="project-info font-12 flex-row align-center justify-between">
                                    <div class="financeStatus info-item">
                                        <div class="info-label font-color-A0A1">融资状态</div>
                                        <div class="info-val font-color-3737">{{item.financingRound_dictText ? item.financingRound_dictText : '-'}}</div>
                                    </div>
                                    <div class="info-item demandAmount">
                                        <div class="info-label font-color-A0A1">需求金额</div>
                                        <div class="info-val font-color-3737">{{item.requestAmount}}</div>
                                    </div>
                                    <div class="onlineData info-item">
                                        <div class="info-label font-color-A0A1">上线日期</div>
                                        <div class="info-val font-color-3737">{{item.createTime ? item.createTime : '-'}}</div>
                                    </div>
                                </div>
                            </router-link >
                            <router-link tag="a" target="_blank" :to="'/product/detail/'+item.id"  v-if="searchType=='product'" :key="index"  class="product-item flex-column item ">
                                <div class="font-14 company-title font-color-1010">{{item.name}}</div>
                                <div class="product-code font-14">
                                    <label class="font-color-3737">产品代码</label>
                                    <span class="font-color-666">{{item.code}}</span>
                                </div>
                            </router-link >
                            <router-link tag="a" target="_blank" :to="'/tr/detail/'+item.id" :key="index" v-if="item.type == 'tech_route' && searchType=='patent'">
<!--                                <div   class="result-item patent-muban bg-color-white font-14">-->
<!--                                    <div class="item-title font-bold" v-html="keywordStress(item.title)"></div>-->
<!--                                    <div class="patent-intro" v-html="keywordStress(item.content)"></div>-->
<!--                                    <div class="item-tag">路线</div>-->
<!--                                </div>-->
                            </router-link>
                            <router-link tag="a" target="_blank" :to="'/patent/detail/'+item.id" :key="index" v-if="item.type == 'tech_patent' && searchType=='patent'">
                                <div  class="result-item patent-muban bg-color-white font-14">
                                    <div class="item-title font-bold" v-html="keywordStress(item.title)"></div>
                                    <div class="patent-intro" v-html="keywordStress(item.content)"></div>
                                    <div class="item-tag">专利</div>
                                </div>
                            </router-link>
                            <router-link tag="a" target="_blank" :to="'/case/detail/'+item.id" :key="index" v-if="item.type == 'disease_case' && searchType=='patent'">
                                <div  class="result-item patent-muban bg-color-white font-14">
                                    <div class="item-title font-bold" v-html="keywordStress(item.title)"></div>
                                    <div class="patent-intro" v-html="keywordStress(item.content)"></div>
                                    <div class="item-tag">病例</div>
                                </div>
                            </router-link>
                            <router-link tag="a" target="_blank" :to="'/disease/detail/'+item.id" :key="index"  v-if="item.type == 'disease' && searchType=='patent'">
                                <div class="result-item patent-muban bg-color-white font-14">
                                    <div class="item-title font-bold" v-html="keywordStress(item.title)"></div>
                                    <div class="patent-intro" v-html="keywordStress(item.content)"></div>
                                    <div class="item-tag">疾病</div>
                                </div>
                            </router-link>
                        </template>
                    </div>
                </div>
                <div class="no-data" v-if="searchResult.length == 0">
                    <img src="/no-data.png">
                    <div>暂无更多数据</div>
                </div>
            </div>
            <div class="reconmend-content">
                <div class="fix-content"><patent-hot /></div>
            </div>

        </div>
        <div class="get-more" v-if="showMoreBottom">
            <div class="getMore cur-pointer" @click="getMoreList"> 加载更多 </div>
        </div>

    </div>
</template>

<script>
import PatentHot from "@/components/hot/PatentHot";
export default {
    name: "SearchResult",
    components: {
        PatentHot
    },
    data(){
        return {
            searchResult: [],
            searchType: 'patent',  // patent, technology， product, project, company
            keywords: '',
            pageNo: 1,
            pageSize: 20,
            showMoreBottom: false,
        }
    },
    created() {
        console.log(this.$route.params)
        this.keywords = this.$route.params.keywords ? this.$route.params.keywords : ''
        this.searchType = this.$route.params.type
        this.Search()
    },
    mounted() {
        // window.addEventListener("scroll",this.handleScroll);
    },
    methods: {

        // 监听页面,加载更多
        handleScroll() {
            //获取滚动时的高度
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            // 获取滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight;

            // 视频窗口高度
            var windowHeight = document.documentElement.clientHeight

            if((scrollTop + windowHeight + 300) >scrollHeight ){
                this.pageNo = this.pageNo + 1
                this.Search()
            }

        },
        getMoreList(){
            this.pageNo = this.pageNo + 1
            this.Search()
        },

        resetSearch(){
            this.pageNo = 1
            this.searchResult = []
            this.showMoreBottom = false
            if(this.searchType == 'patent'){
                this.searchPatent()
            }else if(this.searchType == 'product'){
                this.searchProduct()
            }else if(this.searchType == 'project'){
                this.searchProject()
            }else if(this.searchType == 'company'){
                this.searchCompany()
            }else{
                this.searchPatent()
            }
        },

        Search(){
            if(this.searchType == 'patent'){
                this.searchPatent()
            }else if(this.searchType == 'product'){
                this.searchProduct()
            }else if(this.searchType == 'project'){
                this.searchProject()
            }else if(this.searchType == 'company'){
                this.searchCompany()
            }else{
                this.searchPatent()
            }
        },

        switchType(type){
            this.searchType = type
            this.pageNo = 1
            this.searchResult = []
            this.showMoreBottom = false
            this.Search()
        },

        searchPatent(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/search?pageNo='+this.pageNo+'&pageSize=10&content='+encodeURIComponent(this.keywords),
                data:{
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                },
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    if(ldata.result.records.length > 0){
                        _that.searchResult =  _that.searchResult.concat(ldata.result.records)
                        _that.showMoreBottom = true
                    }else{
                        _that.showMoreBottom = false
                    }
                }
            })

        },

        searchCompany(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/company/list?pageNo='+this.pageNo+'&pageSize=10&companyName='+encodeURIComponent(this.keywords),
                data:{
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                },
            }).then(function (resp) {
                let ldata = resp.data
                if (ldata.success) {
                    if(ldata.result.records.length > 0){
                        _that.searchResult =  _that.searchResult.concat(ldata.result.records)
                        _that.showMoreBottom = true
                    }else{
                        _that.showMoreBottom = false
                    }
                }
            })
        },

        searchProject(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/project/list?pageNo='+this.pageNo+'&pageSize=10&name=*'+this.keywords+"*",
                data:{
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                },
            }).then(function (resp) {
                let ldata = resp.data
                if (ldata.success) {
                    if(ldata.result.records.length > 0){
                        _that.searchResult =  _that.searchResult.concat(ldata.result.records)
                        _that.showMoreBottom = true
                    }else{
                        _that.showMoreBottom = false
                    }
                }
            })
        },

        searchProduct(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/product/list?pageNo='+this.pageNo+'&pageSize=10&name=*'+this.keywords+"*",
                data:{
                    pageNo: this.pageNo,
                    pageSize: this.pageSize
                },
            }).then(function (resp) {
                let ldata = resp.data
                if (ldata.success) {
                    if(ldata.result.records.length > 0){
                        _that.searchResult =  _that.searchResult.concat(ldata.result.records)
                        _that.showMoreBottom = true
                    }else{
                        _that.showMoreBottom = false
                    }
                }
            })
        },

        // 替换显示
        keywordStress(strings){
            if(strings){
                let strword = '<text style="color: red;">'+this.keywords+'</text>';
                return strings.replace(this.keywords, strword)
            }
        },
    }
}
</script>

<style scoped>
.search-header{
    width: 48%;
    position: fixed;
    z-index: 101;
    top: 10px;
    left: 20%;
}
.search{
    margin:0 10%;
}
.search .search-input{
    padding: 4px 24px;
    width: 80%;
    border:1px solid  #4D8CEE;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    /*border:1px solid #FFFFFF  !important;*/
    outline: none;
}
.search .search-btn{
    width: 20%;
    line-height: 40px;
    background-color: #4D8CEE;
    color: #FFFFFF;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

}
.search-content{
    padding: 70px 10% 10px;
}

.type-items {
    border-top: 1px solid #EFEFEF;
    padding: 0px 12px ;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    padding: 4px 10% 0px;
    position: fixed;
    width: 100%;
    box-shadow: 0 5px 10px #EBEBEB;
    z-index: 99;
}
.type-items .type-item{
    padding: 12px 0;
    width: 120px;
    cursor: pointer;
}
.divide-line{
    width: 1px;
    height: 24px;
    background-color: #EBEBEB;
    margin-top: 12px;
}
.type-items .type-item.select{
    color: #4D8CEE;
    border-bottom: 1px solid #4D8CEE;
}

/*//*/
.result-item{
    padding: 20px;
    margin-bottom: 15px;
}
.common-muban .item-title,
.patent-muban .patent-intro{
    margin-bottom: 10px;
}
.patent-muban .item-title{
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    margin-bottom: 10px;
}
.patent-muban .patent-intro{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.patent-muban .patent-num{
    margin-bottom: 5px;
}
.patent-muban label{
    margin-right: 20px;
}
.item-tag{
    color: #B5B7B9;
    border: 1px solid #B5B7B9;
    display: inline;
    padding: 1px 16px;
    border-radius: 4px;
    font-size: 10px;
}

/* product */
.product-item:not(:last-child){
    padding: 20px;
    margin-bottom: 15px;
}
.product-item .product-code label{
    margin-right: 10%;
}

/*project*/
.project-item, .company-item, .product-item{
    padding: 20px;
    margin-bottom: 15px;
    background-color: #FFFFFF;
}
.project-item .project-title{
    margin-bottom: 12px;
}
.project-item .info-item {
    text-align: center;
}
.project-item .info-label{
    margin-bottom: 5px;
}
/*company*/
.company-item{
    padding: 20px;
    margin-bottom: 15px;
    background-color: #FFFFFF;
 }
.company-title{
    margin-bottom: 12px;
}
.company-item .info-item {
    text-align: center;
}
.company-item .info-label{
    margin-bottom: 5px;
}
.results-content{
    width: 69%;
}

.get-more{
    padding: 40px;
    width: 69%;
}
.getMore{
    background-color: #EFEFEF;
    font-size: 14px;
    padding:10px 100px;
    cursor: pointer;
    border-radius: 6px;
    display: inline;
}

.reconmend-content{
    width: 28%;
    position: relative;
}
.reconmend-content .fix-content{
    /*position: fixed;*/
}
.no-data{
    margin-top: 20%;
    color: #999999;
}
</style>