<template>
    <div class="conten index-detail">
        <div class="page-content ">
            <div class="chart bg-color-white">
                <div class="charts-box">
                    <div id="areaCharts" style="width: 100%; height: 300px"></div>
                </div>
            </div>
            <div class="content-desc font-14 text-justify index-blank-padding bg-color-white">
                <p>分析样本中，与对虾病害诊断及防治相关的专利申请主要分布在中国，其次是美国、印度和韩国，其中，中国专利的专利申请量为671件，占总申请量的85.59%。另外，图2-3显示，中国的专利申请趋势与全球专利申请趋势基本趋于一致。</p>
                <p><br />我国的对虾养殖业从20世纪70年代末开始兴起，经历了稳定增长期（1979-1983年）、快速发展期(1983-1992年)、急剧衰退和恢复发展阶段(1993-1999年)，然后到新世纪大发展阶段(2000年至今)。这期间，1993年暴发性流行病从南到北袭击了整个养虾业，因病害严重，养殖对虾总产量急剧下降。灾难性的瘟疫，引起了人们对对虾病害防治的极大关注，不断研究防治措施和新型养虾技术，因此，在2000年开始，我国对虾病害防治的专利开始出现缓慢的增长趋势。</p>
                <p>随着我国对虾养殖的第二次兴起，在认真总结传统对虾养殖技术和经验的基础上，结合现代科学技术，调整养殖品种，重视养殖模式的改进和创新，不断提高对虾健康养殖技术和病害防治技术，同时我国的专利申请也出现大量的布局。</p>
                <p><br />目前，我国对虾养殖区域主要集中在沿海海岸带、海岛与广阔的海域，例如渤海湾、黄海海域、东海海域以及南海海域。</p>
                <p>从专利申请分布上看，也主要分布在这些区域，广东省和山东省的专利申请量属于第一梯队，目前广东的申请量排名第一，其次是山东省和江苏省。</p>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: 'AreaDetail',
    data() {
        return {
            content: '',
            areaData:{
                "global":{
                    labename: ["中国", "美国","印度","韩国","墨西哥","其他"],
                    data: [671, 18, 13, 12, 9, 61]
                },
                "province":{
                    labename: ["安徽","湖北","天津","上海","福建","广西","浙江","江苏","山东","广东"],
                    data: [16, 16, 33, 33, 49, 60, 61, 75, 128, 151]
                }
            },
            areaTemp: {},
            areaType: 'global'
        }
    },
    created(){
        if(this.$route.params.areaType == 'province'){
            this.areaTemp = this.areaData['province']
        }else{
            this.areaTemp = this.areaData['global']
        }
    },
    mounted() {
        this.initAreaChart()
    },
    methods: {

        // 区域分布
        initAreaChart(){
            var chartDom = document.getElementById('areaCharts')
            var myChart = echarts.init(chartDom)
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: this.areaTemp.labename
                },
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data:  this.areaTemp.data
                    }
                ]
            };
            myChart.setOption(option)
        },


    }
}
</script>

<style scoped>
.index-detail{
    padding: 20px 10%;
}
.content-desc{
    margin-top: 15px;
    border-top: 10px solid #F8F8F8;
    padding-bottom: 30px;
    padding-top: 20px;
}

</style>
