<template>
    <div class="hot-page">
        <div class="page-content flex-column">
            <div class="bg-color-white  item flex-column" v-if="hotCompany.length > 0">
                <div class="flex-row  justify-between recommend-company index-blank-padding">
                    <div class="item-title font-16 font-color-1010 font-bold">热门企业</div>
                </div>
                <div class="item-content index-blank-padding">
                    <div class="patent-list">
                        <template v-for="(item, index) in hotCompany">
                            <router-link  tag="a" target="_blank"  :to="'/company/detail/'+item.id"  class="company-item flex-column item" :key="index">
                                <div class="font-14  company-title text-left">{{item.companyName}}</div>
                                <div class="company-info font-12 flex-row align-center justify-between">
                                    <div class="legalPerson info-item">
                                        <div class="info-label font-color-A0A1">法定代表人</div>
                                        <div class="info-val font-color-3737">{{item.representative}}</div>
                                    </div>
                                    <div class="info-item registeredCapital">
                                        <div class="info-label font-color-A0A1">注册资本</div>
                                        <div class="info-val font-color-3737">{{item.capital}}</div>
                                    </div>
                                    <div class="foundData info-item">
                                        <div class="info-label font-color-A0A1">成立日期</div>
                                        <div class="info-val font-color-3737">{{item.established}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompanyHot",
    data(){
        return {
            hotCompany: []
        }
    },
    created() {
        this.getCompany()
    },
    methods:{
        getCompany(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/company/list?pageNo=1&pageSize=5',
            }).then(function (resp) {
                let ldata = resp.data
                if (ldata.success) {
                    _that.hotCompany = ldata.result.records
                }
            })
        },
    }
}
</script>

<style scoped>
.page-content .item{
    margin-bottom: 8px;
    padding-top: 20px;
    padding-bottom: 15px;
}
.recommend-company{
    padding-bottom: 10px;
    border-bottom: 1px solid #F8F8F8;
}
.company-item {
    padding: 10px 0;
}
.company-item:not(:last-child){
     padding-bottom: 16px;
     border-bottom:  1px solid #E6E6E6;
}
.company-title{
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.info-item {
    text-align: center;
}
.info-label{
    margin-bottom: 5px;
}

</style>