<template>
    <div class="hot-page">
        <div class="patent-hot-list rank-item bg-color-white">
            <div class="rank-title font-bold text-left">热门专利</div>
            <div class="list-content">
                <div class="content-item flex-column">
                    <template v-for="(item, index) in hotPatent">
                        <div class="border-bottom-EB" :key="index">
                            <router-link  tag="a" target="_blank" :to="'/patent/detail/'+item.id">
                                <patent-date :patentInfo="item" />
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PatentDate from "@/components/item-components/PatentDate";
export default {
    name: "PatentHot",
    components: {
        PatentDate
    },
    data(){
      return {
          hotPatent: []
      }
    },
    created() {
      this.getHotPatent()
    },
    methods: {
        getHotPatent(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/techPatent/list?pageNo=1&pageSize=10&hot=1&column=sort&order=asc',
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.hotPatent = ldata.result.records
                }
            })
        },

    }
}
</script>

<style scoped>
.rank-item{
    padding:16px 20px 16px;
}
</style>