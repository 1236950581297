<template>
    <div class="page-content detail">
        <div class="basic-info index-blank-padding">
            <div class="detail-title font-16 font-color-1010 font-bold margin-bo-20">
                基础信息
            </div>
            <div class="basic-name font-14 basic-item flex-row">
                <div class="label">
                    名称
                </div>
                <div class="margin-left-30">{{routeDetail.name}}</div>
            </div>
            <div class="basic-desc font-14 basic-item">
                <div class="label">简介</div>
                <div class="text-justify pre-wrap" >{{routeDetail.summary}}</div>
            </div>
        </div>
        <div class="tr-relate">
            <div class="detail-title font-16 font-bold margin-bo-20">
                关联专利数量
            </div>
            <div class="detail-items">
                <navigator hover-class="none" url="/pages/detail_tr/tr_list?key=zhenduan" class="item font-14 font-color-3737 flex-row justify-between">
                    <div class="item-label">诊断相关 <text class="font-color-F204" style="margin-left: 10px;">{{diagnosisCount}}</text></div>
                    <div class="item-nums flex-row align-center"><uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
                </navigator>
                <navigator hover-class="none" url="/pages/detail_tr/tr_list?key=fangzhi" class="item font-14 font-color-3737 flex-row justify-between">
                    <div class="item-label">防治相关 <text class="font-color-F204" style="margin-left: 10px;">{{cureCount}}</text></div>
                    <div class="item-nums flex-row align-center"><uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
                </navigator>
            </div>
        </div>
        <div class="tr-develop">
            <div class="detail-title font-16 font-bold margin-bo-20">
                技术发展路线
            </div>
            <div class="detail-items">
                <template v-for="(item, index) in routeList">
                    <div hover-class="none" :url="'/pages/detail_tr/tr_list?key='+item.title" :key="index" class="item font-14 font-color-3737 flex-row justify-between">
                        <div class="item-label">{{item.title}} </div>
                        <div class="item-nums flex-row align-center"><uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
                    </div>
                </template>
                <!-- <navigator hover-class="none" url="/pages/brain_map/brain_map" class="item font-14 font-color-3737 flex-row justify-between">
                    <div class="item-label">诊断相关</div>
                    <div class="item-nums flex-row align-center"> -->
                <!-- 66 -->
                <!-- 		<uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
                </navigator>
                <navigator hover-class="none" url="/pages/brain_map/brain_map" class="item font-14 font-color-3737 flex-row justify-between align-center">
                    <div class="item-label">防治相关</div>
                    <div class="item-nums flex-row align-center"> -->
                <!-- 66 -->
                <!-- <uni-icons  class="uniIcons" size="14"  type="arrowright" ></uni-icons></div>
        </navigator> -->
                <!-- <tree-tu-y :serverData="serverData" ></tree-tu-y> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            techRouteId: '',
            routeDetail: {},
            cureCount: 0,
            diagnosisCount: 0,
            routeList: []
        }
    },
    created() {
        this.techRouteId = this.$route.params.id
        this.getRouteDetail()
        this.getBrainData()
        // this.pageShare()
    },
    watch: {
        $route: function(to, from) {
            if (to.path !== from.path) {
                this.patentId = to.params.id
                this.getRouteDetail()
                this.getBrainData()
            }
        }
    },
    methods: {
        getRouteDetail(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/techRoute/queryById',
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.routeDetail = ldata.result.techRoute
                    _that.cureCount = ldata.result.cureCount
                    _that.diagnosisCount = ldata.result.diagnosisCount
                }
            })
        },
        strReplace(str){
            return str.replace(/\\n/,'\n')
        },

        getBrainData(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/techRoute/queryBrainById',
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.routeList = ldata.result.list
                }
            })
        },

    }
}
</script>

<style>
.basic-info .basic-name{
    border-bottom: 1px solid #F8f8f8;
}
.tr-relate, .tr-develop{
    padding-left: 17px;
    padding-bottom: 20px;
}
</style>
