<template>
    <div class="hot-page">
        <div class="page-content flex-column">
            <div class="bg-color-white  item flex-column" v-if="hotProject.length > 0">
                <div class="flex-row  justify-between recommend-project index-blank-padding">
                    <div class="item-title font-16 font-bold">推荐项目</div>
                </div>
                <div class="item-content index-blank-padding">
                    <div class="patent-list text-left">
                        <template v-for="(item, index) in hotProject">
                            <router-link  tag="a" target="_blank" :key="index" :to="'/project/detail/'+item.id"  class="project-item flex-column item">
                                <div class="font-14  project-title">{{item.name}}</div>
                                <div class="project-info font-12 flex-row align-center justify-between">
                                    <div class="financeStatus info-item">
                                        <div class="info-label font-color-A0A1">融资状态</div>
                                        <div class="info-val font-color-3737">{{item.financingRound_dictText ? item.financingRound_dictText : '-'}}</div>
                                    </div>
                                    <div class="info-item demandAmount">
                                        <div class="info-label font-color-A0A1">需求金额</div>
                                        <div class="info-val font-color-3737">{{item.requestAmount}}</div>
                                    </div>
                                    <div class="onlineData info-item">
                                        <div class="info-label font-color-A0A1">上线日期</div>
                                        <div class="info-val font-color-3737">{{item.createTime}}</div>
                                    </div>
                                </div>
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProjectHot",
    data(){
        return {
            hotProject: []
        }
    },
    created() {
        this.getProject()
    },
    methods:{
        getProject(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/project/list?pageNo=1&pageSize=5',
            }).then(function (resp) {
                let ldata = resp.data
                if (ldata.success) {
                    _that.hotProject = ldata.result.records
                }
            })
        },
    }
}
</script>

<style scoped>
.page-content{

}
.page-content .item{
    margin-bottom: 8px;
    padding-top: 20px;
    padding-bottom: 15px;
}
.recommend-project{
    padding-bottom: 10px;
    border-bottom: 1px solid #F8F8F8;
}
.project-item {
    padding: 10px 0;
}
.project-item:not(:last-child){
    padding-bottom: 16px;
    border-bottom:  1px solid #E6E6E6;
}
.project-item .project-title{
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
}
.project-item .info-item {
    text-align: center;
}
.project-item  .info-label{
    margin-bottom: 5px;
}

</style>