<template>
  <div ref="chart" style="width: 100%; height: 100%"/>
</template>

<script>
import * as echarts from 'echarts'

// let chart = null

export default {
  name: 'charts',
  props: {
    option: {
      type: [Object, Array],
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.drawChart()
    this.updateChart()
  },
  methods: {
    drawChart() {
      this.chart = echarts.init(this.$el)

      window.addEventListener("resize", this.debounce(this.handleWindowResize))
      this.$on('hook:beforeRouteLeave', () => {
        window.removeEventListener('resize', this.handleWindowResize)
      })
    },
    updateChart() {
      if (!this.chart) return
      let options = this.option
      this.chart.clear()
      this.chart.setOption(options, true)
    },
    handleWindowResize() {
      console.log('s')
      if (!this.chart) return
      this.chart.resize()
    },
    debounce(fn, wait = 500) {
      let timer = null
      return function (...args) {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          fn.apply(this, args)
          timer = null
        }, wait)
      }
    }
  },
  watch: {
    option: {
      deep: true,
      handler(option) {
        console.log(option)
        this.chart.clear()
        this.chart.setOption(option, true)
      }
    }
  }
}
</script>
