import Vue from 'vue'
import App from './App.vue'

import VueAxios from 'vue-axios'
import axios from 'axios'

import VueRouter from "vue-router";
import routes from './router/routes'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueAwesomeSwiper)

// Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
// Vue.prototype.qs = qs

// route

const router = new VueRouter({
  // mode: "history",
  routes
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
