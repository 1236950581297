import PatentDetail from "@/components/detail/PatentDetail";
import ProductDetail from "@/components/detail/ProductDetail";
import ProjectDetail from "@/components/detail/ProjectDetail";
import TrDetail from "@/components/detail/TrDetail";
import CompanyDetail from "@/components/detail/CompanyDetail";
import CaseDetail from "@/components/detail/CaseDetail";
import Index from '@/components/Index'
import SearchResult from "@/components/SearchResult";
import DiseaseDetail from "@/components/detail/DiseaseDetail";
import RankList from "@/components/RankList";
import ApplicantDetail from "@/components/index-detail/ApplicantDetail";
import ApplyTrendDetail from "@/components/index-detail/ApplyTrendDetail";
import AreaDetail from "@/components/index-detail/AreaDetail";
import ProductIndexDetail from "@/components/index-detail/ProductIndexDetail";
import TechnologyDetail from "@/components/index-detail/TechnologyDetail";
// import PantenttDetail from "@/components/detail/PantenttDetail";
// import DataSys from "@/components/DataSys";

var routes = [
    {path: '/', component: Index},
    {path: '/search/:type/:keywords?', component: SearchResult},
    // { path: '/patent/detail/:id', component:  PantenttDetail},
    { path: '/patent/detail/:id', component: PatentDetail },
    { path: '/product/detail/:id', component: ProductDetail },
    { path: '/project/detail/:id', component: ProjectDetail },
    { path: '/tr/detail/:id', component: TrDetail },
    { path: '/company/detail/:id', component: CompanyDetail },
    { path: '/case/detail/:id', component: CaseDetail },
    { path: '/disease/detail/:id', component: DiseaseDetail },
    { path: '/rank/list', component: RankList },

    { path: '/index/applicant/:applicantType', component: ApplicantDetail },
    { path: '/index/apply_trend/:type', component: ApplyTrendDetail },
    { path: '/index/area/:areaType', component: AreaDetail },
    { path: '/index/product', component: ProductIndexDetail },
    { path: '/index/technology', component: TechnologyDetail },
    // { path: '/data/sys', component: DataSys}
]

export default routes;