<template>
    <div class="content ">
        <div class="bread"> </div>
        <div class="flex-row justify-between">
            <div class="page-content">
                <div class="product-info index-blank-padding bg-color-white">
                    <div class="flex-row justify-between align-center product-name">
                        <div class=" font-16 font-bold" style="width: 86%;">{{productInfo.name}}</div>
                    </div>
                    <div class="product-code font-14 text-left">
                        <label class="font-color-3737">产品代码</label> 		<span class="font-color-333">1{{productInfo.code}}</span>
                    </div>
                </div>
                <div class="product-company bg-color-white" v-if="relationCompany.length > 0">
                    <div class="title font-14 font-bold text-left">生产企业</div>
                    <div class="company-list index-blank-padding">

                        <template v-for="(item, index) in relationCompany">
                            <div class="border-bottom-EB" :key="index">
                                <company-item :companyInfo="item" />
                            </div>

                        </template>
                    </div>
                </div>
            </div>
            <div class="recomend-content">
                <product-hot />
            </div>
        </div>

    </div>
</template>

<script>
import CompanyItem from "@/components/item-components/CompanyItem";
import ProductHot from "@/components/hot/ProductHot";

export default {
    name: 'ProductDetail',
    data() {
        return {
            productInfo: {},
            isLike: false,
            productId: '',
            relationCompany: []
        }
    },
    components: {
        CompanyItem,
        ProductHot
    },
    created(){
        this.productId = this.$route.params.id
        this.getDetail()
        this.getRelationCompany()
    },
    watch: {
        $route: function(to, from) {
            if (to.path !== from.path) {
                this.productId = to.params.id
                this.getDetail()
                this.getRelationCompany()
            }
        }
    },
    methods: {

        getDetail(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/product/queryById?id='+ _that.productId,

            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.productInfo = ldata.result
                }
            })

        },

        getRelationCompany(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/product/queryProductCompanyViewByMainId?id='+ _that.productId,
            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.relationCompany = ldata.result
                }
            })
        },

        followsClick(){
            if(this.isLike){
                this.deleteFollow()
            }else{
                this.addFollow()
            }
        },

    }
}
</script>

<style scoped>
.content{
    padding: 16px 10%;
}
.page-content{
    width: 68%;
}
.recomend-content{
    width: 23%;
    position: fixed;
    right: 10%;
}

.product-info{
    padding-top: 20px;
    padding-bottom: 20px;
}
.product-info .product-name{
    margin-bottom: 15px;
}
.product-info .product-code label{
    margin-right: 20px;
}
.product-company{
    border-top: 10px solid #EBEBEB;
}
.product-company .title{
    padding: 20px 20px 15px;
    border-bottom: 1px solid #F8F8F8;
    margin-bottom: 10px;
}
</style>
