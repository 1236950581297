<template>
    <div class="container project-detail flex-row justify-between">
        <div class="page-content text-left">
            <div class="bg-color-white project-head index-blank-padding">
                <div class="flex-row align-center justify-between project-title">
                    <div class="font-16 project-name font-color-1010 font-bold" style="width: 86%;">{{projectInfo.name}}</div>
                </div>
                <div class="project-info font-12 flex-row align-center justify-between">
                    <div class="financeStatus info-item">
                        <div class="info-label font-color-A0A1">融资状态</div>
                        <div class="info-val font-color-3737">{{projectInfo.financingRound_dictText ? projectInfo.financingRound_dictText : '-'}}</div>
                    </div>
                    <div class="info-item demandAmount">
                        <div class="info-label font-color-A0A1">需求金额</div>
                        <div class="info-val font-color-3737">{{projectInfo.requestAmount ? projectInfo.requestAmount : '-'}}</div>
                    </div>
                    <div class="onlineData info-item">
                        <div class="info-label font-color-A0A1">上线日期</div>
                        <div class="info-val font-color-3737">{{valueFill(projectInfo.createTime)}}</div>
                    </div>
                </div>
            </div>
            <div class="bg-color-white project-item project-intro ">
                <div class="font-14 project-label index-blank-padding font-bold">项目简介</div>
                <div class="project-content index-blank-padding">{{projectInfo.summary}}</div>
            </div>
            <div class="bg-color-white project-item project-desc ">
                <div class="font-14 project-label index-blank-padding font-bold">项目说明</div>
                <div class="project-content index-blank-padding">{{projectInfo.description}}</div>
            </div>
            <div class="bg-color-white project-item project-origin ">
                <div class="font-14 project-label index-blank-padding font-bold">项目来源</div>
                <div class="project-content index-blank-padding">-</div>
            </div>
            <div class="bg-color-white project-item project-contact ">
                <div class="font-14 project-label index-blank-padding font-bold">联系方式</div>
                <div class="project-content index-blank-padding">
                    <!-- <div> <label>联系人：</label> {{projectInfo.requestAmount}}</div>
                    <div> <label>手机：</label> {{projectInfo.requestAmount}}</div>
                    <div> <label>电话：</label> {{projectInfo.requestAmount}}</div>
                    <div> <label>邮箱：</label> {{projectInfo.requestAmount}}</div> -->
                    <rich-text :nodes="projectInfo.contact"></rich-text>
                </div>
            </div>
        </div>
        <div class="recomend-content">
            <project-hot />
        </div>
    </div>
</template>

<script>
import ProjectHot from "@/components/hot/ProjectHot";
export default {
    data() {
        return {
            projectInfo: {},
            isEdit: '',
            isLike: false,
            projectId: ''
        }
    },
    components: {
        ProjectHot
    },
    created(){
        this.projectId = this.$route.params.id

        this.getDetail()
    },
    watch: {
        $route: function(to, from) {
            if (to.path !== from.path) {
                this.patentId = to.params.id
                this.getDetail()
            }
        }
    },
    methods: {
        getDetail(){
            var _that = this
            this.axios({
                method: 'get',
                url: 'https://api.tusparkgd.com/seatech/project/queryById?id='+ _that.projectId,

            }).then(function (resp){
                let ldata = resp.data
                if(ldata.success){
                    _that.projectInfo = ldata.result
                }
            })
        },

        valueFill(str){
            if(str){
                return str
            }else{
                return '-'
            }
        },
    }
}
</script>

<style scoped>
page{
    background-color: #F8F8F8;
}
.project-detail{
    padding: 16px 10%;
}
.page-content{
    width: 68%;
}
.recomend-content{
    width: 25%;
    position: fixed;
    right: 10%;
}
.project-head{
    padding: 20px ;
}
.project-head .project-title{
    margin-bottom: 20px;
}
.project-head .project-info{
    background-color: #F8F8F8;
    padding: 16px 10px;
    border-radius: 5px;
}
.project-head .info-item{
    text-align: center;
}
.project-head .info-item .info-label{
    margin-bottom: 5px;
}

.project-item{
    margin-top: 10px;
}
.project-item .project-label{
    padding: 12px 20px;
    border-bottom: 1px solid #E6E6E6;
}
.project-item .project-content{
    padding: 13px  20px 30px ;
    font-size: 14px;
    line-height: 20px;
}
.page-content{
    margin-bottom:60px;
}
.page-bottom{
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 15px 0;
}

</style>
