<template>
    <div class="component-content">
        <div hover-class="none" :url="'/pages/detail_company/detail_company?companyId='+ companyInfo.id" class="project-item flex-column item">
            <div class="font-14  project-title">{{companyInfo.companyName}}</div>
            <div class="project-info font-12 flex-row align-center justify-between">
                <div class="financeStatus info-item">
                    <div class="info-label font-color-A0A1">法定代表人</div>
                    <div class="info-val font-color-3737">{{companyInfo.representative}}</div>
                </div>
                <div class="info-item demandAmount">
                    <div class="info-label font-color-A0A1">注册资本</div>
                    <div class="info-val font-color-3737">{{companyInfo.capital}}</div>
                </div>
                <div class="onlineData info-item">
                    <div class="info-label font-color-A0A1">成立日期</div>
                    <div class="info-val font-color-3737">{{companyInfo.established}}</div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="company-list">
        <template v-for="(item, index) in companyList">

        </template>
    </div> -->
</template>

<script>
export default {
    name:"CompanyItem",
    data() {
        return {

        };
    },
    props: {
        companyInfo: {
            type: Object,
            default: function(){
                return {}
            }
        },
    },
}
</script>

<style scoped>
.project-item{
    padding: 10px 0;
}
.project-item:not(:last-child){
    padding-bottom: 16px;
    border-bottom:  1px solid #E6E6E6;
}
.project-item .project-title{
    margin-bottom: 12px;
}
.project-item .info-item{
    text-align: center;
}
.project-item .info-item .info-label{
    margin-bottom: 10px;
}
</style>
